import { IInstructorMapping } from "src/app/redux/modules/integrations/lti/rest";

export const compareInstructorMapping = (mapping: IInstructorMapping[], scenarioId: string, teamId: string): boolean => {
    return mapping.length > 0 && mapping[0].scenarioId === scenarioId && mapping[0].teamId === teamId;
};

export const isDataNull = (items: any[]): boolean => {
    let isItemNull = false;
    items.forEach((item) => {
        if (!item) {
            isItemNull = true;
        }
    });
    return isItemNull;
};

export const setIds = (id: string, mappingPresent: boolean) => {
    return id && !mappingPresent;
};

export const getIds = (id: string | null) => {
    return id ? [id] : null;
};

export const getValueForActiveProp = (projectId: string | null, scenarioId: string | null): boolean => {
    return !!projectId && !!scenarioId;
};

export const getPreSelectedValue = (id: string | null) => {
    return id ? id : '';
};

export const getDisabledValue = (projectId: string | null, scenarioId: string | null, teamId: string | null, mappingForContextId: IInstructorMapping[]) => {
    return !projectId || !scenarioId || !teamId || compareInstructorMapping(mappingForContextId, scenarioId, teamId);
};