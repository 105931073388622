import IUserRoleExtended from '../interfaces/IUserRoleExtended';
import Permission from '../interfaces/Permission';

/**
 * Returns whether `userRole` has `permission`.
 * @param userRole
 * @param permission
 */
const hasPermission = (userRole: IUserRoleExtended | null, permission: Permission | Permission[]): boolean => {
  if (!userRole) {
    return false;
  }

  let permissions: Permission[] = [];
  permissions = permissions.concat(permission);

  return permissions.some((perm) => userRole.permissions.includes(perm));
};

export default hasPermission;
