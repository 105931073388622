import IUserRoleExtended from 'src/app/data/profile/interfaces/IUserRoleExtended';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import { isCurrentUserLearner } from 'src/app/data/common/utils/userRoleUtils';

const canCreateSessionWithoutLearners = (userRole?: IUserRoleExtended, deliveryMode?: SessionType, scenarioVersion?: ScenarioVersion,) => {

  if (!scenarioVersion || !deliveryMode || !userRole) {
    return false;
  }

  const isLearner = isCurrentUserLearner(userRole);
  const isGroupType = deliveryMode === SessionType.GROUP;

  if (isLearner || isGroupType) {
    return false;
  }

  return [ScenarioVersion.V2, ScenarioVersion.V3z, ScenarioVersion.V3meet].includes(scenarioVersion);
};

export default canCreateSessionWithoutLearners;