export const REPORT_PAGE_ID  = {
    ContractSummaries: 'CONTRACT_SUMMARY',
    contractItems: 'CONTRACT_ITEMS',
    projects: 'CONTRACT_PROJECT',
    scenarios: 'CONTRACT_SCENARIO',
    LearnerActivities: 'LEARNER_ACTIVITY',
    LearnerSession: 'LEARNER_SIMULATIONS',
    SessionDetail: 'SIMULATION_DETAILS', 
    SessionSummaries: 'SIMULATION_SUMMARIES',
};

export const REPORT_FORM_PERMISSION = {
    LEARNER_SIMULATIONS: 'LEARNER_SIMULATIONS',
    SIMULATION_DETAILS: 'SIMULATION_DETAILS',
    SIMULATION_SUMMARIES: 'SIMULATION_SUMMARIES',
    LEARNER_ACTIVITY: 'LEARNER_ACTIVITY',
};
