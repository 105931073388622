import ICompanyUser from 'src/app/data/licensee/users/interfaces/ICompanyUser';
import SessionStatusType, { SessionStatusLabelType, SessionStatusSubType } from 'src/app/data/session/interfaces/SessionStatusType';
import { IAssetSettingBase } from 'src/app/data/client/interfaces/IAsset';
import { IAuthor } from 'src/app/data/author/IAuthor';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import IUser, { IAppUserBase } from 'src/app/data/common/interfaces/IUser';
import { IProjectSimple, ScenarioVersion, SoftwareType } from 'src/app/data/projects/interfaces/IProject';
import { ITeamShort } from 'src/app/data/client/interfaces/ITeam';
import RoleID from 'src/app/data/common/interfaces/RoleID';
import { IAnalyticsEventItem } from 'src/app/data/session/interfaces/ISessionSummary';
import { TLocaleId } from 'src/i18n';
import { VideoStatusType } from 'src/components/EntitySelectors/VideoStatusSelector';
import { IntlShape } from 'react-intl';
import { IViewEntityAction } from 'src/components/EntityTable';
import IUserRoleExtended from 'src/app/data/profile/interfaces/IUserRoleExtended';
import { AccordionThemeType } from 'src/components/Accordion/Accordion';
import  IUserProfile  from 'src/app/data/profile/interfaces/IUserProfile';
export interface ISession {
  licenseeName?: string ;
  rescheduleEmailBy?: string;
  rescheduleEmailDate?: number;
  timeSlots?: ITimeBlock[];
  subType?: string;
  id?: string;
  analyticsReady?: boolean;
  archived?: boolean;
  scenarioId: string;
  endDate: number;
  notes: string[];
  type: SessionType;
  learners: ISessionLearner[];
  timespan?: number;
  clientId?: string;
  clientName?: string;
  projectId?: string;
  projectName?: string;
  scenarioName?: string;
  scenarioVersion: ScenarioVersion;
  simspecialist?: ISimSpecialist | null;
  status?: SessionStatusType;
  statusLabel: SessionStatusLabelType;
  startDate: number;
  createdBy: IAuthor;
  assetSettings: IAssetSettingBase | null;
  actualEndDate?: number | null;
  actualStartDate?: number | null;
  attendanceCount?: number | null;
  version: number;
  teams: ITeamShort[];
  analyticsStatus?: SessionAnalyticsStatus;
  clientNote: string;
  recordingRecipients?: RoleID[];
  recordingAllowed?: boolean;
  recordingDoNotShare?:boolean;
  recordingLearnerChoice?: boolean;
  link?: string;
  publicLink?: string;
  scenarioType?: SessionScenarioType;
  softwareType?: SoftwareType;
  training?: boolean;
  externalLearnersOnly?: boolean;
  sessionSmartMetrics?: boolean;
  videoExists?: boolean;
  videoDeleted?: boolean;
  attendanceAdded?: boolean;
  ml3SocialAttendanceLicensee?:boolean;
  ml3SocialAttendanceClient?:boolean;
  newRescheduled?: boolean;
  supportCase?: string;
  surveyEnabled?: boolean;
  requestStatus?: IRequestStatus[];
  completionRateFulfilled?: boolean;
  schedulingRateFulfilled?: boolean;
  simReportError?: number[];
  otherIssues?: string;
}

interface IIdentifiableNamedObject {
  id: string;
  name: string;
  ml3SocialAttendance?: boolean;
}
interface ITimeBlock {
  startDate: number;
  endDate: number;
}

interface ISessionForListScenarioObject extends IIdentifiableNamedObject {
  deliveryMode: SessionType;
  sessionLength: number;
  endDate?:number;
  vignette?:string;
  simCheckListUrl: string;
  generationType?: number;
  nameCustomized?: string;
}

interface IRevision {
  author: IAuthor;
  date: number;
}

export enum requestStatusTypes {
  ACCEPTED,
  DECLINED_UNAVAILABLE,
  DECLINED_NOT_PREPARED,
  DECLINED_OTHER,
}

export interface IRequestStatus {
  actionDate: number;
  requestStatus: requestStatusTypes;
  requestType: SessionStatusSubType;
  simSpecialistFirstName: string;
  simSpecialistId: string;
  simSpecialistLastName: string;
}

export interface ISessionForList {
  id: string;
  startDate: number;
  endDate: number;
  client: IIdentifiableNamedObject;
  project: IProjectSimple;
  scenario: ISessionForListScenarioObject;
  teams: IIdentifiableNamedObject[];
  created: IRevision | null;
  lastModified: IRevision | null;
  learners: IAppUserBase[];
  simspecialist?: IAppUserBase;
  status: SessionStatusType;
  statusLabel: SessionStatusLabelType;
  version: number;
  recordingRecipients?: RoleID[];
  lastModifyReason?: string;
  timeSpent?: number;
  timeSlots?: ISessionTimeSlot[] | null;
  requestStatus?: IRequestStatus[];
  ssCertified?: boolean;
  selfReviewCancelled: boolean;
  simMatchedDate?: number;
  link?:string;
  newRescheduled?:boolean;
  assetSettingsImageURL?:string;
  attendanceAdded?: boolean;
  simPostSimulationSurveyCompleted?: boolean;
  completionRateFulfilled: boolean;
  scenarioVersion: ScenarioVersion;
  training?: boolean;
  completionRate?: number;
  earlyRescheduled?:boolean;
  lateRescheduled?:boolean;
  earlyCancelled?:boolean;
  lateCancelled?:boolean;
  videoStatus?: VideoStatusType;
  schedulingRateFulfilled: boolean;
  missOrCancelationRateFulfilled?: boolean;
  subType?: SessionStatusSubType;
  learnerRecordingConsent?: string;
  recorded?: boolean;
  simStatusForSession?: string | null;
}

export interface ISessionForLearnerList {
  sessionId: string;
  status: string;
  userId: string;
  userEmail: string | null;
  userFirstName: string;
  userLastName: string | null;
  startDate?: number;
  endDate?: number;
}

export interface ISessionWithHistoryInfo extends ISession {
  createdDate: number;
  lastModifiedDate: number;
  lastModifiedBy: IAuthor;
  reasonForModification?: string;
  assetProjectName?:string;
  scenarioVignette?:string;
  generationType?: number;
}

export interface ISessionUpdateBase {
  endDate: number;
  learners?: string[];
  notes?: string[];
  startDate: number;
  assetSettingsId: string;
  clientNote: string;
  externalLearnersOnly?: boolean;
}

// interface to rest session updates
export interface ISessionUpdate extends ISessionUpdateBase {
  version: number;
}

export interface IDemandBasedTimeSlots {
  preference?: number;
  startDate: number;
  endDate: number;
}

export interface ISessionCreate extends ISessionUpdateBase {
  scenarioId: string;
  timeSlots?: IDemandBasedTimeSlots[];
  subType?: string;
  rescheduled?: boolean;
  previousSessionId?: string;
  instantBooking?: boolean;
  delayedEventId?: string;
}

export interface ITrainingSessionCreate extends ISessionCreate {
  simspecialistId: string | undefined;
  scenarioTemplate: boolean;
}

export interface ITrainingSessionUpdate extends ISessionUpdate {
  simspecialistId?: string | null;
  reason?: string;
}

export interface IEmergencySessionCreate {
  assetSettingsId: string;
  endDate: number;
  intervalDuration?: number;
  learners?: string[];
  notes?: string[];
  reason: string;
  scenarioId: string;
  simspecialistId: string | undefined;
  startDate: number;
  externalLearnersOnly?: boolean;
  subType?: string;
  timeSlots?: IDemandBasedTimeSlots[];
}

export interface ILearnerReport extends ISession {
  analyticsReady: boolean;
  goalsSsFeedback: boolean[];
  scenarioVignette: string | null;
  visited: boolean;
  selfReviewed?: boolean;
  selfReviewCancelled?: boolean;
  simActivity?: number[][];
  smartMetrics?: boolean;
}

export interface ISessionReviewCreate {
  sessionId: string;
  status: SessionStatusType;
  videoLink: string;
  learnerStatus: ILearnerStatusInfo[];
}

export interface ISelfReviewCreate {
  cancelled?: boolean;
  review?: IAnalyticsEventItem[];
}

export interface ILearnerStatusInfo {
  id: string;
  status: SessionUserStatus;
}

export const USER_NO_STATUS = 'MursionPortal.Status.Capitalized.Offline';
export const USER_STATUS_MISSED = 'MursionPortal.Status.Capitalized.Missed';
export const USER_STATUS_INCOMPLETE = 'MursionPortal.Capitalized.Incomplete';
export const USER_STATUS_NO_SHOW = 'Mursion.Portal.Status.No.Show';

// Do NOT apply i18n ids here,
// these are the statuses from BE
export enum SessionStatusLabel {
  REQUESTED = 'Requested',
  PENDING = 'Pending',
  PENDING_SWAP = 'Pending (Swap)',
  PRE_BOOKED = 'Pre-booked',
  BOOKED = 'Booked',
  ACTIVE = 'Active',
  COMPLETE = 'Complete',
  ERROR = 'Error',
  MISSED = 'Missed',
  NEEDS_REVIEW = 'Needs Review',
  EARLY_RESCHEDULED = 'Early Rescheduled',
  LATE_RESCHEDULED = 'Late Rescheduled',
  LICENSEE_CANCELLED = 'Canceled', // System value is <licenseeName> + Canceled
  LATE_CANCELLED = 'Late Canceled',
  EARLY_CANCELLED = 'Early Canceled',
  CANCELLED = 'Cancelled'
}

/**
 * Get localized session status id by status label
 * @param sessionStatusLabel
 */
export const getSessionStatusLabelI18nId = (sessionStatusLabel: SessionStatusLabel): TLocaleId => {
  switch (sessionStatusLabel) {
    case SessionStatusLabel.REQUESTED:
      return 'Mursion.Portal.Status.Requested';
    case SessionStatusLabel.PENDING:
      return 'Mursion.Portal.Status.Pending';
    case SessionStatusLabel.PENDING_SWAP:
      return 'Mursion.Portal.Status.PendingSwap';
    case SessionStatusLabel.PRE_BOOKED:
      return 'Mursion.Portal.Status.PreBooked';
    case SessionStatusLabel.BOOKED:
      return 'Mursion.Portal.Status.Booked';
    case SessionStatusLabel.ACTIVE:
      return 'Mursion.Portal.Status.Active';
    case SessionStatusLabel.COMPLETE:
      return 'Mursion.Portal.Status.Complete';
    case SessionStatusLabel.ERROR:
      return 'Mursion.Portal.Status.Error';
    case SessionStatusLabel.MISSED:
      return 'Mursion.Portal.Status.Missed';
    case SessionStatusLabel.NEEDS_REVIEW:
      return 'Mursion.Portal.Status.NeedsReview';
    case SessionStatusLabel.LICENSEE_CANCELLED :
      return 'Mursion.Portal.Status.Cancelled';
    case SessionStatusLabel.EARLY_CANCELLED :
        return 'Mursion.Portal.Status.EarlierCancelled';
    case SessionStatusLabel.LATE_CANCELLED :
      return 'Mursion.Portal.Status.LateCancelled';
    case SessionStatusLabel.LATE_RESCHEDULED:
      return 'Filters.LateRescheduled';
    case SessionStatusLabel.EARLY_RESCHEDULED:
      return 'Dashboard.ContractProgress.Column.EarlyRescheduledSimulations';
    default :
      return 'Mursion.Portal.Status.Active';
  }
};


// Do NOT apply i18n ids here
export enum SessionUserStatus {
  CONNECTED = 'CONNECTED',
  LATE = 'LATE',
  IMMERSING = 'IMMERSING',
  COMPLETED = 'COMPLETED',
  LEFT = 'LEFT',
  ERROR = 'ERROR',
  SIM_ERROR='SIM_ERROR',
  DECLINED = 'DECLINED',
  MISSED = 'MISSED',
  UNABLE_TO_COMPLETE= 'UNABLE_TO_COMPLETE'
}


export const getSessionUserStatusI18nId = (sessionUserStatus: SessionUserStatus): TLocaleId => {
  switch (sessionUserStatus) {
    case SessionUserStatus.CONNECTED:
      return 'Mursion.Portal.SessionUserStatus.Connected';
    case SessionUserStatus.LATE:
      return 'Mursion.Portal.SessionUserStatus.Late';
    case SessionUserStatus.IMMERSING:
      return 'Mursion.Portal.SessionUserStatus.Immersing';
    case SessionUserStatus.COMPLETED:
      return 'MursionPortal.Capitalized.Completed';
    case SessionUserStatus.LEFT:
      return 'Mursion.Portal.SessionUserStatus.Left';
    case SessionUserStatus.ERROR:
      return 'MursionPortal.Status.Capitalized.Error';
    case SessionUserStatus.SIM_ERROR:
      return 'MursionPortal.Status.Capitalized.SimError';
    case SessionUserStatus.DECLINED:
      return 'Mursion.Portal.Status.Pending';
    case SessionUserStatus.MISSED:
      return 'MursionPortal.Status.Capitalized.Missed';
    default :
      return 'Mursion.Portal.Status.Active';
  }
};

// Do NOT apply i18n ids here
export enum SessionAnalyticsStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR'
}

// Do NOT apply i18n ids here
export enum SessionScenarioType {
  TEMPLATE = 'TEMPLATE',
  CLIENT = 'CLIENT'
}

export enum CreateSessionType {
  Session = 'Session',
  Training = 'Training',
  EmergencySession = 'EmergencySession',
}

export interface ISessionUser extends IUser {
  timeSpent?: number;
}

export interface ISimSpecialist extends ICompanyUser, ISessionUser {
}

export interface ISessionLearner extends ISessionUser {
  participated?: boolean;
  goalsFeedback?: boolean[];
  goalsSsFeedback?: boolean[];
  status?: SessionUserStatus;
  timeSpent?: 900000;
  videoRecordRejected?: boolean;
}

export interface IUpcomingEventsCounter {
  sessions: number; // Number of upcoming sessions
  trainings: number; // Number of upcoming trainings
  swaps: number; // Number of upcoming swaps
  requests: number; // Number of upcoming sessions without SS
  demandBasedRequests: number;
}

export interface ISessionTimeSlot {
  startDate: number;
  endDate: number;
}

export interface ISessionRegisteredUser{
  status?: SessionUserStatus;
  userId?: string | null;
}

export interface ISessionUnregisteredUser{
  firstName?: string;
  lastName?: string;
  status?: SessionUserStatus;
}

export interface ILearnerAttendanceStatus extends ISessionRegisteredUser{
  firstName?: string;
  lastName?: string;
  email?: string;
  isRegisteredUser:boolean;
}
export interface ISessionAttendanceUpdate {
  attendeeStatus: ISessionRegisteredUser[];
  simStatus?: ISessionRegisteredUser;
  addAttendees?: ISessionUnregisteredUser[];
  simReportError?: number[];
  otherIssues?: string;
}

export interface ICustomDonatLabels {
  label : string;
  color : string[];
  chartId : string;
  selected : boolean;
}

export interface ISimulationCountList {
  orphanSession: number;
  reservedSession: number;
  waifSession: number;
  bookedSession: number;
  upcomingSession: number;
  pendingSession: number;
  runningSession: number;
  completedSession: number;
  missedSession: number;
  needsReviewSession: number;
  reviewedSession: number;
  cancelledSession: number;
  swapSession: number;
  errorSession: number;
  rescheduledSession: number;
  surveysIncomplete: number;
}
export interface IRequestData {
  client: string;
  project: string;
  scenario: string;
  simSpecialist: string | undefined;
}

export interface IRequestFormatter {
  requestCertification: (requestData: IRequestData) => Promise<Response>;
  username: string | undefined;
  intl: IntlShape;
 }
export interface ISchedulingFormatter {
  intl: IntlShape;
  timeZoneId: string;
}
export interface ISchedulingDetailsProps {
  session: IRowData;
  intl: IntlShape;
  timeZoneId: string;
}

export interface IWarningTextComponentProps {
  value: string;
}

interface ISessionActionInfo {
  user: IAuthor;
  date: { date: string; time: string; };
  timeUntilStart?: string;
  value?: number;
}
interface IColumn {
  action: {
    onAcceptSwap: () => void;
  };
  client: string;
  id: number;
  learnerNames: string;
  simspecialist: string;
  project: string;
  contractId: string | null;
  scenario: string;
  scenarioVersion: ScenarioVersion;
  sessionId: string;
  teams: string;
  startDate: {
    date: string;
    time: string;
  };
  timeUntil: string | JSX.Element;
  status: SessionStatusLabelType;
  createdDate: ISessionActionInfo | null;
  lastModifiedDate: ISessionActionInfo | null;
  actions: {
    onPickUp: () => void;
  };
  availability: ISessionTimeSlot[] | null;
  requestStatus?: IRequestStatus[];
  simMatchedDate:  number | undefined;
  attendanceAdded: string | JSX.Element;
  simPostSimulationSurveyCompleted: string | JSX.Element;
  videoStatus: VideoStatusType | undefined;
}

export interface IRowData extends IColumn {
  fieldId: string;
  viewAction: {
    action: IViewEntityAction | null | undefined;
    entity: ISessionForList;
  };
}

export interface ISchedulingFormatterProps {
  intl: IntlShape;
  timeZoneId: string;
  session: IRowData;
}

export interface ICertificateRequestProps {
  row: IRowData;
  intl: IntlShape;
  formatExtraData:IRequestFormatter;
 }

 interface IFormatter {
  userRole: IUserRoleExtended | null;
  timeZoneId: string;
  intl: IntlShape;
  onRefresh: () => void;
}
export interface ITimeSlots extends IFormatter {
  row: IRowData;
}
 export interface IButtonCellFormatter  extends IFormatter {
  accordionTheme : AccordionThemeType;
 }

 export interface ISimSpecialistCertificates{
  certificates: [
    {
      id: string,
      name: string,
    }
  ];
  employment: string;
  googleMeetId: string;
  hireDate: number;
  stationType: string;
  tierId: string;
  zoomId: string;
}
 interface IClients {
  id: string;
  projectIds: string[];
}
interface IAccmanager {
  clients: IClients[];
}
export interface IOriginalOrNewSimspecialist extends IUserProfile{
  accmanager: IAccmanager;
  simspecialist: ISimSpecialistCertificates;
  operations?: boolean;
  picture: string;
  profservice: boolean;
  simdesigner: boolean;
}

 type OmitFields = 'teams'
  | 'learners'
  | 'selfReviewCancelled'
  | 'completionRateFulfilled'
  | 'scenarioVersion'
  | 'schedulingRateFulfilled';
  
 export interface ICanceledRescheduledList  extends Omit<ISessionForList, OmitFields> {
  earlyRescheduledDate: number;
  canceledDate: number;
  lateRescheduledDate: number;
  originalSimSpecialist: IOriginalOrNewSimspecialist;
  newSimSpecialist:  IOriginalOrNewSimspecialist;
 }
