export default function openPopupWindow(url: string, name: string, size?: { width: number, height: number }) {
  const screenLeft = window.screenLeft !== undefined
    ? window.screenLeft
    : window.screenX;
  const screenTop = window.screenTop !== undefined
    ? window.screenTop
    : window.screenY;
  const { width, height } = screen;

  const systemZoom = width / window.screen.availWidth;
  const popupWidth = size ? size.width : width * 0.4;
  const popupHeight = size ? size.height : height * 0.8;
  const left = (width - popupWidth) / 2 / systemZoom + screenLeft;
  const top = (height - popupHeight) / 2 / systemZoom + screenTop;

  return window.open(url, name, `height=${popupWidth},width=${popupHeight},menubar=false,left=${left},top=${top}`);
}