import INamedEntry from 'src/app/data/common/interfaces/INamedEntry';
import { ISelectorOption } from 'src/components/Selector';

export const entryToId = <T = string, K = string>(entry: INamedEntry<T, K>): T => entry?.id;


export const getIdsFromNamedEntryValueOrArray = <T = string, K = string>(entries: Array<INamedEntry<T, K>> | INamedEntry<T, K> | null | undefined): T[] => {
  if (Array.isArray(entries)) {
    return entries.map(entry => entryToId<T, K>(entry));
  } else {
    return  entries ? [entries.id] : [];
  }
};


type EntryArrayToQuery<T = string, K = string> = (entries: Array<INamedEntry<T, K>> | null | undefined) => { value: string[] } | undefined;
export const entryArrayToQuery: EntryArrayToQuery = (entries) =>
  (entries && entries.length) ? ({ value: entries.map(entryToId) }) : undefined;


type SelectOptionToEntry = (value: ISelectorOption<any>) => INamedEntry;
export const selectOptionToNamedEntry: SelectOptionToEntry = (value) => ({
  id: `${value.value}`,
  name: `${value.label}`
});

export const getOptionValue = <OptionValueType = string | number>(option?: ISelectorOption<OptionValueType>) => option?.value;


type NamedEntryToSelectOption<T = string> = (value: INamedEntry<T>) => ISelectorOption<T>;
export const namedEntryToSelectOption: NamedEntryToSelectOption = (value) => ({
  value: `${value.id}`,
  label: `${value.name}`
});


export const toNamedEntryArray = (entity: any): INamedEntry[] | null => entity ? [{
  id: entity.id,
  name: entity.name
}] : null;


export type ValueType<Type, Nullable extends boolean = true> = Nullable extends true ? (Type[] | Type | null | undefined) : (Type[] | Type);

