import _isUndefined from 'lodash/isUndefined';
import _omitBy from 'lodash/omitBy';
import _omit from 'lodash/omit';
import * as queryString from 'querystring';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';

export default function getPageDataUrl(pageData: IListPageData, extraData?: any) {
  const data = {
    ..._omitBy(_omit(pageData, 'page', 'size'), _isUndefined),
    ...extraData
  };

  return `/${pageData.page}/${pageData.size}?${queryString.stringify(data)}`;
}
