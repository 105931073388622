export enum ReportFrequencyType {
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
}

export enum ReportSessionType {
    ALL = 'ALL',
    PREVIOUS_DAY = 'PREVIOUS_DAY',
    PREVIOUS_7_DAYS = 'PREVIOUS_7_DAYS',
    CURRENT_DAY = 'CURRENT_DAY',
    NEXT_7_DAYS = 'NEXT_7_DAYS',
    MONTH_TO_DATE = 'MONTH_TO_DATE',
    PREVIOUS_FULL_MONTH = 'PREVIOUS_FULL_MONTH',
    PREVIOUS_30_DAYS = 'PREVIOUS_30_DAYS',
}

export enum DashboardFilterType {
    provider = 'provider',
    client = 'clientIds',
    projects = 'projectIds',
    scenarios = 'scenarioIds',
    scenarioVersion = 'scenarioVersion',
    deliveryModes = 'deliveryModes',
    learnerSessionStatuses = 'learnerSessionStatuses',
    recorded = 'recorded',
    standardized = 'standardized',
    billable = 'billable',
    startDate = 'startDate',
    endDate = 'endDate',
    simulations = 'simulationIds',
    sessionStatus = 'sessionStatus',
    sessionStatusLabels = 'sessionStatusLabels',
    teams = 'teamIds',
    lateReschedule = 'lateReschedule',
}

export enum DefaultFilterIdPayloadType {
    CLIENT = 'CLIENT',
    TEAM = 'TEAM',
    PROJECT = 'PROJECT',
    SCENARIO = 'SCENARIO',
    PROVIDER = 'PROVIDER',
    CONTRACT = 'CONTRACT'
}

export enum SessionSummariesType {
    SessionSummaries = '[Session Summaries]',
    SimulationRecorded = 'Simulation Recorded',
    SessionStatus = 'Session Status',
}