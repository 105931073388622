import { TLocaleId } from 'src/i18n';
import bookedPrebookedImage from 'src/images/sessionstatuslabels/booked-prebooked.svg';
import pendingTypeAllImage from 'src/images/sessionstatuslabels/pending-type-all.svg'; 
import activeCompleteImage from 'src/images/sessionstatuslabels/active-complete.svg';
import learnerMissedLatecancelledImage from 'src/images/sessionstatuslabels/missed-late-canceled.svg';
import systemErrorImage from 'src/images/sessionstatuslabels/system-error.svg';
import earlySystemCancelledImage from 'src/images/sessionstatuslabels/early-system-caneled.svg';
import { IntlShape } from 'react-intl/lib';

// Do NOT apply i18n ids here,
// these are the status types from BE
enum SessionStatusType {
  UNDEFINED = 'UNDEFINED',
  ORPHAN = 'ORPHAN',
  RESERVED = 'RESERVED',
  WAIF = 'WAIF',
  BOOKED = 'BOOKED',
  UPCOMING = 'UPCOMING',
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  MISSED = 'MISSED',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  REVIEWED = 'REVIEWED',
  CANCELLED = 'CANCELLED',
  LICENSEE_CANCELLED = 'LICENSEE_CANCELED',
  LATE_CANCELLED = 'LATE_CANCELED',
  EARLY_CANCELLED = 'EARLY_CANCELED',
  SWAP = 'SWAP',
  ERROR = 'ERROR',
  EARLY_RESCHEDULED = 'EARLY_RESCHEDULED',
  LATE_RESCHEDULED = 'LATE_RESCHEDULED',
  JOIN_NOW_REQUESTS = 'JOIN_NOW',
  AUTO_SIM_REQUESTS = 'AUTO_SIM',
  INCOMPLETE = 'INCOMPLETE'
}

export enum LearnerStatusType {
  CONNECTED = 'CONNECTED',
  LATE = 'LATE',
  IMMERSING = 'IMMERSING',
  COMPLETED = 'COMPLETED',
  LEFT = 'LEFT',
  ERROR = 'ERROR',
  SIM_ERROR='ERROR',
  DECLINED = 'DECLINED',
  MISSED = 'MISSED',
  UNABLE_TO_COMPLETE = 'UNABLE_TO_COMPLETE'
}
 
export enum SessionStatusSubType {
  DEMAND_BASED = 'DEMAND_BASED',
  BASIC = 'BASIC',
  JOIN_NOW_REQUESTS = 'JOIN_NOW',
  AUTO_SIM_REQUESTS = 'AUTO_SIM',
}

// Do NOT apply i18n ids here,
// these are the status labels from BE
export enum SessionStatusLabelType {
  ACTIVE = 'Active',
  COMPLETED = 'Complete',
  PENDING_NO_SS = 'Pending (No SS)',
  PENDING_NO_L = 'Pending (No L)',
  PENDING_NO_LEARNERS = 'Pending (No Learners)',
  BOOKED = 'Booked',
  REQUESTED = 'Requested',
  SWAP = 'Pending (Swap)',
  PENDING_NO_PARTICIPANTS = 'Pending (No L/SS)',
  PREBOOKED = 'Pre-booked',
  ERROR = 'Error',
  MISSED = 'Missed',
  CANCELLED = 'Cancelled',
  LICENSEE_CANCELLED = 'Canceled', // <licenseeName> + Cancelled
  LATE_CANCELLED = 'Late Canceled',
  EARLY_CANCELLED = 'Early Canceled',
  NEEDS_REVIEW = 'Needs review',
  EMPTY = '',
  INCOMPLETE = 'Incomplete',
}

export const learnerStatusMapping: { [key: string]: string } = {
  'Connected': 'Connected',
  'Late': 'Too Late to Complete',
  'Immersing': 'Immersing',
  'Completed': 'Completed',
  'Left': 'Elected to Leave',
  'Error': 'Technical Error (Learner)',
  'Declined': 'Declined',
  'Missed': 'No Show',
  'Unable to complete': 'Unable to Complete'
};

export enum SessionStatusLabelTypeColor{
  ACTIVE = '#06817D',
  COMPLETED = '#06817D',
  PENDING_NO_SS = '#C35424',
  PENDING_NO_L = '#C35424',
  PENDING_NO_LEARNERS = '#C35424',
  BOOKED = '#561AD6',
  REQUESTED = '#561AD6',
  SWAP = '#C35424',
  PENDING_NO_PARTICIPANTS = '#C35424',
  PREBOOKED = '#561AD6',
  ERROR = '#5D6973',
  MISSED = '#CB000E',
  CANCELLED = '#C35424',
  LICENSEE_CANCELLED = '#C35424',
  LATE_CANCELLED = '#CB000E',
  EARLY_CANCELLED = '#C35424',
  NEEDS_REVIEW = '#561AD6',
  EMPTY = '',
}


export default SessionStatusType;


/**
 * Get localized session status label type id by status label type
 * @param sessionStatusLabelType
 */
export const getSessionStatusLabelTypeI18nId = (sessionStatusLabelType: SessionStatusLabelType): TLocaleId => {
  switch (sessionStatusLabelType) {
    case SessionStatusLabelType.ACTIVE:
      return 'Mursion.Portal.Status.Active';
    case SessionStatusLabelType.COMPLETED:
      return 'Mursion.Portal.Status.Complete';
    case SessionStatusLabelType.PENDING_NO_SS:
      return 'MursionPortal.Status.PendingNoSS';
    case SessionStatusLabelType.PENDING_NO_L:
      return 'MursionPortal.Status.PendingNoL';
    case SessionStatusLabelType.PENDING_NO_LEARNERS:
      return 'MursionPortal.Status.PendingNoLearners';
    case SessionStatusLabelType.BOOKED:
      return 'Mursion.Portal.Status.Booked';
    case SessionStatusLabelType.REQUESTED:
      return 'Mursion.Portal.Status.Requested';
    case SessionStatusLabelType.SWAP:
      return 'Mursion.Portal.Status.PendingSwap';
    case SessionStatusLabelType.PENDING_NO_PARTICIPANTS:
      return 'MursionPortal.Status.PendingNoLSS';
    case SessionStatusLabelType.PREBOOKED:
      return 'Mursion.Portal.Status.PreBooked';
    case SessionStatusLabelType.ERROR:
      return 'Mursion.Portal.Status.Error';
    case SessionStatusLabelType.MISSED:
      return 'Mursion.Portal.Status.Missed';
    case SessionStatusLabelType.CANCELLED:
      return 'Mursion.Portal.Status.Cancelled';  
    case SessionStatusLabelType.LICENSEE_CANCELLED:
      return 'Mursion.Portal.Status.SystemCancelled';
    case SessionStatusLabelType.LATE_CANCELLED:
        return 'Mursion.Portal.Status.LateCancelled';
    case SessionStatusLabelType.EARLY_CANCELLED:
      return 'Mursion.Portal.Status.EarlierCancelled';
    case SessionStatusLabelType.NEEDS_REVIEW:
      return 'Mursion.Portal.Status.NeedsReview';
    case SessionStatusLabelType.EMPTY:
      return 'MursionPortal.Status.Empty';
    default :
      return 'MursionPortal.Status.Empty';
  }
};
// Do NOT apply i18n ids here
export enum ScenarioTypes {
  Certified_Scenarios = 'Certified Scenarios',
  Uncertified_Scenarios = 'Uncertified Scenarios',
}

export const getTranslatedScenarios = (scenario: string): TLocaleId => {
  switch (scenario) {
    case ScenarioTypes.Certified_Scenarios:
      return 'Requests.Table.CertifiedScenarios';
    case ScenarioTypes.Uncertified_Scenarios:
      return 'Requests.Table.UncertifiedScenarios';
    default:
      return 'Requests.Table.CertifiedScenarios';
  }
};


export const getTranslatedReason = (reason: string): string => {
  switch (reason) {
    case 'Unavailable':
      return 'Requests.Table.UnAvailable';
    case 'Not Prepared':
      return 'Requests.Table.NotPrepared';
    case 'Other':
      return 'Requests.Table.Other';
    default:
      return '';
  }
};

/**
 * Get localized session status type id by status label
 * @param sessionStatusType
 * @param capitalized
 */
export const getSessionStatusTypeI18nId = (sessionStatusType: SessionStatusType, capitalized: boolean = true): TLocaleId => {
  const capitalizedStr = capitalized ? '.Capitalized' : '';
  switch (sessionStatusType) {
    case SessionStatusType.UNDEFINED:
      return `MursionPortal.Status${capitalizedStr}.Undefined` as TLocaleId;
    case SessionStatusType.ORPHAN:
      return `MursionPortal.Status${capitalizedStr}.Orphan` as TLocaleId;
    case SessionStatusType.RESERVED:
      return `MursionPortal.Status${capitalizedStr}.Reserved` as TLocaleId;
    case SessionStatusType.WAIF:
      return `MursionPortal.Status${capitalizedStr}.Waif` as TLocaleId;
    case SessionStatusType.BOOKED:
      return `MursionPortal.Status${capitalizedStr}.Booked` as TLocaleId;
    case SessionStatusType.UPCOMING:
      return `MursionPortal.Status${capitalizedStr}.Upcoming` as TLocaleId;
    case SessionStatusType.PENDING:
      return `MursionPortal.Status${capitalizedStr}.Pending` as TLocaleId;
    case SessionStatusType.RUNNING:
      return `MursionPortal.Status${capitalizedStr}.Running` as TLocaleId;
    case SessionStatusType.COMPLETED:
      return `MursionPortal${capitalizedStr}.Completed` as TLocaleId;
    case SessionStatusType.MISSED:
      return `MursionPortal.Status${capitalizedStr}.Missed` as TLocaleId;
    case SessionStatusType.NEEDS_REVIEW:
      return `MursionPortal.Status${capitalizedStr}.NeedsReview` as TLocaleId;
    case SessionStatusType.REVIEWED:
      return `MursionPortal.Status${capitalizedStr}.Reviewed` as TLocaleId;
    case SessionStatusType.CANCELLED:
      return `MursionPortal.Status${capitalizedStr}.LicenseCancelled` as TLocaleId;
    case SessionStatusType.LICENSEE_CANCELLED:
      return `MursionPortal.Status${capitalizedStr}.SystemCancelled` as TLocaleId;
    case SessionStatusType.LATE_CANCELLED:
      return `MursionPortal.Status${capitalizedStr}.LateCancelled` as TLocaleId;
    case SessionStatusType.EARLY_CANCELLED:
      return `MursionPortal.Status${capitalizedStr}.EarlierCancelled` as TLocaleId;
      case SessionStatusType.INCOMPLETE:
        return `MursionPortal${capitalizedStr}.Incomplete` as TLocaleId;
    case SessionStatusType.SWAP:
      return 'Requests.Table.Swap';
    case SessionStatusType.ERROR:
      return `MursionPortal.Status${capitalizedStr}.Error` as TLocaleId;
    default:
      return 'MursionPortal.Status.Empty';
  }
};


export const getLearnerStatusLabelTypeI18nId = (learnerStatusLabelType: LearnerStatusType): TLocaleId => {
  switch (learnerStatusLabelType) {
    case LearnerStatusType.CONNECTED:
      return 'MursionPortal.Status.Connected.Label';
    case LearnerStatusType.LATE:
      return 'Mursion.Portal.Status.TooLateToComplete';
    case LearnerStatusType.IMMERSING:
      return 'MursionPortal.Status.Immersing.Label';
    case LearnerStatusType.COMPLETED:
      return 'Reports.SessionListHeader.Completed';
    case LearnerStatusType.LEFT:
      return 'Mursion.Portal.Status.ElectedToLeave';
    case LearnerStatusType.ERROR:
      return 'Mursion.Portal.Status.TechnicalErrorLearner';
    case LearnerStatusType.SIM_ERROR:
      return 'MursionPortal.Status.Capitalized.SimError';
    case LearnerStatusType.DECLINED:
      return 'Requests.Table.Declined';
    case LearnerStatusType.MISSED:
      return 'Mursion.Portal.Status.No.Show';
    case LearnerStatusType.UNABLE_TO_COMPLETE:
      return 'Mursion.Portal.Status.UnableToComplete';
    default :
      return 'MursionPortal.Status.Empty';
  }
};

export function setSessionStatusLabelWithColorIcon(sessionStatusLabelType: SessionStatusLabelType | undefined, intl: IntlShape) {
  
  switch (sessionStatusLabelType) {
    
    case SessionStatusLabelType.COMPLETED:
      return {
        label:  intl.formatMessage({ id: 'Mursion.Portal.Status.Complete' }) as TLocaleId ,
        icon: activeCompleteImage,
        color: SessionStatusLabelTypeColor.COMPLETED,
        alt: intl.formatMessage({ id: 'Mursion.Portal.Status.Complete' }) as TLocaleId,
      };

    case SessionStatusLabelType.PENDING_NO_SS:
      return {
        label: intl.formatMessage({ id: 'MursionPortal.Status.PendingNoSS'}) as TLocaleId,
        icon: pendingTypeAllImage,
        color: SessionStatusLabelTypeColor.PENDING_NO_SS,
        alt: intl.formatMessage({ id:'MursionPortal.Status.PendingNoSS'}) as TLocaleId,
      };

    case SessionStatusLabelType.PENDING_NO_L:
      return {
        label: intl.formatMessage({ id: 'MursionPortal.Status.PendingNoL'}) as TLocaleId,
        icon: pendingTypeAllImage,
        color: SessionStatusLabelTypeColor.PENDING_NO_L,
        alt: intl.formatMessage({ id:'MursionPortal.Status.PendingNoL'}) as TLocaleId,
      };

    case SessionStatusLabelType.PENDING_NO_LEARNERS:
      return {
        label: intl.formatMessage({ id: 'MursionPortal.Status.PendingNoLearners'}) as TLocaleId,
        icon: pendingTypeAllImage,
        color: SessionStatusLabelTypeColor.PENDING_NO_LEARNERS,
        alt: intl.formatMessage({ id:'MursionPortal.Status.PendingNoLearners'}) as TLocaleId,
      };

    case SessionStatusLabelType.BOOKED:
      return {
        label: intl.formatMessage({ id: 'Mursion.Portal.Status.Booked'}) as TLocaleId,
        icon: bookedPrebookedImage,
        color: SessionStatusLabelTypeColor.BOOKED,
        alt: intl.formatMessage({ id:'Mursion.Portal.Status.Booked'}) as TLocaleId,
      };

    case SessionStatusLabelType.SWAP:
      return {
        label: intl.formatMessage({ id: 'Mursion.Portal.Status.PendingSwap'}) as TLocaleId,
        icon: pendingTypeAllImage,
        color: SessionStatusLabelTypeColor.SWAP,
        alt: intl.formatMessage({ id:'Mursion.Portal.Status.PendingSwap'}) as TLocaleId,
      };

    case SessionStatusLabelType.PENDING_NO_PARTICIPANTS:
      return {
        label: intl.formatMessage({ id: 'MursionPortal.Status.PendingNoLSS'}) as TLocaleId,
        icon: pendingTypeAllImage,
        color: SessionStatusLabelTypeColor.PENDING_NO_PARTICIPANTS,
        alt: intl.formatMessage({ id:'MursionPortal.Status.PendingNoLSS'}) as TLocaleId,
      };

    case SessionStatusLabelType.PREBOOKED:
      return {
        label: intl.formatMessage({ id: 'Mursion.Portal.Status.PreBooked'}) as TLocaleId,
        icon: bookedPrebookedImage,
        color: SessionStatusLabelTypeColor.PREBOOKED,
        alt: intl.formatMessage({ id:'Mursion.Portal.Status.PreBooked'}) as TLocaleId,
      };

    case SessionStatusLabelType.ERROR:
      return {
        label: intl.formatMessage({ id: 'Mursion.Portal.Status.Error'}) as TLocaleId,
        icon: systemErrorImage,
        color: SessionStatusLabelTypeColor.ERROR,
        alt: intl.formatMessage({ id:'Mursion.Portal.Status.Error'}) as TLocaleId,
      };

    case SessionStatusLabelType.MISSED:
      return {
        label: intl.formatMessage({ id: 'Mursion.Portal.Status.LearnerMissed'}) as TLocaleId,
        icon: learnerMissedLatecancelledImage,
        color: SessionStatusLabelTypeColor.MISSED,
        alt: intl.formatMessage({ id: 'Mursion.Portal.Status.LearnerMissed'}) as TLocaleId,
      };

    case SessionStatusLabelType.CANCELLED:
      return {
        label: intl.formatMessage({ id: 'Mursion.Portal.Status.Cancelled'}) as TLocaleId,
        icon: earlySystemCancelledImage,
        color: SessionStatusLabelTypeColor.CANCELLED,
        alt: intl.formatMessage({ id:'Mursion.Portal.Status.Cancelled'}) as TLocaleId,
      };

    case SessionStatusLabelType.ACTIVE:
    return {
      label: intl.formatMessage({ id: 'Mursion.Portal.Status.Active'}) as TLocaleId,
      icon: activeCompleteImage,
      color: SessionStatusLabelTypeColor.ACTIVE,
      alt: intl.formatMessage({ id:'Mursion.Portal.Status.Active'}) as TLocaleId,
    };
      
    case SessionStatusLabelType.LATE_CANCELLED:
      return {
        label: intl.formatMessage({ id: 'Mursion.Portal.Status.LateCancelled'}) as TLocaleId,
        icon: learnerMissedLatecancelledImage,
        color: SessionStatusLabelTypeColor.LATE_CANCELLED,
        alt: intl.formatMessage({ id:'Mursion.Portal.Status.LateCancelled'}) as TLocaleId,
      };

    case SessionStatusLabelType.EARLY_CANCELLED:
      return {
        label: intl.formatMessage({ id: 'Mursion.Portal.Status.EarlierCancelled'}) as TLocaleId,
        icon: earlySystemCancelledImage,
        color: SessionStatusLabelTypeColor.EARLY_CANCELLED,
        alt: intl.formatMessage({ id:'Mursion.Portal.Status.EarlierCancelled'}) as TLocaleId,
      };

    case SessionStatusLabelType.NEEDS_REVIEW:
      return {
        label: intl.formatMessage({ id: 'Mursion.Portal.Status.NeedsReview'}) as TLocaleId,
        icon: systemErrorImage,
        color: SessionStatusLabelTypeColor.NEEDS_REVIEW,
        alt: intl.formatMessage({ id:'Mursion.Portal.Status.NeedsReview'}) as TLocaleId,
      };

    case SessionStatusLabelType.INCOMPLETE:
      return {
        label: intl.formatMessage({ id: 'MursionPortal.SurveyFilter.Incomplete' }) as TLocaleId,
        icon: pendingTypeAllImage,
        color: SessionStatusLabelTypeColor.PENDING_NO_L,
        alt: intl.formatMessage({ id: 'MursionPortal.SurveyFilter.Incomplete' }) as TLocaleId,
      };

    default:
      if(sessionStatusLabelType && /canceled/gi.test(sessionStatusLabelType)){
        return {
          label: sessionStatusLabelType,
          icon: earlySystemCancelledImage,
          color: SessionStatusLabelTypeColor.LICENSEE_CANCELLED,
          alt: sessionStatusLabelType,
        };
      }
      return {
        label: '',
        icon: '',
        color: '',
        alt: intl.formatMessage({ id:'MursionPortal.SessionReport.NotAvailable'}) as TLocaleId,
      };
  }
}
