const riskyChars = ['=', '+', '-', '@'];

export const isInjected = (str: string) => {
  const firstChar = str.charAt(0);
  return riskyChars.includes(firstChar);
};

const csvInjectionProtector = (str: string): string => {
  if (!str) {
    return '';
  }

  if (!isInjected(str)) {
    return str;
  }

  const slicedStr = str.slice(1);

  return csvInjectionProtector(slicedStr);
};

export default csvInjectionProtector;