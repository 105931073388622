import RoleID from "../../common/interfaces/RoleID";
import { intl } from "src/i18n/createIntl";
import { TLocaleId } from 'src/i18n';

const SelectedFilterViewRoleGroup = {
    BA_SA: [
      RoleID.BUSINESS_ADMIN,
      RoleID.SUPPORT_ADMIN,
    ],
    PS_PM_BA_SA_Ops: [  
      RoleID.PROF_SERVICE,
      RoleID.ACC_MANAGER,
      RoleID.BUSINESS_ADMIN,
      RoleID.SUPPORT_ADMIN,
      RoleID.OPERATIONS,
    ],
  };

const defaultObject = {
    filters: null,
    selected: true,
    roles: null,
};

enum AccessKeys {
    PROVIDER = 'PROVIDER',
    CLIENT = 'CLIENT',
    TEAM = 'TEAM',
    PROJECT = 'PROJECT',
    SCENARIO = 'SCENARIO',
    SCENARIO_VERSION = 'SCENARIO_VERSION',
    DELIVERY_MODE = 'DELIVERY_MODE',
    LEARNER_SESSION_STATUS = 'LEARNER_SESSION_STATUS',
    SESSION_STATUS = 'SESSION_STATUS',
    LEARNER_STATUS = 'LEARNER_STATUS',
    SIMULATION = 'SIMULATION',
    REGISTRATION_STATUS = 'REGISTRATION_STATUS',
    CONTRACT = 'CONTRACT',
    STANDARDIZED = 'STANDARDIZED',
    SIMULATION_RECORD = 'SIMULATION_RECORD',
    SIMULATION_STATUS = 'SIMULATION_STATUS',
    RECORD = 'RECORD',
    SOFTWARE_VERSION = 'SOFTWARE_VERSION',
    BILLABLE = 'BILLABLE',
    LATE_RESCHEDULED = 'LATE_RESCHEDULED',
}

enum FilterLabel {
    Provider = 'Provider',
    Client = 'Client',
    Contract = 'Contract',
    Team = 'Team',
    Projects = 'Projects',
    Scenarios = 'Scenarios',
    SoftwareVersions = 'Software Versions',
    DeliveryModes = 'Delivery Modes',
    LearnerSessionStatuses = 'Learner Session Statuses',
    SessionStatus = 'Session Status',
    LearnerStatuses = 'Learner Statuses',
    Simulations = 'Simulations',
    RegistrationStatus = 'Registration Status',
    Recorded = 'Recorded',
    Standardized = 'Standardized',
    LateRescheduled = 'Late Rescheduled',
    SimulationRecorded = 'Simulation Recorded',
    SimulationStatus = 'Simulation Status',
    SoftwareVersion = 'Software Version',
    Billable = 'Billable',
}

export const FILTER_LABEL: { [key in FilterLabel]: TLocaleId } = {
    [FilterLabel.Provider]: 'MursionPortal.Table.ColumnHeader.Provider',
    [FilterLabel.Client]: 'MursionPortal.Client',
    [FilterLabel.Contract]: 'Filters.Contract',
    [FilterLabel.Team]: 'Filters.Team',
    [FilterLabel.Projects]: 'MursionPortal.Heading.Projects',
    [FilterLabel.Scenarios]: 'Dashboard.UserTable.Column.Scenarios',
    [FilterLabel.SoftwareVersions]: 'MursionPortal.Report.FilterHeading.SoftwareVersions',
    [FilterLabel.DeliveryModes]: 'MursionPortal.Report.FilterHeading.DeliveryModes',
    [FilterLabel.LearnerSessionStatuses]: 'MursionPortal.Report.FilterHeading.LearnerSessionStatuses',
    [FilterLabel.SessionStatus]: 'Filters.SessionStatus',
    [FilterLabel.LearnerStatuses]: 'MursionPortal.Report.FilterHeading.LearnerStatuses',
    [FilterLabel.Simulations]: 'MursionPortal.Dashboard.Chart.Simulations',
    [FilterLabel.RegistrationStatus]: 'Filters.RegistrationStatus',
    [FilterLabel.Recorded]: 'Dashboard.Learners.Column.Recorded',
    [FilterLabel.Standardized]: 'Filters.Standardized',
    [FilterLabel.LateRescheduled]: 'Filters.LateRescheduled',
    [FilterLabel.SimulationRecorded]: 'Filters.SimulationRecorded',
    [FilterLabel.SimulationStatus]: 'MursionPortal.Report.FilterHeading.SimulationStatus',
    [FilterLabel.SoftwareVersion]: 'MursionPortal.Filters.SoftwareVersion',
    [FilterLabel.Billable]: 'Dashboard.ProjectsTable.ColumnHeader.Billable',
};

const defaultScenario = {
    ...defaultObject,
    name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.Scenarios] }),
    accessKey: AccessKeys.SCENARIO,
};

export const DEFAULT_TABS_FILTERS_CONFIGURATIONS = [
    {
        ...defaultObject,
        key : 'provider',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.Provider] }),
        roles: SelectedFilterViewRoleGroup.BA_SA,
        accessKey: AccessKeys.PROVIDER,
    },
    {
        ...defaultObject,
        key : 'providerId',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.Provider] }),
        roles: SelectedFilterViewRoleGroup.BA_SA,
        accessKey: AccessKeys.PROVIDER,
    },
    {
        ...defaultObject,
        key : 'client',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.Client] }),
        roles: SelectedFilterViewRoleGroup.PS_PM_BA_SA_Ops,
        accessKey: AccessKeys.CLIENT,
    },
    {
        ...defaultObject,
        key : 'clientId',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.Client] }),
        roles: SelectedFilterViewRoleGroup.PS_PM_BA_SA_Ops,
        accessKey: AccessKeys.CLIENT,
    },
    {
        ...defaultObject,
        key : 'clientIds',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.Client] }),
        roles: SelectedFilterViewRoleGroup.PS_PM_BA_SA_Ops,
        accessKey: AccessKeys.CLIENT,
    },
    {
        ...defaultObject,
        key : 'contract',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.Contract] }),
        accessKey: AccessKeys.CONTRACT,
    },
    {
        ...defaultObject,
        key : 'contractId',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.Contract] }),
        accessKey: AccessKeys.CONTRACT,
    },
    {
        ...defaultObject,
        key : 'teams',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.Team] }),
        accessKey: AccessKeys.TEAM,
    },
    {
        ...defaultObject,
        key : 'teamIds',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.Team] }),
        accessKey: AccessKeys.TEAM,
    },
    {
        ...defaultObject,
        key : 'projects',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.Projects] }),
        accessKey: AccessKeys.PROJECT,
    },
    {
        ...defaultObject,
        key : 'projectIds',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.Projects] }),
        accessKey: AccessKeys.PROJECT,
    },
    {
        ...defaultObject,
        key : 'project',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.Projects] }),
        accessKey: AccessKeys.PROJECT,
    },
    {
        ...defaultScenario,
        key : 'scenarios',
    },
    {
        ...defaultScenario,
        key : 'scenario',
    },
    {
        ...defaultScenario,
        key : 'scenariosIds',
    },
    {
        ...defaultScenario,
        key : 'scenarioIds',
    },
    {
        ...defaultObject,
        key : 'scenarioVersion',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.SoftwareVersions] }),
        accessKey: AccessKeys.SCENARIO_VERSION,
    },
    {
        ...defaultObject,
        key : 'deliveryModes',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.DeliveryModes] }),
        accessKey: AccessKeys.DELIVERY_MODE,
    },
    {
        ...defaultObject,
        key : 'deliveryMode',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.DeliveryModes] }),
        accessKey: AccessKeys.DELIVERY_MODE,
    },
    {
        ...defaultObject,
        key : 'learnerSessionStatuses',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.LearnerSessionStatuses] }),
        accessKey: AccessKeys.LEARNER_SESSION_STATUS,
    },
    {
        ...defaultObject,
        key : 'sessionStatusLabels',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.SessionStatus] }),
        accessKey: AccessKeys.SESSION_STATUS,
    },
    {
        ...defaultObject,
        key : 'learnerStatus',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.LearnerStatuses] }),
        accessKey: AccessKeys.LEARNER_STATUS,
    },
    {
        ...defaultObject,
        key : 'simulations',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.Simulations] }),
        accessKey: AccessKeys.SIMULATION,
    },
    {
        ...defaultObject,
        key : 'registrationStatus',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.RegistrationStatus] }),
        accessKey: AccessKeys.REGISTRATION_STATUS,
    },
    {
        ...defaultObject,
        key : 'recorded',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.Recorded] }),
        accessKey: AccessKeys.RECORD,
    },
    {
        ...defaultObject,
        key : 'standardized',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.Standardized] }),
        accessKey: AccessKeys.STANDARDIZED,
    },
    {
        ...defaultObject,
        key : 'lateReschedule',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.LateRescheduled] }),
        accessKey: AccessKeys.LATE_RESCHEDULED,
    },
    {
        ...defaultObject,
        key : 'simulationRecorded',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.SimulationRecorded] }),
        accessKey: AccessKeys.SIMULATION_RECORD,
    },
    {
        ...defaultObject,
        key : 'simulationStatus',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.SessionStatus] }),
        accessKey: AccessKeys.SIMULATION_STATUS,
    },
    {
        ...defaultObject,
        key : 'simulationStatuses',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.SimulationStatus] }),
        accessKey: AccessKeys.SIMULATION_STATUS,
    },
    {
        ...defaultObject,
        key : 'softwareVersion',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.SoftwareVersion] }),
        accessKey: AccessKeys.SOFTWARE_VERSION,
    },
    {
        ...defaultObject,
        key : 'billable',
        name : intl().formatMessage({ id: FILTER_LABEL[FilterLabel.Billable] }),
        accessKey: AccessKeys.BILLABLE,
    }
];