import { IPageRequestParams } from 'src/app/data/common/interfaces/IRestRequest';
import IListDataResponse from 'src/app/data/common/interfaces/IListDataResponse';
import RestService from 'src/app/services/rest/RestService';
import { IProjectActivityDashboardItem } from 'src/app/data/dashboard/IProjectDashboard';

export function fetchDummyData<Response>(
  params: IPageRequestParams & { query: any },
  mapper: (item: IProjectActivityDashboardItem) => Response,
): Promise<IListDataResponse<Response>> {
  const { pageData, query, signal } = params;
  // tslint:disable-next-line:no-console
  console.trace('fetchDummyData params', pageData, query);

  return new Promise((resolve, reject) => {
    RestService.fetch(`${RestService.DASHBOARD_REST_URL}/project/activity`, {
      body: JSON.stringify({
        ...pageData, ...query,
        licenseeIds: query.providerId ? { value: [query.providerId] } : null,
        order: 'clientName',
      }),
      headers: RestService.generateHeaders({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      signal,
    }).then(({ content, ...result }) => {
      resolve({ ...result, content: content.map(mapper) });
    }).catch((e) => reject(e));
  });
}
