import RoleID from 'src/app/data/common/interfaces/RoleID';

export enum PortalVersion {
  CURRENT_PORTAL,
  NEW_PORTAL,
}

type PortalVersionType = number | undefined | null;

export const PORTAL_VERSION_VALUES = {
  [PortalVersion.CURRENT_PORTAL]: {
    label: 'MursionPortal.Label.OldPortal',
  },
  [PortalVersion.NEW_PORTAL]: {
    label: 'MursionPortal.Label.NewPortal',
  }
};

export const isNonCurrentPortalVersionForLearner = (portalVersion: PortalVersionType, roleID: string | undefined) => {
  if (portalVersion === undefined || portalVersion === null) {
    return false;
  }
  return portalVersion !== PortalVersion.CURRENT_PORTAL && roleID === RoleID.LEARNER;
};