export const PROJECT_DEFAULT_COLOR: string = '#3174ad';
export const SESSION_CLIENT_NOTE_MAX_LENGTH = 1500;
export const MAX_SCENARIO_NAME_LENGTH = 255;
export const MAX_TAG_LENGTH = 50;
export const MAX_FIELD_LENGTH = 255;
export const DEFAULT_VIGNETTE_MAX_LENGTH = 5000;
export const DEFAULT_AVAILABILITY_MAX_DAYS = 90;
export const MINUTES_JOIN_BEFORE = 30;
export const MAX_VIGNETTE_VISIBLE_LENGTH = 1000;
export const MINIMUM_COMPLETION_RATE = 1;
export const MAXIMUM_USER_CREATION_ALLOWED = 500;
export const MINIMUM_SCHEDULING_RATE = 1;
export const MINIMUM_MISS_CANCELLATION_RATE = 1;
export const DEFAULT_OUTCOMES_MAX_LENGTH = 500;
export const DEFAULT_GOALS_MAX_LENGTH = 500;
export const MIN_UPCOMING_AVAILABILITY = 0;
export const MAX_UPCOMING_AVAILABILITY_IN_HOURS = 24;
export const MAX_UPCOMING_AVAILABILITY_IN_SECONDS = 86400;
export const MAX_PERSONALIZED_DASHBOARD_SECTION_LENGTH = 800;
export const MAX_PERSONALIZED_SCHEDULING_SECTION_LENGTH = 2000;
export const MAX_PERSONALIZED_LEARNER_DASHBOARD_SECTION_LENGTH = 2000;
export const MAX_PERSONALIZED_TITLE_LENGTH = 255;
export const WAITING_TIME_TO_START_SESSION = 60000; // miliseconds
export const MIN_SESSION_MISSED_TIME = 1;
export const MINIMUM_SCHEDULING_LOCKOUT_TIME = 1;
export const ITEM_BANK_MAX_TITLE_LENGTH = 150;
export const ITEM_BANK_MAX_DESCRIPTION_LENGTH = 500;
export const DEFAULT_SCHEDULING_RATE = 2; // for project default value
export const DEFAULT_MISS_OR_CANCELATION_RATE = 3;
export const DEFAULT_SCHEDULING_LOCKOUT_TIME = 168; // in hours
export const INVITE_TO_SCHEDULE_MESSAGE_LENGTH = 1000;
export const MAX_EVENT_DASHBOARD_TITLE_LENGTH = 150;
export const MAX_EVENT_DASHBOARD_DESCRIPTION_LENGTH = 500;
export const DESCRIPTION_OF_UPDATE_MAX_LENGTH = 500;
export const VIEW_MORE_BUILDING_BLOCK_CONTENT_LIMIT = 300;
export const AVATAR_BUILDING_BLOCK_CONTENT_MAX_LENGTH = 50;
export const RESEARCH_SUMMARY_BUILDING_BLOCK_CONTENT_MAX_LENGTH = 5000;
export const PUBLISHER_TEXT_FIELD_MAX_LENGTH = 75;
export const RESOURCE_DESCRIPTION_BLOCK_MAX_LENGTH = 1000;
export const OUTCOME_BUILDING_BLOCK_TITLE_MAX_LENGTH = 300;
export const NEXTGEN_SCENARIO_AVATAR_PERSPECTIVE_INRO_LENGTH = 2000;
export const NEXTGEN_SCENARIO_AVATAR_PREP_GUIDE_LENGTH = 5000;
export const EVENT_OBSERVED_IMPACT_ACTION_MAX_LENGTH = 1000;
export const MAX_LENGTH_SYNOPSIS = 1000;
export const MAX_LENGTH_ASSESSMENT = 5000;
export const MIN_SKILLS = 2;
export const MAX_SKILLS = 6;
export const REFLECTION_GUIDE_FIELDS_MAX_LENGTH = 2000;
export const REFLECTION_GUIDE_HOST_ASSESSMENT_FIELDS_MAX_LENGTH = 1000;
export const VIEW_MORE_LEARNER_INVITE_LIMIT = 65;
export const MURSION_DEFAULT_EMAIL = 'no-reply@mursion.com';
export const MINIMUM_REMINDER_EMAIL_VALUE = 1;
export const SCENARIO_TITLE_MAX_LENGTH = 200;
export const SCENARIO_COVER_STORY_MAX_LENGTH = 7500;
export const WHY_THIS_MATTERS_MAX_LENGTH = 2500;
export const LEARNER_INSTRUCTION_MAX_LENGTH = 2500;
export const AVATAR_PRESPECTIVE_INTRO_MAX_LENGTH = 5000;
export const AVATAR_PRESPECTIVE_DISC_PROFILE_MAX_LENGTH = 2000;
export const AVATAR_BEHAVIOR_MAX_LENGTH = 2500;
export const EXPECTED_LEARNER_RESPONSE_MAX_LENGTH = 1000;
export const POSSIBLE_AVATAR_RESPONSE_MAX_LENGTH = 1000;
export const EVENT_SCORING_POSITIVE_DERAILING_MINDSET = 1000;
export const VERSION_NUMBER_MAX_LENGTH = 50;
export const VERSION_DETAILS_MAX_LENGTH = 1000;
export const LERARNER_INVITATIONS_SENDER_NAME_MAX_LENGTH = 100;
export const LERARNER_INVITATIONS_SUBJECT_MAX_LENGTH = 200;
export const CUSTOMIZATION_BTN_TEXT_MAX_LENGTH = 20;
export const LEARNER_ATTENDANCE_OTHER_ISSUE_MAX_LENGTH = 1000;
export const PROD_HOST_NAME = 'portal.mursion.com'; 
export const MAX_RESTORE_PASSWORD_ATTEMPTS_ALLOWED = 5;
export const RESTORE_PASSWORD_MAX_ATTEMPT_REACHED_MESSAGE = 'Maximum attempts reached for password reset';
export const EMAIL_MANAGEMENT_CHARACTER_LIMIT  = 2000;
export const MAX_LENGTH_CONTRACTID = 36;