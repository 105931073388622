import IProject, { DemandWindowType, ShowAvailabilityType } from 'src/app/data/projects/interfaces/IProject';
import generateNewProjectBaseData from 'src/app/data/projects/utils/generateNewProjectBaseData';
import generateNewClientShortData from 'src/app/data/projects/utils/generateNewClientShortData';
import RoleID from 'src/app/data/common/interfaces/RoleID';
import { defaultDemandBasedTimeSlots } from 'src/layouts/common/Projects/components/ProjectCard/components/NewProjectForm/components/ProjectCreateStep/ProjectCreateStep';
import moment from 'moment-timezone';
import { MAX_UPCOMING_AVAILABILITY_IN_SECONDS } from 'src/app/data/common/constants';
import { DeliveryHoursType } from 'src/app/data/common/utils/dateUtil';

export default function generateNewProjectData(): IProject {
  return {
    ...generateNewProjectBaseData(),
    accManagers: [],
    hours: 0,
    client: generateNewClientShortData(),
    createdDate: null,
    learnerCount: 0,
    scenarios: [],
    recordingAllowed: true,
    recordingDoNotShare: true,
    recordingRecipients: [RoleID.LEARNER],
    recordingLearnerChoice: true,
    billable: true,
    cancellationWindow: 172800, // default cancellation window as 2 days in seconds
    demandBasedScheduling: true,
    reschedulingEnabled:false,
    sameDayRescheduling: false,
    demandBasedSlotsCount: defaultDemandBasedTimeSlots,
    demandWindowType: DemandWindowType.HOURS,
    surveyEnabled: false,
    simPostSimulationSurveyOneToOne: false,
    simPostSimulationSurveyWorkshop: false,
    enabledReportOneToOneDeliveries: false,
    sessionTimeStep: null,
    demandWindow: 48,
    demandBasedSchedulingStartTime: moment().startOf('d').add(9, 'h').format('HH:mm'),
    demandBasedSchedulingEndTime: moment().startOf('d').add(17, 'h').format('HH:mm'),
    deliveryHoursStartTime: moment().startOf('d').add(9, 'h').format('HH:mm'),
    deliveryHoursEndTime: moment().startOf('d').add(17, 'h').format('HH:mm'),
    deliveryHoursType: DeliveryHoursType.STANDARD,
    deliveryWeekDays: {
      deliveryOnMonday: true,
      deliveryOnTuesday: true,
      deliveryOnWednesday: true,
      deliveryOnThursday: true,
      deliveryOnFriday: true,
      deliveryOnSaturday: false,
      deliveryOnSunday: false,
    },
    cancellationWindowType: DemandWindowType.HOURS,
    showAvailability: 0,
    showAvailabilityType: ShowAvailabilityType.ALL,
    upcomingAvailability: MAX_UPCOMING_AVAILABILITY_IN_SECONDS,
    sessionMissedTime: null,
    completionRate: null,
    schedulingRate: null,
    missOrCancelationRate: null,
    schedulingLockoutTime: null,
    scenarioSettingErrorMessage: '',
    virtualSimOnly: false,
  };
}


export const defaultProjectSettingsForAutoSim = {
  demandWindowType: DemandWindowType.MINUTES,
  demandWindow: 1,
  demandBasedSchedulingStartTime: moment().startOf('day').format('HH:mm'),
  demandBasedSchedulingEndTime: moment().startOf('day').set({ hour: 23, minute: 30 }).format('HH:mm'),
};