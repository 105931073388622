import { IErrorProperties, IErrorState, IErrorValidation } from 'src/app/data/common/interfaces/IRestError';
import capitalize from 'src/app/data/common/utils/capitalize';
import { IAuthError } from 'src/app/redux/modules/auth/rest';
import { intl } from 'src/i18n/createIntl';
import { RESTORE_PASSWORD_MAX_ATTEMPT_REACHED_MESSAGE } from 'src/app/data/common/constants';
import RestError from 'src/app/services/rest/RestError';

const errorCodes = [400,401,403];
export const ERROR_CODE_OBJECT_NOT_FOUND = 'object.not.found';
export const ERROR_CODE_NO_USER_ROLE = 'no.user.role';
export const ERROR_CODE_TIMELINE = 'ValidateTimeInterval';
export const ERROR_CODE_VALIDATION = 'validation';
export const ERROR_CODE_FORBIDDEN = 'forbidden';
export const ERROR_CODE_UNARCHIVED_DESCENDANT = 'unarchived.descendant';
export const ERROR_CODE_CONFLICT = 'conflict';
export const ERROR_CODE_SESSION_VIDEO_REJECTED = 'session.video.record.rejected';
export const ERROR_CODE_HAS_SESSIONS = 'has.sessions';
export const ERROR_CODE_TOO_LATE_SESSION = 'too.late.session';
export const ERROR_CODE_USER_IS_BUSY = 'user.is.busy';
export const ERROR_CODE_INVALID_STATUS = 'invalid.status';
export const ERROR_CODE_SS_ALREADY_ACCEPTED = 'ss.already.accepted';

const DEFAULT_MARKER = '%';
export const replaceWordBetweenCharacters = (message: string, mapping: any, marker = DEFAULT_MARKER) => {

  const m: string = marker || DEFAULT_MARKER;
  let parsedMessage = message;

  Object.keys(mapping).forEach((key) => {
    const regExp = new RegExp(m + '(?:' + key + ')' + m, 'g');

    parsedMessage = parsedMessage.replace(regExp, '"' + mapping[key] + '"');
  });

  return parsedMessage;
};


export function toUpperFirst(s: string): string {
  if (s) {
    s = s.charAt(0).toUpperCase() + s.slice(1);
  }
  return s;
}

/**
 * Converts text with unicode symbols (i.e. \u0000) to char code
 * @param text
 */
export function unicodeToChar(text: string) {
  return text.replace(/\\u[\dA-F]{4}/gi,
    (match: string) =>
      String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16)));
}

export function parseErrorMessage(error: IErrorState): string {
  const errorPropsMessage = parseErrorProperties(error.properties);
  const customErrorMessage = errorPropsMessage ? `\n${errorPropsMessage}` : '';
  return `${toUpperFirst(error.message)}${customErrorMessage}`;
}

export function parseErrorValidation(val: IErrorValidation, replaceMap?: any): string {
  let res = '';
  if (val.code === 'Unique') {
    return getUniqueErrorMessage(val.objectName, val.field) || `${getTranslatedFieldNames(val.field)} ${val.message}.`;
  } else if (val.code === 'IllegalFormat' && (val.objectName || '').indexOf('scenario') > -1) {
    res = intl().formatMessage({id:'MursionPortal.ErrorMessage.CannotContainCharacter'});
  } else if (val.code === 'invalid') {
    res = `${val.message}: ${val.rejectedValue}`;
  } else if (val.code === ERROR_CODE_CONFLICT
    || val.code === 'Size'
    || val.code === 'Email'
    || (val.objectName || '').indexOf('licenseeConfig') > -1
    || !!val.field
    || val.code === ERROR_CODE_TIMELINE
  ) {
    res = toUpperFirst(val.message);
  } else if (!res) {
    res = JSON.stringify(val);
  }

  return replaceMap ? replaceWordBetweenCharacters(unicodeToChar(res), replaceMap) : unicodeToChar(res);
}

function isNameIncludes(name: string, includes: string): boolean {
  return name.toLowerCase().indexOf(includes.toLowerCase()) > -1;
}

function getUniqueErrorMessageFor(dtoName: string, fieldName: string, objectNames: string[]) {
  for (const objectName of objectNames) {

    if (isNameIncludes(dtoName, objectName)) {
      return intl().formatMessage({id: 'MursionPortal.ErrorMessage.EnterDifferentName'}, {capitalized:capitalize(getTranslatedObjectNames(objectName)), fieldName: getTranslatedFieldNames(fieldName), objectName: getTranslatedObjectNames(objectName)});
    }
  }

  return '';
}

function getUniqueErrorMessage(dtoName: string, fieldName: string): string {
  if (isNameIncludes(dtoName, 'user')) {
    return fieldName === 'email'
      ? intl().formatMessage({id:'MursionPortal.ErrorMessage.EnterDifferentEmail'})
      : intl().formatMessage({id:'MursionPortal.ErrorMessage.NameExists'}, {fieldName: getTranslatedFieldNames(fieldName)});
  }

  return getUniqueErrorMessageFor(dtoName, fieldName, ['team', 'project', 'scenario', 'client', 'industry', 'environment', 'avatar']);
}


export function parseErrorProperties(p: IErrorProperties | null): string {
  if (!p) {
    return '';
  }

  let res = '';
  if (p.entity) {
    if (p.entity.entityName && p.descendantEntityType) {
      const entity = p.entity.entityName.toLowerCase()
        .replace(/licenseeuser/g, 'user');
      const depEntity = p.descendantEntityType.toLowerCase();
      res = intl().formatMessage({id:'MursionPortal.ErrorMessage.UnableToRemove'}, {entity, depEntity});
    } else if (p.entity.entityName === 'Availability' && p.entity.objectId === 'new') {
      res = intl().formatMessage({id:'MursionPortal.ErrorMessage.OverlapsExistingTimeBlock'});
    }
  }
  /* else {
    res = !_isEmpty(p) ? JSON.stringify(p) : '';
  }*/ // TODO parse the properties, don't show them raw
  return res;
}


export const getTranslatedFieldNames = (fieldName: string) => {
  if (fieldName === "name") {
    return intl().formatMessage({id:'MursionPortal.Name'});
  } 
  return fieldName;
};


export const getTranslatedObjectNames = (objectName: string) => {
  switch (objectName) {
    case 'team':
      return intl().formatMessage({ id: 'MursionPortal.Text.Team' });
    case 'project':
      return intl().formatMessage({ id: 'MursionPortal.Label.Project' });
    case 'scenario':
      return intl().formatMessage({ id: 'MursionPortal.Label.Scenario' });
    case 'client':
      return intl().formatMessage({ id: 'MursionPortal.Label.LowerCase.Client' });
    case 'industry':
      return intl().formatMessage({ id: 'MursionPortal.Text.Industry' });
    case 'environment':
      return intl().formatMessage({ id: 'MursionPortal.Text.Environment' });
    case 'avatar':
      return intl().formatMessage({ id: 'MursionPortal.Text.Avatar' });
    default :
      return objectName;
  }
};

export const isCancel = (errorCode: string | number | undefined) => {
  return errorCode && typeof errorCode === 'number' ? errorCodes.includes(errorCode) : true;
};

export function parseErrorCodeToMessage(error: IErrorState): string {
  if (error && error.code === ERROR_CODE_HAS_SESSIONS) {
    error.message = intl().formatMessage({ id: 'MursionPortal.Users.Archive.HasSessions' });
  }
   return parseErrorMessage(error);
}

export function checkIfRestorePasswordLimitExceedError(errorMessage: Partial<IAuthError>) {
  return (
    errorMessage.code === ERROR_CODE_FORBIDDEN && errorMessage.message === RESTORE_PASSWORD_MAX_ATTEMPT_REACHED_MESSAGE
  );
}

export function getForbiddenError(errorMessage: Partial<IAuthError>, statusCode: number) {
  let error = new RestError(intl().formatMessage({ id: 'MursionPortal.ErrorMessage.AccessIsDenied' }), statusCode);

  if (checkIfRestorePasswordLimitExceedError(errorMessage)) {
    error = new RestError(errorMessage.message ?? '', statusCode, undefined, errorMessage?.properties?.info?.attempts);
  }
  return error;
}