import moment, { Moment } from 'moment-timezone';
import { getAvailablePeriods } from 'src/app/data/calendar/utils/calendarEventUtils';
import ITimeInterval from 'src/app/data/common/interfaces/ITimeInterval';
import { isWeekEnd } from 'src/app/data/common/utils/calendarUtils';
import { convertDateToMoment, convertMomentToDate, minutesToMillisConverter } from 'src/app/data/common/utils/dateUtil';
import { TimeBlocksSection } from 'src/layouts/common/SimulationScheduling/components/TimeRangeBlocks/TimeRangeBlocks';
import { getTimeBlockName } from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/components/TimeBlock/utils';
import { isCurrentUserLearner } from 'src/layouts/common/Sessions/SessionsTable/components/SessionEditForm/utils';
import { ISlot } from 'src/layouts/common/SimulationScheduling/SimulationScheduling';
import { isCurrentUserBorF, isCurrentUserPSorOps } from 'src/app/data/common/utils/userRoleUtils';
import { IUserRoleExtended } from 'src/app/data/profile/interfaces/index';
import { DemandWindowType } from 'src/app/data/projects/interfaces/IProject';
import { ISimSpecialist } from 'src/app/data/session/interfaces/ISession';
import SessionStatusType from 'src/app/data/session/interfaces/SessionStatusType';
import SessionType from 'src/app/data/session/interfaces/SessionType';

export const timeFormat = "HH:mm";
export const defaultTimeStep = 30;
export const milliSecond = 1000;
export const msInOneDay = 86400000;

interface IRange {
  start: string;
  end: string;
}

export interface ITimeBlock {
  startTime: Moment;
  endTime: Moment;
  timeTitle?: string;
  isAvailable?: boolean;
}

export const getTimedDay = (timeStr: string, date: Moment): Moment => {
  const parsedTime = moment(timeStr, "HH:mm");
  return date.clone().hour(parsedTime.hours()).minute(parsedTime.minutes()).seconds(0).millisecond(0);
};

export const isCurrentUserPSorOpsAndScheduleType = (isUserUserPSorOps: boolean, scheduleType: string | undefined) => {
  return isUserUserPSorOps && scheduleType !== undefined;
};

export const addDisabledClass = (itemLength:number, disabledClass:string) => {
  return !itemLength && disabledClass;
};

export const getTimeRanges = (
  projectStartTime: string,
  projectEndTime: string,
  projectTz: string,
  userTz: string
): IRange[] => {
  const timezone = projectTz || moment.tz.guess();
  const startTime = getTimedDay(projectStartTime, moment().tz(timezone));
  const endTime = getTimedDay(projectEndTime, moment().tz(timezone));

  const ranges: IRange[] = [];
  const userTzStartTime = startTime.clone().tz(userTz);
  const userTzEndTime = endTime.clone().tz(userTz);

  if (userTzStartTime.isSame(userTzEndTime, "d")) {
    ranges.push({
      start: userTzStartTime.format(timeFormat),
      end: userTzEndTime.format(timeFormat),
    });
  } else {
    ranges.push({
      start: "00:00",
      end: userTzEndTime.format(timeFormat),
    },
    {
      start: userTzStartTime.format(timeFormat),
      end: "23:59",
    }
    );
  }
  return ranges;
};

export const getTimeSlotsPerDay = (
  start: Moment,
  endTime: Moment,
  stepper: number,
  sessionLength: number,
  projectTz: string,
  enableWeekends: boolean
): ITimeBlock[] => {
  const startTime = start.clone();
  const timeSlots: ITimeBlock[] = [];
  while (startTime.clone().add(sessionLength, "m").isSameOrBefore(endTime)) {
    if ((isWeekEnd(startTime.valueOf(), projectTz) && !enableWeekends)) {
      break;
    }
    timeSlots.push({
      startTime: startTime.clone(),
      endTime: startTime.clone().add(sessionLength, "m"),
    });
    startTime.add(stepper, "m");
  }
  return timeSlots;
};

// checks learner/sim availability
/**
 * The enddates of availability that we get from fetchScenarioAvailableTimeBlocks api have session length subtracted and 1 second added to them
 * eg- (when session length is of 30 mins)  
 * Actual availability - 9:00 AM - 9:30 AM 
 * Availability that we get in api response- 9:00 AM - 9:00:01 AM
 * Time slots generation in old scheduling flow (SessionWizard) was written with keeeping this api response in mind
 * However the new scheduling flow (Bookit) generates time slot in a different way, so we are adding the subtracted time to make enddate equal to actual availability enddate.
 * This has been done in release-2.10.1 in ticket MP-6512.
 */
export const isTimeBlockBooked = (availability: ITimeInterval[], slot: ITimeBlock, sessionLength?: number): boolean => {
  let enableTimeBlock = true;
  availability.forEach((avail: ITimeInterval) => { 
    let modifiedEnddate;
    if (sessionLength) {
      modifiedEnddate = avail.endDate + minutesToMillisConverter(sessionLength) - milliSecond;
    } else {
      modifiedEnddate = avail.endDate;
    }
    if (slot.startTime.valueOf() >= avail.startDate && slot.endTime.valueOf() <= modifiedEnddate) {
      enableTimeBlock = false;
    }
  });
  return enableTimeBlock;
};

export interface ITimeBlocksOnDays {
  date: number;
  timeSlots: ITimeBlock[];
}

export enum SchedulingType {
  INSTANT = "instant",
  DEMAND_BASED = "demandBased",
  EMERGENCY = "emergency",
  RESCHEDULING = "rescheduling",
}

export const isClientGroupDelivery = (userRole: IUserRoleExtended | null, scenarioDelivery?: SessionType) => {
  return isCurrentUserBorF(userRole) && scenarioDelivery === SessionType.GROUP;
};
interface ITimeSlotsForDaysParams {
  projectTz: string;
  userTz: string;
  startTimeStr: string;
  endTimeStr: string;
  selectedMonthStart: Moment;
  selectedMonthEnd: Moment;
  stepper: number;
  sessionLength: number;
  isCurrentUserClient: boolean;
  scenarioEndDay: number;
  restrictionTimeSlots: ITimeInterval[];
  availability: ITimeInterval[];
  schedulingType: SchedulingType;
}

export const getTimeSlotsForDays = (
  params: ITimeSlotsForDaysParams
): { timeBlocksOnDays: ITimeBlocksOnDays[]; excludedDates: Date[] } => {
  const {
    projectTz,
    userTz,
    startTimeStr,
    endTimeStr,
    selectedMonthStart,
    selectedMonthEnd,
    stepper,
    sessionLength,
    isCurrentUserClient,
    scenarioEndDay,
    restrictionTimeSlots,
    availability,
    schedulingType,
  } = params;
  const rangeArr = (schedulingType === SchedulingType.DEMAND_BASED) ? getTimeRanges(startTimeStr, endTimeStr, projectTz, userTz) : [];
  const timeBlocksOnDays = [];
  const excludedDates: Date[] = [];
  const startOfDayForInitialDate = selectedMonthStart.clone().startOf('d');
  for (let i = 0; startOfDayForInitialDate.clone().add(i, "d").isSameOrBefore(selectedMonthEnd); i++) {
    let accumTimeSlots: ITimeBlock[] = [];
    const date = startOfDayForInitialDate.clone().add(i, "d");
    if (isCurrentUserClient) {
      if (schedulingType === SchedulingType.DEMAND_BASED) {
        rangeArr.forEach((range: IRange) => {
          const startTime = getTimedDay(range.start, date);
          const endTime = getTimedDay(range.end, date);
          accumTimeSlots.push(
            ...getTimeSlotsPerDay(startTime.clone(), endTime, stepper, sessionLength, projectTz, false)
          );
        });
      }
      let simAvailableTimeSlots = getTimeSlotsPerDay(
        date.clone(),
        date.clone().add(1, 'd').startOf('d'),
        stepper,
        sessionLength,
        userTz,
        true
      );
      const simAvailabilityOnDate = getDateAvailability(availability, date, userTz);
      simAvailableTimeSlots = simAvailableTimeSlots.filter((tb: ITimeBlock) => {
        const returnTB = !isTimeBlockBooked(simAvailabilityOnDate, tb, sessionLength);
        if (returnTB) {
          tb.isAvailable = true;
        }
        return returnTB;
      });

      accumTimeSlots = [...accumTimeSlots, ...simAvailableTimeSlots].filter(
        (value, index, self) =>
          !self.find((item, j) => item.startTime.valueOf() === value.startTime.valueOf() && index !== j && index < j)
      );
    } else {
      accumTimeSlots = getTimeSlotsPerDay(date, date.clone().add(1, 'd').startOf('d'), stepper, sessionLength, userTz, true);
      accumTimeSlots = getPsOrOpsSlots(accumTimeSlots, date, sessionLength, stepper, userTz, availability);
    }
    // check for restrictions
    accumTimeSlots = accumTimeSlots.filter((tb: ITimeBlock) => {
      return !isTimeBlockRestricted(restrictionTimeSlots, tb);
    });

    if (selectedMonthStart.clone().isAfter(date)) {
      accumTimeSlots = filterTodayOrLastDayTimeBlocks(accumTimeSlots, selectedMonthStart.clone(), userTz, true);
    }

    const isLastDate = moment(scenarioEndDay).tz(userTz).isSame(date, "d");
    if (isLastDate) {
      const lastDate = moment(scenarioEndDay || "").tz(userTz);

      accumTimeSlots = filterTodayOrLastDayTimeBlocks(accumTimeSlots, lastDate, userTz, false, isLastDate, selectedMonthStart.clone());
    }
    if (!accumTimeSlots.length) {
      excludedDates.push(convertMomentToDate(startOfDayForInitialDate.clone().add(i, "d")));
    }

    timeBlocksOnDays.push({
      date: startOfDayForInitialDate.clone().add(i, "d").valueOf(),
      timeSlots: accumTimeSlots,
    });
  }
  return { timeBlocksOnDays, excludedDates };
};

export const todayStartTime = (bufferWindow: number): moment.Moment | null => {
  return moment().isSame(moment().add(bufferWindow, "ms"), "day")
    ? moment().add(bufferWindow, "ms")
    : null;
};

export const filterTodayOrLastDayTimeBlocks = (
  timeBlocks: ITimeBlock[],
  timeToCheck: moment.Moment,
  learnerTz: string,
  upperBound?: boolean,
  isLastDate?: boolean,
  selectedMonthStart?: moment.Moment,
): ITimeBlock[] => {
  const isToday = moment(timeToCheck).tz(learnerTz).isSame(moment().tz(learnerTz), "day");
  return timeBlocks.filter((tb: ITimeBlock) => {
    // when current date is also the last date of a scenario
    if (isLastDate && isToday && selectedMonthStart) {
      return tb.startTime.valueOf() >= selectedMonthStart.valueOf() && tb.endTime.valueOf() <= timeToCheck.valueOf();
    } else if (isToday || upperBound) {
      return tb.startTime.valueOf() >= timeToCheck.valueOf();
    } else {
      return tb.endTime.valueOf() <= timeToCheck.valueOf();
    }
  });
};

export const isTimeBlockRestricted = (restrictions: ITimeInterval[], tb: ITimeBlock): boolean => {
  let disableTimeBlock = false;

  restrictions.forEach((restriction: ITimeInterval) => {
    const isTBInBetweenRestrictions =
      tb.startTime.valueOf() >= restriction.startDate && tb.endTime.valueOf() <= restriction.endDate;
    const isTBPartiallyInStartDate =
      tb.startTime.valueOf() < restriction.startDate && tb.endTime.valueOf() > restriction.startDate;
    const isTBPartiallyInEndDate =
      tb.startTime.valueOf() < restriction.endDate && tb.endTime.valueOf() > restriction.endDate;
    if (isTBInBetweenRestrictions || isTBPartiallyInStartDate || isTBPartiallyInEndDate) {
      disableTimeBlock = true;
    }
  });
  return disableTimeBlock;
};

export const getTitle = ({ startTime, endTime }: { startTime: Moment; endTime: Moment }): string => {
  const timeValue = `${startTime.format("LT")} - ${endTime.format("LT")}`;
  const splitTitleByColon = timeValue.split(":");
  const splitByDash = splitTitleByColon[1].split("-");
  let parsedStartTime = "";
  if (splitByDash[0].substring(0, 2) === "00") {
    parsedStartTime = splitTitleByColon[0];
  } else {
    parsedStartTime = `${splitTitleByColon[0]}:${splitByDash[0].substring(0, 2)}`;
  }
  let parsedEndTime = "";
  if (splitTitleByColon[2].substring(0, 2) === "00" && splitTitleByColon[2].length > 2) {
    parsedEndTime = " " + splitTitleByColon[2].slice(-2).toLowerCase();
  } else if (splitTitleByColon[2].substring(0, 2) !== "00" && splitTitleByColon[2].length > 2) {
    parsedEndTime = `:${splitTitleByColon[2].slice(0, -2)}${splitTitleByColon[2].slice(-2).toLowerCase()}`;
  } else if (splitTitleByColon[2].substring(0, 2) !== "00" && splitTitleByColon[2].length === 2) {
    parsedEndTime = `:${splitTitleByColon[2]}`;
  }
  return `${parsedStartTime}-${splitByDash[1].trim()}${parsedEndTime}`;
};

// take availability array as an input, start of day and  get internally eod then get values from availability array which falls under this
export const getDateAvailability = (
  availability: ITimeInterval[],
  date: moment.Moment,
  learnerTz: string
): ITimeInterval[] => {
  return availability.filter((avail: ITimeInterval) => {
    const isDayInUpperRange = moment(date).tz(learnerTz).isSameOrAfter(moment(avail.startDate).tz(learnerTz), "day");
    const isDayInLowerRange = moment(date).tz(learnerTz).isSameOrBefore(moment(avail.endDate).tz(learnerTz), "day");
    return isDayInUpperRange && isDayInLowerRange;
  });
};

export const getPsOrOpsSlots = (
  filteredTBs: ITimeBlock[],
  selectedDate: moment.Moment,
  sessionLength: number,
  timeStep: number,
  userTimezoneId: string,
  schedulingDates: ITimeInterval[]
): ITimeBlock[] => {
  const timeSlots: ITimeBlock[] = [];
  const isDstChangeDate =
    filteredTBs.length > 2 &&
    getDstTime(filteredTBs[0], userTimezoneId) !== getDstTime(filteredTBs[filteredTBs.length - 1], userTimezoneId);
  const tzChangeTimeBlocks = getAvailablePeriods(
    convertDateToMoment(convertMomentToDate(selectedDate || moment()), userTimezoneId),
    schedulingDates,
    moment.duration(sessionLength, "minutes").asMilliseconds(),
    moment.duration(timeStep, "minutes").asMilliseconds(),
    userTimezoneId
  );
  tzChangeTimeBlocks.forEach((timeBlock, index) => {
    let timeTitle;
    if (
      moment(timeBlock.end)
        .tz(userTimezoneId)
        .isSameOrBefore(selectedDate?.clone().tz(userTimezoneId).add(1, "d").startOf("d"))
    ) {
      if (isDstChangeDate) {
        let showOffsetInfo = false;
        if (
          timeBlock.start.isDST() !== timeBlock.start.clone().subtract(1, "hour").isDST() ||
          timeBlock.start.isDST() !== timeBlock.start.clone().add(1, "hour").isDST()
        ) {
          showOffsetInfo = true;
        }
        if (showOffsetInfo) {
          timeTitle = getTimeBlockName({ start: timeBlock.start, end: timeBlock.end, index }, userTimezoneId, true);
        }
      }
      timeSlots.push({ startTime: timeBlock.start, endTime: timeBlock.end, timeTitle });
    }
  });
  return timeSlots;
};

const getDstTime = (timeBlock: ITimeBlock, userTimezoneId: string): boolean => {
  return timeBlock.startTime.tz(userTimezoneId).isDST();
};

export const showRequestTimeSlots = (
  scheduleType: SchedulingType,
  activeSection: TimeBlocksSection,
  isRequestSlots: boolean,
  isAvailableSlots: boolean
): boolean => {
  return ((scheduleType === SchedulingType.DEMAND_BASED && activeSection === "demandSlots" && isRequestSlots) || (scheduleType === SchedulingType.DEMAND_BASED && !isAvailableSlots));
};

export interface IReschedulingEnabledArgs {
  status: SessionStatusType | undefined;
  newRescheduled: boolean | undefined;
  sameDayRescheduling: boolean | undefined;
  endDate: number | undefined;
  sessionLength: number | undefined;
  reschedulingEnabled: boolean | undefined;
  simspecialist?: ISimSpecialist | null;
  type: SessionType;
  userRole: IUserRoleExtended | null;
  isSessionCancellable?: boolean;
  completionRateFulfilled: boolean;
  schedulingRateFulfilled: boolean;
  isMissOrCancellationRateFulfilled?: boolean;
}
export const reschedulingBufferTime = 3600000;

export const isReschedulingEnabled = (reschedulingParams: IReschedulingEnabledArgs): boolean => {
  const isSessionMissedOrCancelled = (reschedulingParams.status === (SessionStatusType.CANCELLED ||SessionStatusType.LATE_CANCELLED ||SessionStatusType.LICENSEE_CANCELLED ||SessionStatusType.EARLY_CANCELLED)) || (reschedulingParams.status === SessionStatusType.MISSED);

  if (isCurrentUserLearner(reschedulingParams.userRole)) {
    const isSessionRescheduleableForLearner = checkIsSessionRescheduleableForLearner(reschedulingParams, isSessionMissedOrCancelled);

    if (isSessionRescheduleableForLearner !== undefined) {
      return isSessionRescheduleableForLearner;
    }
  } else if (isCurrentUserPSorOps(reschedulingParams.userRole)) {
    if (!reschedulingParams.isSessionCancellable) {
      return false;
    }
  } else {
    return false;
  }

  const isScenarioExpired = reschedulingParams?.sameDayRescheduling
    ? moment()
      .add(reschedulingParams?.sessionLength, "ms")
      .add(reschedulingBufferTime, "ms")
      .isSameOrAfter(moment(reschedulingParams?.endDate))
    : moment()
      .add(1, "d")
      .startOf("d")
      .add(reschedulingParams?.sessionLength, "ms")
      .add(reschedulingBufferTime, "ms")
      .isSameOrAfter(moment(reschedulingParams?.endDate));
  const isReschedulingEnabledOnProject = !!reschedulingParams?.reschedulingEnabled;
  const isSessionConfirmed = !!reschedulingParams.simspecialist;
  const isSessionOneToOne = reschedulingParams.type === SessionType.ONE_TO_ONE;
  return checkIsReschedulingEnabledConditions(isSessionConfirmed, isScenarioExpired, isReschedulingEnabledOnProject, isSessionOneToOne);
};

function checkIsSessionRescheduleableForLearner(reschedulingParams: IReschedulingEnabledArgs, isSessionMissedOrCancelled: boolean) {
  const { isMissOrCancellationRateFulfilled } = reschedulingParams;

  if (isMissOrCancellationRateFulfilled) {
    return false;
  }

  const isSessionCompleted = (reschedulingParams.status === SessionStatusType.COMPLETED);
  const isSessionRunning = (reschedulingParams.status === SessionStatusType.RUNNING);
  const isSessionCompletedOrRunning = isSessionCompleted || isSessionRunning;

  if (isSessionMissedOrCancelled) {
    const isSessionRescheduled = reschedulingParams?.newRescheduled;
    const isSessionCompletionRateFulfilled = reschedulingParams?.completionRateFulfilled;

    if (isSessionRescheduled || isSessionCompletionRateFulfilled) {
      return false;
    }
  } else if (isSessionCompletedOrRunning) {
    return false;
  }

  return undefined;
}

const checkIsReschedulingEnabledConditions = (isSessionConfirmed: boolean, isScenarioExpired: boolean, isReschedulingEnabledOnProject: boolean, isSessionOneToOne: boolean):boolean => {
  return (isSessionConfirmed && !isScenarioExpired && isReschedulingEnabledOnProject && isSessionOneToOne);
};

export const isSchedulingTypeNotDemandBased = (schedulingType?:SchedulingType) => {
  return schedulingType && schedulingType !== SchedulingType.DEMAND_BASED;
};

export const allDbsTimeSlotsFilled = (timeSlots:ISlot[], projectDemandBasedSlotsCount: number, schedulingType?: SchedulingType): boolean => {
  return (schedulingType === SchedulingType.DEMAND_BASED) && (!timeSlots.filter((slot: ISlot, i: number) => {
    return (
      (i < projectDemandBasedSlotsCount) && !(slot.startTime && slot.endTime)
    );
  }).length);
};

interface IBufferTimeParams {
  schedulingType: SchedulingType;
  userRole: IUserRoleExtended | null;
  companyConfigDisabledTime?: number;
  sameDayRescheduling: boolean;
  demandWindow: number;
  demandWindowType: DemandWindowType;
}

export const getBufferTime = (params: IBufferTimeParams): number => {
  const {schedulingType, userRole, companyConfigDisabledTime, sameDayRescheduling, demandWindow, demandWindowType} = params;
  if (schedulingType === SchedulingType.RESCHEDULING) {
    if (!sameDayRescheduling) {
      return moment.duration(moment().add(1, "d").startOf("d").diff(moment())).asMilliseconds();
    }
    return reschedulingBufferTime;
  } else if (isCurrentUserPSorOps(userRole)) {
    // one minute buffer at least to handle last moment slot selections
    return companyConfigDisabledTime || moment.duration(1, "m").asMilliseconds();
  } else {
    if (demandWindow) {
      let momentTimeType: "d" | "h" | "m";
      switch (demandWindowType) {
        case DemandWindowType.DAYS:
          momentTimeType = "d";
          break;
        case DemandWindowType.HOURS:
          momentTimeType = "h";
          break;
        case DemandWindowType.MINUTES:
          momentTimeType = "m";
          break;
        default:
          momentTimeType = "d";
      }
      // to keep demand window logic consistent as of now which enables time slots starting the other day
      if (momentTimeType === "d") {
        return (
          moment.duration(demandWindow, momentTimeType).asMilliseconds() -
          moment.duration(moment().subtract(1, "s").diff(moment().startOf("d"))).asMilliseconds()
        );
      }
      return moment.duration(demandWindow, momentTimeType).asMilliseconds();
    }
    return demandWindow || reschedulingBufferTime;
  }
};

// returns no of weekends falling between two dates
const getNoOfWeekends = (from: moment.Moment, to: moment.Moment, tz: string): number => {
  let weekendsCount = 0;

  while (from.isSameOrBefore(to, 'd')) {
    if (isWeekEnd(from.valueOf(), tz)) {
      weekendsCount++;
    }

    from.add(1, 'd');
  }

  return weekendsCount;
};

// also adds the weekends that fall after adding previous iteration result to end date 
export const getTotalNoOfWeekends = (from: moment.Moment, to: moment.Moment, tz: string): number => {
  let totalWeekendsCount = 0;

  while (getNoOfWeekends(from.clone(), to.clone(), tz) !== 0) {
    const weekendsCount = getNoOfWeekends(from.clone(), to.clone(), tz);
    totalWeekendsCount += weekendsCount;
    from = to.clone().add(1, 'd');
    to.add(weekendsCount, 'd');
  }

  return totalWeekendsCount;
};

// returns restrictions duration(ms) between two dates
const getRestrictions = (from: moment.Moment, to: moment.Moment, restrictionTimeSlots: ITimeInterval[]) => {
  let restrictionsDuration = 0;

  restrictionTimeSlots.forEach((restriction: ITimeInterval) => {
    if (from.valueOf() === to.valueOf()) {
      if (restriction.endDate <= to.valueOf()) {
        restrictionsDuration += restriction.endDate - restriction.startDate;
      } else if (getRestrictionStartEndDateCheck(restriction.startDate, restriction.endDate, to.valueOf())) {
        restrictionsDuration += to.valueOf() - restriction.startDate;
      }
    } else if (restriction.startDate <= from.valueOf()) {
      if (restriction.endDate > from.valueOf()) {
        restrictionsDuration += restriction.endDate - from.valueOf();
      }
    } else if (restriction.startDate < to.valueOf()) {
      if (restriction.endDate < to.valueOf()) {
        restrictionsDuration += restriction.endDate - restriction.startDate;
      } else {
        restrictionsDuration += to.valueOf() - restriction.startDate;
      }
    }
  });

  return restrictionsDuration;
};

const getRestrictionStartEndDateCheck = (startDate: number, endDate: number, toVal: number) => {
  return startDate < toVal && endDate > toVal;
};

export const getScenarioEndDateVal = (showAvailabilityEndDate: moment.Moment | null, scenarioEndDate: moment.Moment) => {
  if (showAvailabilityEndDate && showAvailabilityEndDate.valueOf() < scenarioEndDate.valueOf()) {
    return showAvailabilityEndDate;
  } else {
    return scenarioEndDate;
  }
};

const isEntryUnique = (entries: ITimeInterval[], newEntry: ITimeInterval) => {

  return entries
    .every((entry) =>
      !(newEntry.startDate >= entry.startDate && newEntry.endDate <= entry.endDate)
    );
};

const filterWeekendsRestriction = (restrictions: ITimeInterval[], tzId: string) => {
  const splittedRestrictions: ITimeInterval[] = [];

  // splitting restrictions daywise based on user timezone
  restrictions.forEach((restriction: ITimeInterval) => {
    let startTime = restriction.startDate;
    const endTime = restriction.endDate;

    while (startTime < endTime) {
      let splittedRestriction: ITimeInterval;

      if (moment(startTime).tz(tzId).endOf('d').valueOf() < endTime - 1) {
        splittedRestriction = {
          startDate: startTime,
          endDate: moment(startTime).tz(tzId).endOf('d').valueOf() + 1,
        };

        startTime = moment(startTime).add(1, 'd').startOf('d').valueOf();
      } else {
        splittedRestriction = {
          startDate: startTime,
          endDate: endTime,
        };

        startTime = endTime;
      }

      // removing restrictions that are either identical or fall on weekends
      if (
        !isWeekEnd(splittedRestriction.startDate, tzId) &&
        (splittedRestrictions.length === 0 || isEntryUnique(splittedRestrictions, splittedRestriction))
      ) {
        splittedRestrictions.push(splittedRestriction);
      }

    }
  });

  const filteredRestrictions: ITimeInterval[] = [];

  splittedRestrictions.forEach((restriction) => {
    const filteredRestrictionsLastIndex = filteredRestrictions.length - 1;

    // merging two overlapping restrictions
    if (
      filteredRestrictionsLastIndex !== -1
      && restriction.startDate < filteredRestrictions[filteredRestrictionsLastIndex].endDate) {
      filteredRestrictions[filteredRestrictionsLastIndex].endDate = restriction.endDate;
    } else {
      filteredRestrictions.push(restriction);
    }
  });

  return filteredRestrictions;
};


export const getShowAvailabilityEndDateExcludingRestrictions = (date: moment.Moment, restrictionTimeSlots: ITimeInterval[], tz: string) => {
  const endDate = date.clone();
  let prevEndDate = date.clone();

  restrictionTimeSlots.sort((rA, rB) => rA.startDate - rB.startDate);
  const filteredRestrictions = filterWeekendsRestriction(restrictionTimeSlots, tz);

  while (getRestrictions(prevEndDate, endDate, filteredRestrictions) !== 0) {
    const restrictionsDuration = getRestrictions(prevEndDate, endDate, filteredRestrictions);
    const daysToSkip = getTotalNoOfWeekends(endDate.clone(), endDate.clone().add(restrictionsDuration, 'ms'), tz);
    prevEndDate = endDate.clone();
    endDate.add(restrictionsDuration, 'ms').add(daysToSkip, 'd');
  }

  return endDate;
};

export function showSelectionMessage(isCurrentUserL: boolean, areSlotsAvailable: boolean) {
  return isCurrentUserL && areSlotsAvailable;
}

export function getName(name: string | undefined) {
  return name || '';
}