import { LearnerCompletionType, MissedLateActivityType } from "src/app/data/projects/interfaces/IScenario";

export const getLearnerCompletionFilterValue = (learnerCompletion: LearnerCompletionType[] | null | undefined) => {
  if (Array.isArray(learnerCompletion) && learnerCompletion.length) {
    return learnerCompletion;
  } 
  return null;
};

export const getMissedLateActivityFilterValue = (missedLateActivity: MissedLateActivityType[] | null | undefined) => {
  if (Array.isArray(missedLateActivity) && missedLateActivity.length) {
    return missedLateActivity;
  }
  return null;
};