enum Permission {
  // layouts
  CLIENTS_LAYOUT = 'clients_layout',
  MURSION_LAYOUT = 'mursion_layout',
  IT_MANAGER_LAYOUT = 'it_manager_layout',
  SUPPORT_ADMIN_LAYOUT = 'support_admin_layout',

  // access
  COMPANY_USERS_READ = 'company_users_read',
  COMPANY_USERS_WRITE = 'company_users_write',

  CLIENTS_INFO_READ = 'clients_info_read',
  CLIENTS_INFO_WRITE = 'clients_info_write',
  CLIENT_USERS_READ = 'client_users_read',
  CLIENT_USERS_WRITE = 'client_users_write',
  CLIENTS_LEARNERS_INFO_WRITE = 'clients_learners_info_write',
  CLIENT_USERS_IMPORT = 'client_users_import',

  GLOBAL_USERS_READ = 'global_users_read',
  GLOBAL_USERS_WRITE = 'global_users_write',

  EXTERNAL_CLIENT_USERS_IMPORT = 'external_client_users_import',

  PROFILE_WRITE = 'profile_write',

  COMPANIES_INFO_READ = 'companies_info_read',
  COMPANIES_INFO_WRITE = 'companies_info_write',
  PROVIDER_INFO_READ = 'provider_info_read',
  PROVIDER_INFO_WRITE = 'provider_info_write',

  PROJECTS_INFO_READ = 'projects_info_read',
  PROJECTS_CREATE = 'projects_create',
  PROJECTS_EDIT = 'projects_edit',
  PROJECTS_DELETE = 'projects_delete',
  PROJECTS_MANAGERS_EDIT = 'projects_managers_edit',

  SCENARIOS_INFO_READ = 'scenarios_info_read',
  SCENARIOS_INFO_WRITE = 'scenarios_info_write',
  SCENARIOS_TEAM_WRITE = 'scenarios_team_write',

  TEAMS_INFO_READ = 'teams_info_read',
  TEAMS_INFO_WRITE = 'teams_info_write',
  TEAMS_LEARNERS_INFO_WRITE = 'teams_learners_info_write',

  COMPANY_EVENTS_INFO_READ = 'company_events_info_read',
  COMPANY_EVENTS_INFO_WRITE = 'company_events_info_write',

  SESSIONS_INFO_READ = 'sessions_info_read',
  SESSIONS_CANCELLED_READ = 'sessions_cancelled_read',
  SESSIONS_CREATE = 'sessions_create',
  SESSIONS_ONE_TO_ONE_CREATE = 'sessions_one_to_one_create',
  SESSIONS_EDIT = 'sessions_edit',
  SESSIONS_SS_EDIT = 'sessions_ss_edit',
  SESSIONS_SELF_CREATED_EDIT = 'sessions_self_created_edit',
  SESSIONS_DELETE = 'sessions_delete',
  SESSIONS_SELF_CREATED_DELETE = 'sessions_self_created_delete',
  SESSIONS_REVIEW = 'sessions_review',
  SESSIONS_HISTORY_READ = 'session_history_read',
  SESSIONS_SMART_METRICS_READ = 'session_smart_metrics_read',
  SESSION_REPORT_READ = 'session_report_read',
  SESSION_EMERGENCY_CREATE = 'session_emergency_create',
  SESSIONS_RECORDING_OPTIONS_READ = 'sessions_recording_options_read',
  SESSIONS_RECORDING_OPTIONS_WRITE = 'sessions_recording_options_write',

  TRAINING_SESSIONS_READ = 'training_sessions_read',
  TRAINING_SESSIONS_CREATE = 'training_sessions_create',
  TRAINING_SESSIONS_EDIT = 'training_sessions_edit',

  SS_SESSION_NOTES_READ = 'ss_session_notes_read',
  SS_SESSION_NOTES_EDIT = 'ss_session_notes_edit',

  SS_AVAILABILITIES_READ = 'ss_availabilities_read',
  SS_AVAILABILITIES_CREATE = 'ss_availabilities_create',
  SS_AVAILABILITIES_SELF_CREATED_EDIT = 'ss_availabilities_self_created_edit',
  SS_AVAILABILITIES_SELF_CREATED_DELETE = 'ss_availabilities_self_created_delete',

  SCENARIO_AVAILABILITY_READ = 'scenario_availability_read',

  SWAP_READ = 'swap_read',
  SWAP = 'swap',

  CERTIFICATES_INFO_READ = 'certificates_info_read',
  CERTIFICATES_CREATE = 'certificates_create',
  CERTIFICATES_MY_PROJECTS_CREATE = 'certificates_my_projects_create',
  CERTIFICATES_INFO_WRITE = 'certificates_info_write',

  INDUSTRIES_INFO_READ = 'industries_info_read',
  INDUSTRIES_INFO_WRITE = 'industries_info_write',

  DESKTOP_APP_DOWNLOAD = 'desktop_app_download',

  EXPORT_SESSION_REPORT = 'export_session_report',
  READ_SESSION_FLOW = 'session_flow_data_read',
  SESSIONS_ANALYTICS_START = 'sessions_analytics_start',

  SSO_SCHEMAS_CREATE = 'sso_schemas_create',
  SSO_PUBLIC_SCHEMAS_CREATE = 'sso_public_schemas_create',
  SSO_PRIVATE_SCHEMAS_CREATE = 'sso_private_schemas_create',
  SSO_SCHEMAS_DELETE = 'sso_schemas_delete',
  SSO_SCHEMAS_READ = 'sso_schemas_read',
  SSO_SCHEMAS_UPDATE = 'sso_schemas_update',
  
  ART_DESIGNER_READ = 'art_design_read', 
  ART_DESIGNER_WRITE= 'art_design_write', 
}

export default Permission;
