import { TLocaleId } from 'src/i18n';

export enum EventType {
  AVAILABILITY = 'availability',
  COMPANY_EVENT = 'companyEvent',
  NEW_EVENT = 'newEvent',
  REQUEST = 'request',
  RESTRICTION = 'restriction',
  SCHEDULED_SESSION = 'session',
  TRAINING = 'training',
  PRELOADING = 'PRELOADING',
  SUMMARY_SESSION = 'SUMMARY_SESSION',
  SUMMARY_TRAINING = 'SUMMARY_TRAINING',
  SUMMARY_COMPANY_EVENT = 'SUMMARY_COMPANY_EVENT',
  SUMMARY_AVAILABILITY = 'SUMMARY_AVAILABILITY',
}

export enum CompanyEventType {
  QA = 'QA',
  BREAK = 'BREAK',
  PREP = 'PREP',
}

export enum CreateEventType {
  AVAILABILITY,
  QA,
  TRAINING,
  EMERGENCY_SESSION,
  SESSION,
  BREAK_PREP,
  PRACTICE_SESSION,
}

export const CREATE_EVENT_LABEL: { [key in CreateEventType]: TLocaleId } = {
  [CreateEventType.AVAILABILITY]: 'MursionPortal.Availability',
  [CreateEventType.QA]: 'Calendar.Button.Schedule.QualityAssurance',
  [CreateEventType.TRAINING]: 'MursionPortal.Training',
  [CreateEventType.EMERGENCY_SESSION]: 'Calendar.Button.Schedule.EmergencySession',
  [CreateEventType.SESSION]: 'Calendar.Button.Schedule.Session',
  [CreateEventType.BREAK_PREP]: 'Calendar.Button.Schedule.BreakPrep',
  [CreateEventType.PRACTICE_SESSION]: 'MursionPortal.Calendar.Button.Practice.Session',
};

export const getCompanyEventTypes = (companyEvent: string):TLocaleId => {
  switch (companyEvent) {
    case CompanyEventType.QA:
      return 'Calendar.Text.QA';
    case CompanyEventType.BREAK:
      return 'Calendar.Label.Break';
    case CompanyEventType.PREP:
      return 'Calendar.Label.Prep';
    default :
      return 'Calendar.EventType.CompanyEvent';
  }
};