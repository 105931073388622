import _has from 'lodash/has';
import RoleID from 'src/app/data/common/interfaces/RoleID';
import { CLIENTS_ROLES, GLOBAL_ROLES, LEARNER_REPORT_ROLES, MURSION_ROLES, ROLES} from 'src/app/data/common/roles';
import IUserRoleExtended from 'src/app/data/profile/interfaces/IUserRoleExtended';

function isCurrentUserLearner(userRole: IUserRoleExtended | null): boolean {
  return !!userRole && userRole.id === RoleID.LEARNER;
}

function isCurrentUserNotLearner(userRole: IUserRoleExtended | null): boolean {
  return !!userRole && userRole.id !== RoleID.LEARNER;
}

function isCurrentUserBorForL(userRole: IUserRoleExtended | null): boolean {
  return !!userRole && (
    userRole.id === RoleID.BUYER || userRole.id === RoleID.FACILITATOR || userRole.id === RoleID.LEARNER
  );
}

function isCurrentUserPSorSimOrOps(userRole: IUserRoleExtended | null) {
  return !!userRole && (
    userRole.id === RoleID.PROF_SERVICE || userRole.id === RoleID.SIM_SPECIALIST || userRole.id === RoleID.OPERATIONS
  );
}

function isCurrentUserPS(userRole: IUserRoleExtended | null): boolean {
  return !!userRole && userRole.id === RoleID.PROF_SERVICE;
}

function isCurrentUserSimDesigner(userRole: IUserRoleExtended | null): boolean {
  return !!userRole && userRole.id === RoleID.SIM_DESIGNER;
}

function isCurrentUserPSorOps(userRole: IUserRoleExtended | null): boolean {
  return !!userRole && (userRole.id === RoleID.PROF_SERVICE || userRole.id === RoleID.OPERATIONS);
}

function isCurrentUserOps(userRole: IUserRoleExtended | null): boolean {
  return !!userRole && userRole.id === RoleID.OPERATIONS;
}

function isCurrentUserClientUser(userRole: IUserRoleExtended | null): boolean {
  if (!userRole) {
    return false;
  }

  return _has(CLIENTS_ROLES, userRole.id);
}

function isCurrentUserSimSpec(userRole: IUserRoleExtended | null): boolean {
  if (!userRole) {
    return false;
  }

  return userRole.id === RoleID.SIM_SPECIALIST;
}

function isCurrentUserPSorAMorOps(userRole: IUserRoleExtended | null) {
  return !!userRole && (
    userRole.id === RoleID.PROF_SERVICE || userRole.id === RoleID.ACC_MANAGER || userRole.id === RoleID.OPERATIONS
  );
}

function isCurrentUserPSorAMorSimSpec(userRole: IUserRoleExtended | null) {
  return !!userRole && (
    userRole.id === RoleID.PROF_SERVICE || userRole.id === RoleID.ACC_MANAGER || userRole.id === RoleID.SIM_SPECIALIST
  );
}

function isCurrentUserBorF(userRole: IUserRoleExtended | null) {
  return !!userRole && (
    userRole.id === RoleID.BUYER || userRole.id === RoleID.FACILITATOR
  );
}

function isCurrentUserFacilitator(userRole: IUserRoleExtended | null) {
  return !!userRole && userRole.id === RoleID.FACILITATOR;
}

function isCurrentUserBuyer(userRole: IUserRoleExtended | null) {
  return !!userRole && (
    userRole.id === RoleID.BUYER
  );
}

function isCurrentUserSupportAdmin(userRole: IUserRoleExtended | null) {
  return !!userRole && (
    userRole.id === RoleID.SUPPORT_ADMIN);
}

function isCurrentUserPSorAOorOps(userRole: IUserRoleExtended | null) {
  return !!userRole && (
    userRole.id === RoleID.PROF_SERVICE || userRole.id === RoleID.BUYER || userRole.id === RoleID.OPERATIONS
  );
}

function isCurrentUserBusinessAdmin(userRole: IUserRoleExtended | null) {
  return !!userRole && (
    userRole.id === RoleID.BUSINESS_ADMIN);
}

function isCurrentUserClientsOrOpsOrPSOrSimSpec(userRole: IUserRoleExtended | null) {
  return !!userRole && (_has(LEARNER_REPORT_ROLES, userRole.id));
}

function userHasRole(userRole: IUserRoleExtended | null | undefined, roles: RoleID[]) {
  return userRole?.id && roles.includes(userRole.id);
}

function isCurrentUserPSorAOorForAMorO(userRole: IUserRoleExtended | null) {
  return !!userRole && (
    userRole.id === RoleID.PROF_SERVICE || userRole.id === RoleID.BUYER || userRole.id === RoleID.FACILITATOR || userRole.id === RoleID.ACC_MANAGER || userRole.id === RoleID.OPERATIONS
  );
}

function isUserAOAndF(roles: IUserRoleExtended[] | undefined) {
  return !!roles && (roles.some((role: IUserRoleExtended) => role.id === RoleID.BUYER) && roles.some((role: IUserRoleExtended) => role.id === RoleID.FACILITATOR));
}

function isUserFAndL(roles: IUserRoleExtended[] | undefined) {
  return !!roles && (roles.some((role: IUserRoleExtended) => role.id === RoleID.FACILITATOR) && roles.some((role: IUserRoleExtended) => role.id === RoleID.LEARNER));
}

function checkUserRoleByRoleID(roles: IUserRoleExtended[], roleId: RoleID) {
  return !!roles && (roles.some((role: IUserRoleExtended) => role.id === roleId));
}

function isUserPSorOPSorSD(userRole: IUserRoleExtended | null) {
  return !!userRole && (userRole.id === RoleID.PROF_SERVICE || userRole.id === RoleID.OPERATIONS || userRole.id === RoleID.SIM_DESIGNER);
}

function isUserPSorPMorOPSorSA(userRole: IUserRoleExtended | null) {
  return !!userRole && (userRole.id === RoleID.PROF_SERVICE || userRole.id === RoleID.ACC_MANAGER || userRole.id === RoleID.OPERATIONS || userRole.id === RoleID.SUPPORT_ADMIN);
}
function isAllUserRole(userRole: IUserRoleExtended | null){
  if (!userRole) {
    return false;
  }

  return _has(ROLES.clients, userRole.id) || _has(ROLES.mursion, userRole.id) || _has(ROLES.global, userRole.id);
}

function isCurrentUserPSorAOorOpsorF(userRole: IUserRoleExtended | null) {
  return !!userRole && (
    userRole.id === RoleID.PROF_SERVICE || userRole.id === RoleID.BUYER || userRole.id === RoleID.OPERATIONS || userRole.id === RoleID.FACILITATOR
  );
}

function isCurrentUserPSorPMorOpsorSD(userRole: IUserRoleExtended | null) {
  return !!userRole && (
    userRole.id === RoleID.PROF_SERVICE || userRole.id === RoleID.ACC_MANAGER || userRole.id === RoleID.OPERATIONS || userRole.id === RoleID.SIM_DESIGNER
  );
}

function isCurrentUserPSorAOorF(userRole: IUserRoleExtended | null) {
  return !!userRole && (
    userRole.id === RoleID.PROF_SERVICE || userRole.id === RoleID.BUYER || userRole.id === RoleID.FACILITATOR
  );
}

function isCurrentUserPSorSSorSD(userRole: IUserRoleExtended | null) {
  return !!userRole && (
    userRole.id === RoleID.PROF_SERVICE || userRole.id === RoleID.SIM_SPECIALIST || userRole.id === RoleID.SIM_DESIGNER
  );
}

function isCurrentUserPSOrSimSpecOrBOrF(userRole: IUserRoleExtended | null) {
  return !!userRole && (
    userRole.id === RoleID.PROF_SERVICE || userRole.id === RoleID.SIM_SPECIALIST || userRole.id === RoleID.BUYER || userRole.id === RoleID.FACILITATOR
  );
}

function isCurrentUserPSorSimSpec(userRole: IUserRoleExtended | null) {
  return !!userRole && (
    userRole.id === RoleID.PROF_SERVICE || userRole.id === RoleID.SIM_SPECIALIST
  );
}

function isCurrentUserItManager(userRole: IUserRoleExtended | null): boolean {
  return !!userRole && userRole.id === RoleID.IT_MANAGER;
}

function isCurrentUserPSorPMorOpsorSDorSimS(userRole: IUserRoleExtended | null) {
  return !!userRole && (
    userRole.id === RoleID.PROF_SERVICE || userRole.id === RoleID.ACC_MANAGER || userRole.id === RoleID.OPERATIONS || userRole.id === RoleID.SIM_DESIGNER || userRole.id === RoleID.SIM_SPECIALIST
  );
}

function isCurrentUserProviderOrAdmin(userRoleID: string | undefined) {
  return !!userRoleID && (
    _has(MURSION_ROLES, userRoleID) || _has(GLOBAL_ROLES, userRoleID)
  );
}

export {
  isCurrentUserLearner,
  isCurrentUserClientUser,
  isCurrentUserPSorAMorOps,
  isCurrentUserBuyer,
  isCurrentUserBorF,
  isCurrentUserSimSpec,
  isCurrentUserPSorOps,
  isCurrentUserSupportAdmin,
  isCurrentUserPSorAOorOps,
  isCurrentUserPS,
  isCurrentUserOps,
  isCurrentUserSimDesigner,
  isCurrentUserBusinessAdmin,
  isCurrentUserClientsOrOpsOrPSOrSimSpec,
  userHasRole,
  isCurrentUserPSorSimOrOps,
  isCurrentUserNotLearner,
  isCurrentUserPSorAOorForAMorO,
  isCurrentUserBorForL,
  isCurrentUserFacilitator,
  isUserAOAndF,
  isUserFAndL,
  checkUserRoleByRoleID,
  isCurrentUserPSorAMorSimSpec,
  isUserPSorOPSorSD,
  isAllUserRole,
  isUserPSorPMorOPSorSA,
  isCurrentUserPSorAOorOpsorF,
  isCurrentUserPSorPMorOpsorSD,
  isCurrentUserPSorAOorF,
  isCurrentUserPSorSSorSD,
  isCurrentUserPSOrSimSpecOrBOrF,
  isCurrentUserPSorSimSpec,
  isCurrentUserItManager,
  isCurrentUserPSorPMorOpsorSDorSimS,
  isCurrentUserProviderOrAdmin,
};
