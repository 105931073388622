export const DEFAULT_LOGO_PATH = `${process.env.PUBLIC_URL}/static/media/default_logo.png`;
export const DEFAULT_USER_ICON_PATH = `${process.env.PUBLIC_URL}/static/media/user_icon.svg`;
export const DEFAULT_USER_PROFILE_UPLOAD_ICON_PATH = `${process.env.PUBLIC_URL}/static/media/profileImg.png`;

// todo: load possible industries from the server
export const INDUSTRIES = [
  { id: 'i1', name: 'Corporate' },
  { id: 'i2', name: 'Hospitality' },
  { id: 'i3', name: 'Healthcare' },
  { id: 'i4', name: 'Education' },
];
