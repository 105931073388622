import { ScenarioVersion, SoftwareType, ScenarioSoftwareVersion } from 'src/app/data/projects/interfaces/IProject';

const SCENARIO_VERSION_LABELS = {
  [ScenarioVersion.V2]: 'ML2x',
  [ScenarioVersion.V3]: 'ML3',
  [ScenarioVersion.V3z]: 'ML3Z',
  [ScenarioVersion.V3meet]: 'Mursion Meet',
  [ScenarioVersion.V3MAGIC]: 'Magic',
};
const SCENARIO_BANK_VERSION_LABELS = {
  [ScenarioVersion.V2]: 'ML2x',
  [ScenarioVersion.V3]: 'ML3/Mursion Social',
};

const SCENARIO_SOFTWARE_VERSION_LABELS = {
  [ScenarioSoftwareVersion.DESKTOP_V3]: 'ML3',
  [ScenarioSoftwareVersion.WEB_V3]: 'Magic',
  [ScenarioSoftwareVersion.DESKTOP_WEB_V3]: 'MagicML3',
  [ScenarioSoftwareVersion.V3z]: 'Zoom',
  [ScenarioSoftwareVersion.V3meet]: 'Meet',
};

const getScenarioVersionLabel =
  (scenarioVersion: ScenarioVersion) => SCENARIO_VERSION_LABELS[scenarioVersion];

export const getScenarioBankVersionLabel =
  (scenarioVersion: ScenarioVersion) => SCENARIO_BANK_VERSION_LABELS[scenarioVersion];

export const getSoftwareVersionAndTypeLabel = (type: SoftwareType, scenarioVersion: ScenarioVersion) => {

  switch (true) {
    case (type === SoftwareType.DESKTOP && scenarioVersion === ScenarioVersion.V3):
      return SCENARIO_SOFTWARE_VERSION_LABELS[ScenarioSoftwareVersion.DESKTOP_V3];
    case (type === SoftwareType.WEB && scenarioVersion === ScenarioVersion.V3):
      return SCENARIO_SOFTWARE_VERSION_LABELS[ScenarioSoftwareVersion.WEB_V3];
    case (type === SoftwareType.WEB_DESKTOP && scenarioVersion === ScenarioVersion.V3):
      return SCENARIO_SOFTWARE_VERSION_LABELS[ScenarioSoftwareVersion.DESKTOP_WEB_V3];
    case (scenarioVersion === ScenarioVersion.V3z):
      return SCENARIO_SOFTWARE_VERSION_LABELS[ScenarioSoftwareVersion.V3z];
    case (scenarioVersion === ScenarioVersion.V3meet):
      return SCENARIO_SOFTWARE_VERSION_LABELS[ScenarioSoftwareVersion.V3meet];
    default:
      return '';
  }
};

export default getScenarioVersionLabel;
