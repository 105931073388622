import { IErrorValidation } from 'src/app/data/common/interfaces/IRestError';
import { parseErrorValidation } from 'src/app/data/common/utils/errorUtils';
import { IntlShape } from 'react-intl';
import { isCancellationWindowGreaterThanUpperLimit } from 'src/app/data/projects/utils/isCancellationWindowZero';
import { ShowAvailabilityType } from 'src/app/data/projects/interfaces/IProject';
import { getValueAccordingToType } from 'src/app/data/common/utils/dateUtil';
import { MAX_UPCOMING_AVAILABILITY_IN_HOURS, MAX_UPCOMING_AVAILABILITY_IN_SECONDS } from 'src/app/data/common/constants';
import { ProjectCreateStepField } from 'src/layouts/common/Projects/components/ProjectCard/components/NewProjectForm/components/ProjectCreateStep/ProjectCreateStep';
import SessionType from 'src/app/data/session/interfaces/SessionType';

export const cancellationWindowUpperLimit = 31536000; // seconds in 365 days

type ScenarioSettingValue = number | null | undefined;

interface IReplaceMap {
  [key: string]: string | null;
}

export const getValidationErrorMessage = (fieldName: string, validationErrors?: IErrorValidation[], replaceMap?: IReplaceMap): string | null => {
  if (!validationErrors) {
    return null;
  }

  const validationError = validationErrors.find(error => error.field === fieldName);

  if (!validationError) {
    return null;
  }

  return parseErrorValidation(validationError, replaceMap);
};

export const getValidationErrorByCode = (code: string, validationErrors?: IErrorValidation[]): string | null => {
  if (!validationErrors) {
    return null;
  }

  const validationError = validationErrors.find(error => error.code === code);

  return validationError ? parseErrorValidation(validationError) : null;
};

export const getValidationStateForCancellationWindow = (message:string | null) => {
  return message ? 'error' : undefined;
};

export const getValidationErrorMessageForCancellationWindow = (validationErrors: IErrorValidation[] | undefined, intl: IntlShape, cancellationWindowValue: number | undefined, cancellationWindowInSeconds: number | undefined) => {
  let errorMessage = null;

  if (validationErrors?.length) {
    errorMessage = getValidationErrorMessage('cancellationWindow', validationErrors);
  } else if (cancellationWindowValue === 0) {
    errorMessage = intl.formatMessage({ id: 'MursionPortal.Project.CancelationDeadline.Validation' });
  } else if (isCancellationWindowGreaterThanUpperLimit(cancellationWindowInSeconds)) {
    errorMessage = intl.formatMessage({ id: 'MursionPortal.Project.CancelationDeadline.UpperLimit.Validation' });
  }
  return errorMessage;
};

export const getValidationErrorMessageForShowAvailability = (validationErrors: IErrorValidation[] | undefined, intl: IntlShape, showAvailability: number, showAvailabilityType: ShowAvailabilityType) => {
  let errorMessage = null;
  const value = getValueAccordingToType(showAvailability, showAvailabilityType);

  if (validationErrors?.length) {
    errorMessage = getValidationErrorMessage('showAvailability', validationErrors);
  } else if (value === 0 && showAvailabilityType !== ShowAvailabilityType.ALL) {
    errorMessage = intl.formatMessage({ id: 'Projects.InputValidation.GreaterThanZero' });
  }

  return errorMessage;
};

export function getValidationError(validationErrors: IErrorValidation[] | undefined, fieldName: string, value: number, intl: IntlShape) {
  let errorMessage = null;

  if (validationErrors?.length) {
    errorMessage = getValidationErrorMessage(fieldName, validationErrors);
  } else if (fieldName === ProjectCreateStepField.UPCOMING_AVAILABILITY) {
    if (value > MAX_UPCOMING_AVAILABILITY_IN_SECONDS) {
      errorMessage = intl.formatMessage(
        { id: 'MursionPortal.ProjectCreateStep.UpperLimitValidation.Hours' },
        { code: MAX_UPCOMING_AVAILABILITY_IN_HOURS }
      );
    }
  }

  return errorMessage;
}

export function getValidationErrorForSessionMissedTime(validationErrors: IErrorValidation[] | undefined, fieldName: string) {
  let errorMessage = null;

  if (validationErrors?.length) {
    errorMessage = getValidationErrorMessage(fieldName, validationErrors);
  }

  return errorMessage;
}

export function getScenarioSettingCheck(higherLevel: ScenarioSettingValue, lowerLevel: ScenarioSettingValue) {
  return higherLevel && lowerLevel && lowerLevel > higherLevel;
}

export function getScenarioSettingValueCheck(projectLevelValue: ScenarioSettingValue, clientLevelValue: ScenarioSettingValue) {
  return projectLevelValue ? projectLevelValue : clientLevelValue;
}

export function schedulingLockoutNullCheck(missOrCancelationRate: ScenarioSettingValue, schedulingLockoutTime: ScenarioSettingValue) {
  return missOrCancelationRate && (missOrCancelationRate !== null || !isNaN(missOrCancelationRate as number)) && (schedulingLockoutTime === null || isNaN(schedulingLockoutTime as number));
}

export function missOrCancelRateAndSchedulingLockoutNullCheck(missOrCancelationRate: ScenarioSettingValue, schedulingLockoutTime: ScenarioSettingValue) {
  return (missOrCancelationRate === null || isNaN(missOrCancelationRate as number)) && (schedulingLockoutTime === null || isNaN(schedulingLockoutTime as number));
}

export function getScheduleButtonLearnerVisibility(
  isLearner: boolean,
  schedulingRateFulfilled: boolean | undefined,
  completionRateFulfilled: boolean | undefined,
  deliveryMode: SessionType | undefined
) {
  return isLearner && schedulingRateFulfilled && completionRateFulfilled && deliveryMode === SessionType.ONE_TO_ONE;
}

export function isSequencingAllowed(showScheduleButton: boolean, inviteToScheduleAllowed: boolean | undefined) {
  return showScheduleButton && inviteToScheduleAllowed;
}

export function isSchedulingAndCompletionRateCheckFulfilled(
  schedulingRateFulfilled: boolean | undefined,
  schedulingRate: number | null | undefined,
  completionRate: number | null | undefined
) {
  return (
    schedulingRateFulfilled &&
    schedulingRate &&
    completionRate &&
    (schedulingRate < completionRate || completionRate === null)
  );
}