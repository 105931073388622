import emailValidator from 'email-validator';
import { isInjected } from 'src/app/data/common/utils/csvInjectionProtector';
import { intl } from 'src/i18n/createIntl';

export default function validateEmail(email: string, isUserImport?: boolean): string | null {
  if (isUserImport) {
    return intl().formatMessage({ id: 'MursionPortal.ErrorMessage.InvalidEmailFormat' });
  }
  if (!email) {
    return intl().formatMessage({ id: 'MursionPortal.ErrorMessage.EmailCanNotBeEmpty' });
  }
  if (!emailValidator.validate(email) || isInjected(email)) {
    return intl().formatMessage({ id: 'MursionPortal.ErrorMessage.EnterValidEmailAddress' });
  }

  return null;
}
