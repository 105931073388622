import { IProjectBase, ProjectStatus } from 'src/app/data/projects/interfaces/IProject';

export default function generateNewProjectBaseData(): IProjectBase {
  return {
    archived: false,
    endDate: null,
    id: '',
    name: '',
    startDate: null,
    status: ProjectStatus.ACTIVE,
    tags: [],
    version: 0,
  };
}
