enum SessionType {
  NONE = '', // only for preparing data
  ONE_TO_ONE = 'ONE_TO_ONE',
  ONE_TO_MANY_LOCAL = 'ONE_TO_MANY_LOCAL',
  ONE_TO_MANY_REMOTE = 'ONE_TO_MANY_REMOTE',
  GROUP = 'GROUP',
}

export enum ContractSessionType {
  ONE_TO_ONE = 'ONE_TO_ONE',
  WORKSHOPS = 'WORKSHOPS'
}

export default SessionType;
