import IUserRole from './interfaces/IUserRole';
import RoleID from './interfaces/RoleID';

export type RolesMap = { [propertyKey in RoleID]?: IUserRole };

export const CLIENTS_ROLES: RolesMap = {
  [RoleID.BUYER]: {
    id: RoleID.BUYER,
    name: 'Account Owner',
    shortName: 'Acc Owner',
  },
  [RoleID.FACILITATOR]: {
    id: RoleID.FACILITATOR,
    name: 'Facilitator',
    shortName: 'Facilitator',
  },
  [RoleID.LEARNER]: {
    id: RoleID.LEARNER,
    name: 'Learner',
    shortName: 'Learner',
  },
};

export const LEARNER_REPORT_ROLES: RolesMap = {
  ...CLIENTS_ROLES,
  [RoleID.OPERATIONS]: {
    id: RoleID.OPERATIONS,
    name: 'Operations',
    shortName: 'Operations',
  },
  [RoleID.PROF_SERVICE]: {
    id: RoleID.PROF_SERVICE,
    name: 'Professional Service',
    shortName: 'Prof. Services',
  },
  [RoleID.SIM_SPECIALIST]: {
    id: RoleID.SIM_SPECIALIST,
    name: 'Simulation Specialist',
    shortName: 'Sim Specialist',
  },
};

export const MURSION_ROLES: RolesMap = {
  [RoleID.OPERATIONS]: {
    id: RoleID.OPERATIONS,
    name: 'Operations',
    shortName: 'Ops',
  },
  [RoleID.PROF_SERVICE]: {
    id: RoleID.PROF_SERVICE,
    name: 'Professional Service',
    shortName: 'Prof. Services',
  },
  [RoleID.ACC_MANAGER]: {
    id: RoleID.ACC_MANAGER,
    name: 'Project Manager',
    shortName: 'Project Manager',
  },
  [RoleID.SIM_SPECIALIST]: {
    id: RoleID.SIM_SPECIALIST,
    name: 'Simulation Specialist',
    shortName: 'Sim Specialist',
  },
  [RoleID.SIM_DESIGNER]: {
    id: RoleID.SIM_DESIGNER,
    name: 'Simulation Designer',
    shortName: 'Sim Designer',
  },
};

export const GLOBAL_ROLES: RolesMap = {
  [RoleID.IT_MANAGER]: {
    id: RoleID.IT_MANAGER,
    name: 'IT MANAGER',
    shortName: 'IT Manager',
  },
  [RoleID.BUSINESS_ADMIN]: {
    id: RoleID.BUSINESS_ADMIN,
    name: 'Business Admin',
    shortName: 'Business Admin',
  },
  [RoleID.SUPPORT_ADMIN]: {
    id: RoleID.SUPPORT_ADMIN,
    name: 'Support Admin',
    shortName: 'Support Admin',
  },
  [RoleID.DEV_ADMIN]: {
    id: RoleID.DEV_ADMIN,
    name: 'Dev Admin',
    shortName: 'Dev Admin',
  },
};

export const ROLES = {
  clients: CLIENTS_ROLES,
  mursion: MURSION_ROLES,
  global: GLOBAL_ROLES,
};

export const getRoleById = (roleId: RoleID) => CLIENTS_ROLES[roleId] || MURSION_ROLES[roleId] || GLOBAL_ROLES[roleId];

export default ROLES;
