// @formatter:off
enum RoleID {
  ACC_MANAGER    = 'accmanager'    , // Project Manager
  BUYER          = 'buyer'         , // Account Owner
  FACILITATOR    = 'facilitator'   , // Facilitator
  IT_MANAGER     = 'itmanager'     , // IT Manager
  BUSINESS_ADMIN = 'businessadmin' , // Business Admin
  SUPPORT_ADMIN  = 'supportadmin'  , // Support Admin
  DEV_ADMIN      = 'devadmin'      , // Dev Admin
  LEARNER        = 'learner'       , // Learner
  PROF_SERVICE   = 'profservice'   , // Professional Service
  SIM_SPECIALIST = 'simspecialist' , // Simulation Specialist
  OPERATIONS     = 'operations'    ,
  SIM_DESIGNER   = 'simdesigner'   ,
}
// @formatter:on

export default RoleID;