const hashCode = (str: string) => { // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash); // tslint:disable-line
  }
  return hash;
};

// function to get contrast color
const getContrastColor = (color: string) => {
  const rgb = hexToRgb(color);
  const yiq = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
  return yiq >= 128 ? "#000" : "#fff";
};

const hexToRgb = (hex: string) => {
  const bigint = parseInt(hex.replace("#", ""), 16);
  return {
    r: (bigint >> 16) & 255, // tslint:disable-line
    g: (bigint >> 8) & 255, // tslint:disable-line
    b: bigint & 255, // tslint:disable-line
  };
};

// check contrast betwwen two colors is ok
const isColorContrast = (color1: string) => {
  const rgb = hexToRgb(color1);
  const yiq = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
  const rgb2 = hexToRgb(color1);
  const yiq2 = (rgb2.r * 299 + rgb2.g * 587 + rgb2.b * 114) / 1000;
  return yiq >= 128 && yiq2 >= 128;
};

const getColorFromId = (id: string) => {
  const c = (hashCode(id) & 0x00ffffff).toString(16).toUpperCase(); // tslint:disable-line
  const colorId = "#" + "00000".substring(0, 6 - c.length) + c;
  return isColorContrast(colorId) ? getContrastColor(colorId) : colorId;
};

export default getColorFromId;
