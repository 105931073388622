import moment from 'moment-timezone';
import { unitOfTime } from 'moment';

import momentDurationFormatSetup from 'moment-duration-format';
import { IntlShape } from 'react-intl';
import IProject, { DemandWindowType, ShowAvailabilityType } from 'src/app/data/projects/interfaces/IProject';
import { ISessionForList, ISessionTimeSlot } from 'src/app/data/session/interfaces/ISession';
import { ISessionActionInfo } from 'src/layouts/common/Sessions/SessionsTable/SessionsTable';

momentDurationFormatSetup(moment);

export const DATE_FORMAT = 'MM/DD/YYYY';
export const TIME_FORMAT = 'hh:mma';
type TimezoneType = string | null | undefined;

export enum DeliveryHoursTimeType {
  START_TIME = 'startTime',
  END_TIME = 'endTime',
}

export enum DeliveryHoursType {
  STANDARD = 'STANDARD',
  GLOBAL = 'GLOBAL'
}

export enum DeliveryWeekDaysField {
  MONDAY = 'deliveryOnMonday',
  TUESDAY = 'deliveryOnTuesday',
  WEDNESDAY = 'deliveryOnWednesday',
  THURSDAY = 'deliveryOnThursday',
  FRIDAY = 'deliveryOnFriday',
  SATURDAY = 'deliveryOnSaturday',
  SUNDAY = 'deliveryOnSunday',
}

export const convertTimestampToMoment = (timestamp?: number): moment.Moment | null => {
  if (!timestamp) {
    return null;
  }

  return moment.unix(timestamp);
};

export const momentDateToTimestamp = (momentDate: moment.Moment | null): number => {
  if (!momentDate) {
    return 0;
  }
  return momentDate.unix();
};

export const convertTimestampMillisToMoment = (timestamp: number): moment.Moment | null => {
  if (!timestamp) {
    return null;
  }

  return moment(timestamp).utc();
};

export const momentDateToTimestampMillis = (momentDate: moment.Moment | null): number => {
  if (!momentDate) {
    return 0;
  }

  return momentDate.valueOf();
};

export const copyDate = (srcDate: moment.Moment, destDate: moment.Moment): void => {
  if (!srcDate || !destDate) {
    return;
  }

  destDate.set('year', srcDate.get('year'));
  destDate.set('month', srcDate.get('month'));
  destDate.set('date', srcDate.get('date'));
};

export const round = (timestamp: number, duration: number, method: 'ceil' | 'floor', tzId: string): moment.Moment => {
  return moment.tz(Math[method](timestamp / duration) * duration, tzId);
};

export const roundMoment = (date: moment.Moment, duration: unitOfTime.DurationConstructor, method: 'ceil' | 'floor' = 'ceil') => {

  if (method === 'ceil') {
    return date.second() || date.millisecond()
      ? date.clone().add(1, duration).startOf(duration)
      : date.startOf(duration);
  }

  return date.startOf(duration);
};

export const secToMinutes = (seconds: number) => {
  return moment.duration(seconds, 's').asMinutes();
};

export const msToMin = (seconds: number) => {
  return moment.duration(seconds, 'ms').asMinutes();
};

export const msToSec = (seconds: number) => {
  return moment.duration(seconds, 'ms').asSeconds();
};

export const secToMs = (seconds: number) => {
  return moment.duration(seconds, 's').asMilliseconds();
};

export const getTimezoneTitle = (timezone: string) => {
  const format = (tzId: string, fmt: string) => {
    return moment.tz(tzId).format(fmt);
  };
  const title = format(timezone, 'z');
  return /[+-]\d+/.test(title) ? `GMT(${format(timezone, 'Z')})` : title;
};

export const getDateAwareTimezoneTitle = (timezoneId: string, timestamp: number) => {
  const date = moment(timestamp);
  const title = date.tz(timezoneId).format('z');

  return /[+-]\d+/.test(title) ? `GMT ${date.tz(timezoneId).format('Z')}` : title;
};

export const getDateAwareDuration = (startDate: moment.Moment, endDate: moment.Moment): string => {
  let label = '';

  const yearCounter = endDate.diff(startDate, 'year');
  endDate.subtract(yearCounter, 'year');
  if (!!yearCounter) {
    label += moment.duration(yearCounter, 'year').format({ template: 'y __ ', trim: 'all' }) + ' ';
  }

  const monthCounter = endDate.diff(startDate, 'month');
  endDate.subtract(monthCounter, 'month');
  if (monthCounter) {
    label += moment.duration(monthCounter, 'month').format({ template: 'M __', trim: 'all' }) + ' ';
  }

  const daysCounter = endDate.diff(startDate, 'days');
  label += moment.duration(daysCounter, 'days').format({ template: 'w __ d __', trim: 'all' });

  return !!label ? label.trim() : '0 days';
};

export const convertDateToMoment = (date: Date, timezoneId: string) =>
  moment.tz(moment(date).format('YYYY-MM-DD'), timezoneId);

export const convertMomentToDate = (date: moment.Moment) =>
  moment(date.format('YYYY-MM-DD')).toDate();

export const getDateFormat = () => {
  return moment.localeData().longDateFormat('L');
};

export const getTimeFormat = () => {
  return  moment.localeData().longDateFormat('LT');
};

export const getDayFormat = () => {
  return moment.localeData().longDateFormat('LL');
};

export const momentformatYearlessL = (): string => {
  const formatL = moment.localeData().longDateFormat('L');
  return formatL.replace(/Y/g, '').replace(/^\W|\W$|\W\W/, '');
};

export const getDateTime = (intl: IntlShape, timeZoneId : string, timestamp?: number) => {
  return timestamp
    ? {
      date: intl.formatDate(timestamp, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: timeZoneId,
      }),
      time: `${intl.formatTime(timestamp, {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timeZoneId,
      })} ${getDateAwareTimezoneTitle(timeZoneId, timestamp)}`,
    }
    : null;
};

export const minutesToMillisConverter = (minutes: number): number => {
  return moment.duration(minutes, 'minutes').asMilliseconds();
};
  
export const millisToMinutesConverter = (millis: number): number => {
  return moment.duration(millis, 'ms').asMinutes();
};

export const getFormatedTimeWithZone = (date: number, timezoneId: string, currentMomentValue: number) => {
  const newDate = moment(date).tz(timezoneId); 
  return `${newDate.format("LT")} ${getDateAwareTimezoneTitle(timezoneId, currentMomentValue)}`;
};

export const getFormatedTimeDate = (date: number, userTimeZoneId: string, currentMoment: number,seprator: string = ', ') => {
  const newDate = moment(date).tz(userTimeZoneId); 
  return `${newDate.format("dddd")} - ${newDate.format("LL")}${seprator}${newDate.format("LT")} ${getDateAwareTimezoneTitle(userTimeZoneId, currentMoment)}`;
};

export const getDemandBasedSchedulingTime = (demandBasedSchedulingEndTime: TimezoneType, state: 'startTime' | 'endTime', momentDate: moment.Moment) => {
  const isEndTimeBeforeStartTime = moment(demandBasedSchedulingEndTime, 'HH:mm').isSameOrBefore(momentDate);
  const project: IProject = {} as IProject;
  if (state === 'startTime') {
    project.demandBasedSchedulingStartTime = momentDate.format('HH:mm');
    if (isEndTimeBeforeStartTime) {
      project.demandBasedSchedulingEndTime = moment(momentDate).add('30', 'minutes').format('HH:mm');
    }
  } else if (state === 'endTime') {
    project.demandBasedSchedulingEndTime = momentDate.format('HH:mm');
  }
  return project;
};

export const getDeliveryHours = (deliveryHoursType: DeliveryHoursTimeType, momentDate: moment.Moment) => {
  const deliveryHours = {} as { startTime: string, endTime: string };

  if (deliveryHoursType === DeliveryHoursTimeType.START_TIME) {
    deliveryHours.startTime = momentDate.format('HH:mm');
  } else {
    deliveryHours.endTime = momentDate.format('HH:mm');
  }

  return deliveryHours;
};

export function getSessionMissedTime(sessionMissedTime: number | null) {
  return sessionMissedTime ? moment.duration(sessionMissedTime, 'minutes').asSeconds() : null;
}

export const daysHoursMinutesToSecondsConverter = (cancellationWindow: number | undefined, cancellationWindowType: string): number => {
  let cancellationWindowVal:number;
  if (cancellationWindowType === DemandWindowType.HOURS) {
    cancellationWindowVal = moment.duration(cancellationWindow, 'hours').asSeconds();
  } else if (cancellationWindowType === DemandWindowType.MINUTES) {
    cancellationWindowVal = moment.duration(cancellationWindow, 'minutes').asSeconds();
  } else if (cancellationWindowType === DemandWindowType.DAYS) {
    cancellationWindowVal = moment.duration(cancellationWindow, 'days').asSeconds();
  } else {
    cancellationWindowVal = moment.duration(cancellationWindow, 'weeks').asSeconds();
  }
  return cancellationWindowVal;
};

export const getTypeFromSecondsToDaysHoursMinutesConverter = (cancellationWindow: number | undefined) => {
  const cancellationWindowVal:number = moment.duration(cancellationWindow, 'seconds').asHours();
  let cancellationWindowType;

    if (Number.isInteger(cancellationWindowVal)) {
      cancellationWindowType = DemandWindowType.HOURS;
    }else{
      cancellationWindowType = DemandWindowType.MINUTES;
    }
    return cancellationWindowType;
};

export const getCancellationWindowValue = (cancellationWindow: number | undefined, cancellationWindowType: string): number => {
  let cancellationWindowVal:number;
  if(cancellationWindowType === DemandWindowType.HOURS){
    cancellationWindowVal = moment.duration(cancellationWindow, 'seconds').asHours();
  }else if(cancellationWindowType === DemandWindowType.MINUTES){
    cancellationWindowVal = moment.duration(cancellationWindow, 'seconds').asMinutes(); 
  } else{
    cancellationWindowVal = moment.duration(cancellationWindow, 'seconds').asDays();
  }
  return cancellationWindowVal;
};

export function sessionMissedTimeInMinutes(sessionMissedTime: number | null) {
  return sessionMissedTime ? moment.duration(sessionMissedTime, 'seconds').asMinutes() : null;
}

export const getShortDayFormat = (date: string | number | null | '' | undefined, tzId?: string | null, fmt: string = 'll') => {
  return date ? moment.tz(date, tzId || moment.tz.guess()).format(fmt) : '';
};

export const getValueAccordingToType = (value: number, type: string): number => {
  let valueAccordingToType;

  if (type === ShowAvailabilityType.HOURS) {
    valueAccordingToType = moment.duration(value, 'seconds').asHours();
  } else if (type === ShowAvailabilityType.MINUTES) {
    valueAccordingToType = moment.duration(value, 'seconds').asMinutes();
  } else if (type === ShowAvailabilityType.DAYS) {
    valueAccordingToType = moment.duration(value, 'seconds').asDays();
  } else {
    valueAccordingToType = moment.duration(value, 'seconds').asWeeks();
  }

  return Number.isInteger(valueAccordingToType) ? valueAccordingToType : 0;
};

export const getTypeForShowAvailability = (showAvailability: number) => {
  let showAvailabilityType;

  if (showAvailability === 0) {
    showAvailabilityType = ShowAvailabilityType.ALL;
  } else if (Number.isInteger(moment.duration(showAvailability, 'seconds').asWeeks())) {
    showAvailabilityType = ShowAvailabilityType.WEEKS;
  } else if (Number.isInteger(moment.duration(showAvailability, 'seconds').asDays())) {
    showAvailabilityType = ShowAvailabilityType.DAYS;
  } else if (Number.isInteger(moment.duration(showAvailability, 'seconds').asHours())) {
    showAvailabilityType = ShowAvailabilityType.HOURS;
  } else {
    showAvailabilityType = ShowAvailabilityType.MINUTES;
  }

  return showAvailabilityType;
};

export function getDashboardSessionsAvailabilty(timeSlots: ISessionTimeSlot[] | null | undefined) {
  return timeSlots?.length
    ? timeSlots?.map((timeslot) => ({ startDate: timeslot.startDate, endDate: timeslot.endDate }))
    : null;
}

export function  getCreatedDate(session: ISessionForList, intl: IntlShape, timezoneId: string) {
  return session.created
    ? ({
        user: session.created.author,
        date: getDateTime(intl, timezoneId, session.created.date),
        value: session.created.date,
      } as ISessionActionInfo)
    : null;
}

export function getLastModifiedDate(
  session: ISessionForList,
  intl: IntlShape,
  timezoneId: string,
  timeUntilStart: string | undefined
) {
  return session.lastModified
    ? ({
        user: session.lastModified.author,
        date: getDateTime(intl, timezoneId, session.lastModified.date),
        timeUntilStart,
      } as ISessionActionInfo)
    : null;
}

export const getStartDateFormat = (timezoneId: TimezoneType, startDate: moment.Moment) => {
  return timezoneId ? moment.tz(startDate, timezoneId).startOf('day') : startDate.utc().startOf('day');
};

export const getEndDateFormat = (timezoneId: TimezoneType, endDate: moment.Moment) => {
  return timezoneId ? moment.tz(endDate, timezoneId).endOf('day') : endDate.utc().startOf('day').add(1, 'days');
};

export const getEndDateFromStartDateFormat = (timezoneId: TimezoneType, startDate: moment.Moment) => {
  return timezoneId ? startDate.clone().endOf('day') : startDate.clone().add(1, 'day').startOf('day');
};

export const convertTimeWithTimezoneFormat = (timezoneId: TimezoneType, momentDate: moment.Moment | number, formattedTime: string) => {
  return timezoneId ? moment.tz(momentDate, timezoneId).format('LLL (z)') : formattedTime;
};

export const getDateTimeWithTimezone = (timezoneId: TimezoneType, momentDate: moment.Moment) => {
  return timezoneId ? moment.tz(momentDate, timezoneId).startOf('day') : momentDate.utc().startOf('day');
};

export const getProjectDateWithTimezone = (timezoneId: TimezoneType, momentDate: number | null | undefined) => {
  return timezoneId ? moment.tz(momentDate, timezoneId) : convertTimestampMillisToMoment(momentDate || Date.now());
};

export function getStartDateMoment(timezoneId: TimezoneType, startDate: number | null) {
  return timezoneId
    ? moment.tz(startDate, timezoneId)
    : convertTimestampMillisToMoment(startDate || Date.now());
}

export function getEndDateMoment(timezoneId: TimezoneType, endDate: number | null) {
  const endDateMomentForViewMode = timezoneId ? moment.tz(endDate, timezoneId) : convertTimestampMillisToMoment(endDate || Date.now());
  const endDateMomentForEditMode = endDateMomentForViewMode ? endDateMomentForViewMode.clone().startOf('day') : null;

  return {
    endDateMomentForViewMode,
    endDateMomentForEditMode,
  };
}

export const getMaxTime = (timezoneId: TimezoneType) => {
  return timezoneId ? moment.tz(timezoneId).endOf('d') : moment().endOf('d');
};

export const getMinTime = (timezoneId: TimezoneType) => {
  return timezoneId ? moment.tz(timezoneId).startOf('d') : moment().startOf('d');
};

export const getTimezoneId = (timezoneId: TimezoneType) => {
  return timezoneId || moment.tz.guess();
};

export const convertTimeStampToDateFormat = (timezoneId: TimezoneType, momentDate: moment.Moment | number) => {
  return timezoneId ? moment.tz(momentDate, timezoneId).format('LL') : moment(momentDate).format('LL');
};

export const getCreationDate = (createdDate?: number | null, startDate?: number | null) => {
  return createdDate ?? startDate;
};

export const getCurrentDate = (timezoneId: TimezoneType) => {
  return moment().tz(timezoneId || moment.tz.guess());
};

export const getSchedulingLockoutDate = (schedulingLockoutDate?: number | null, projectSchedulingLockoutDate?: number | null) => {
  return schedulingLockoutDate ?? projectSchedulingLockoutDate;
};

export const isCurrentDateValid = (creatingDate?: number | null, timezoneId?: TimezoneType) => {
  return creatingDate ? getCurrentDate(timezoneId) : null;
};

export const getTimeUsingTZ = (timeZoneId: string, timeInfo?: string | null) => {
  const timeWithTZ = moment(timeInfo, 'HH:mm');
  return timeWithTZ.tz(timeZoneId);
};

export const convertDateToUtcUsingTimezone = (selectedDate: moment.Moment, timezoneId: string) => {
  return selectedDate.utc().tz(timezoneId);
};

export const convertDateToUtcUsingTimezoneFormat = (selectedDate: moment.Moment, timezoneId: string) => {
  return selectedDate.utc().tz(timezoneId).format('L');
};

export const milliSecondToDemandTypeConverter = (demandWindowInMiliSecond: number, demandWindowType: DemandWindowType) => {
  const duration = moment.duration(demandWindowInMiliSecond, 'ms');
  let convertedValue: number;

  switch (demandWindowType) {
    case DemandWindowType.DAYS:
      convertedValue = duration.asDays();
      break;
    case DemandWindowType.HOURS:
      convertedValue = duration.asHours();
      break;
    case DemandWindowType.MINUTES:
      convertedValue = duration.asMinutes();
      break;
    default:
      convertedValue = duration.asMinutes();
  }

  return convertedValue;
};