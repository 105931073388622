import ITeam from 'src/app/data/client/interfaces/ITeam';
import { IProjectActiveScenario, IProjectShort, ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import { ILicenseeShort } from 'src/app/data/client/interfaces/IClient';
import IAssetProject, { IAssetProjectBase } from 'src/app/data/client/interfaces/IAsset';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import IAvatar from 'src/app/data/client/interfaces/IAvatar';
import IEnvironment from 'src/app/data/client/interfaces/IEnvironment';
import { TLocaleId } from 'src/i18n';
import SessionStatusType from 'src/app/data/session/interfaces/SessionStatusType';
import { ISessionLearner } from 'src/app/data/session/interfaces/ISession';
import { AvatarRestrictionType } from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/Components/NextGenScenarioAvatarRestriction/NextGenScenarioAvatarRestriction';
import { ScenarioCompositionType } from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/Components/ScenarioCompositionSelector/ScenarioCompositionSelector';
import { INextGenExtraData } from 'src/app/data/nextGenScenario/interfaces/INextGenScenario';

export enum ScenarioStatus {
  DRAFT = 'DRAFT',
  UPCOMING = 'UPCOMING',
  STARTED = 'STARTED', // aka running
  FINISHED = 'FINISHED', // or COMPLETED
  ARCHIVED = 'ARCHIVED', // aka scenario.archived === true
}

export const getCapitalisedTranslatedScenarioStatuses = (scenarioStatus: string):TLocaleId => {
  switch (scenarioStatus) {
    case ScenarioStatus.DRAFT:
      return 'MursionPortal.Label.Capitalized.Draft';
    case ScenarioStatus.UPCOMING:
      return 'MursionPortal.Status.Capitalized.Upcoming';
    case ScenarioStatus.STARTED:
      return 'MursionPortal.Label.Capitalized.Started';
    case ScenarioStatus.FINISHED:
      return 'MursionPortal.Label.Capitalized.Finished';
    case ScenarioStatus.ARCHIVED:
      return 'MursionPortal.Capitalized.Archived';
    default :
      return 'MursionPortal.Status.Empty';
  }
};

export const checkMediaTypeIsVideo = (extension :string) => {
  return (extension && [ 'mp4', 'm4v', 'mkv', 'ogv', 'mov', 'webm', 'avi', 'wmv', 'flv'].includes(extension)) || false;
};
export interface IScenarioStatusConfig {
  [ScenarioStatus.DRAFT]: number;
  [ScenarioStatus.ARCHIVED]: number;
  [ScenarioStatus.UPCOMING]: number;
  [ScenarioStatus.STARTED]: number;
  [ScenarioStatus.FINISHED]: number;
}
export interface IScenarioStatusResponseConfig {
  archived: number;
  draft: number;
  upcoming: number;
  started: number;
  finished: number;
}

export enum ScenarioCreateOption {
  EMPTY,
  NEW,
  REPEAT_CLIENT,
  REPEAT_TEMPLATE
}

export interface IScenarioShort {
  archived: boolean;
  id: string;
  name: string;
  status: ScenarioStatus;
  startDate: number;
  endDate: number;
  inviteToScheduleAllowed: boolean;
  deliveryMode?: SessionType;
  generationType?: number;
  nameCustomized?: string;
}

export interface IScenarioAttachment {
  id: string;
  name: string;
  link: string;
}

export interface IResourcesItems {
  id: string;
  name: string;
}
export interface IScenarioPlanningUpdate {
  endDate: number | null;
  intervalDuration: number;
  startDate: number | null;
  teamIds: string[];
  version: number;
  name?: string;
  completionRate: number | null;
  schedulingRate: number | null;
  missOrCancelationRate?: number | null;
  schedulingLockoutTime: number | null;
  schedulingLockoutDate: number | null;
}

export interface IScenarioPlanning {
  endDate: number;
  intervalDuration: number;
  startDate: number;
  teams: ITeam[];
  version: number;
  name?: string;
  completionRate: number | null;
  schedulingRate: number | null;
  missOrCancelationRate: number | null;
  schedulingLockoutTime: number | null;
  schedulingLockoutDate: number | null;
}

export interface IScenarioDraftUpdate {
  assetProjectId?: string;
  assetSettingsAliases?: { [assetSettingId: string]: string };
  deliveryMode: SessionType;
  goals: string[];
  name: string;
  sessionSize: number;
  sessionLength: number;
  vignette: string;
  avatarIds?: string[];
  environmentId?: string;
  version: number;
  simCheckListUrl: string;
  vignetteLengthExceed?: boolean;
  virtualSimOnly?: boolean;
}

export interface IScenarioDraftCreate extends IScenarioDraftUpdate {
  projectId: string;
  scenarioVersion?: ScenarioVersion;
}

export interface IScenarioBaseInfo {
  deliveryMode: SessionType;
  name: string;
  sessionSize: number;
  sessionLength: number;
  vignette: string | null;
  scenarioVersion?: ScenarioVersion;
  goals: string[];

  planning?: IScenarioPlanning;
}

export interface IScenarioDraft {
  archived: boolean;
  assetProject: IAssetProjectBase | null;
  assetSettingsAliases: { [assetSettingId: string]: string };
  deliveryMode: SessionType;
  goals: string[];
  name: string;
  picture: string | null;
  project: IProjectShort;
  sessionSize: number;
  sessionLength: number;
  vignette: string | null;
  attachments: IScenarioAttachment[] | null;
  scenarioVersion?: ScenarioVersion;
  avatars?: IAvatar[];
  environment?: IEnvironment;
  version: number;
  simCheckListUrl: string;
  avatarRestriction?: AvatarRestrictionType[];
  avatarComposition?: ScenarioCompositionType;
  nameCustomized?: string;
  prepGuide?: string;
  prepGuideCustomized?: string;
  virtualSimOnly?: boolean;
}

export interface IScenarioTemplate {
  archived: boolean;
  assetProject?: IAssetProjectBase;
  assetSettingsAliases?: {[assetId: string]: string};
  certificateId: string;
  certificateName: string;
  deliveryMode: SessionType;
  goals: string[];
  id: string;
  licensee: ILicenseeShort;
  name: string;
  picture: string;
  sessionSize: number;
  sessionLength: number;
  vignette: string;
  scenarioVersion?: ScenarioVersion;
  avatars?: IAvatar[];
  attachments?: IScenarioAttachment[];
  environment?: IEnvironment;
  standardized?: boolean;
  version?: number;
  generationType?: number;
}

export interface IScenarioTemplateCreate {
  assetProjectId?: string;
  assetSettingsAliases?: {[assetId: string]: string};
  avatarIds?: string[];
  deliveryMode: SessionType;
  environmentId?: string;
  goals?: string[];
  licenseeId?: string;
  name: string;
  scenarioVersion?: ScenarioVersion;
  sessionLength: number;
  sessionSize: number;
  version?: number;
  vignette?: string;
  standardized?: boolean;
  vignetteLengthExceed?: boolean;
}

export interface IScenarioTemplateUpdate extends IScenarioTemplateCreate {
  id: string;
}

export interface IScenarioActive extends IScenario {
  projectId: string;
  projectName: string;
  clientId: string;
  clientName: string;
}

export interface IClientUserScenario {
  id:	string;
  name:	string;
  projectName:	string;
  scenarioVersion: ScenarioVersion;
  sessionsCompletedCount:number;
  sessionsErrorCount: number;
  sessionsLateCancelledCount:number;
  sessionsMissedCount:number;
  sessionsScheduledCount: number;
  startDate: number;
  endDate: number;
  status: ScenarioStatus;
  deliveryMode: SessionType;
}

export default interface IScenario {
  originId?: string | null;
  archived: boolean;
  certificateId?: string;
  certificateName?: string;
  draft: IScenarioDraft;

  id: string;
  name: string;
  planning: IScenarioPlanning;
  status: ScenarioStatus;
  version: number;
  learnerPathwayScheduleAllowed: boolean;
  completionRateFulfilled: boolean;
  inviteToScheduleAllowed: boolean;
  schedulingRateFulfilled: boolean;
  missOrCancelationRateFulfilled: boolean;
  generationType?: number;
  nameCustomized?: string;
  prepGuide?: string;
  prepGuideCustomized?: string;
}

export interface IActiveScenarios extends INextGenExtraData{
  prevention?: boolean | null;
  sequence?: number | null;
  version: number;
  name: string;
  deliveryMode:SessionType;
  sessionLength: number;
  sessionSize: number;
  scenarioVersion?: ScenarioVersion;
  environment?: IEnvironment | null;
  avatars?: IAvatar[];
  assetProject: IAssetProject | null;
  goals?: string[];
  vignette: string;
  attachments?: IScenarioAttachment[];
  archived: boolean;
  assetSettingsAliases?: {[assetId: string]: string};
  scenarioId: string;
  project: IProjectActiveScenario;
  state: ActiveScenarioState | null;
  endDate: number;
  completionRate?: number;
  sessionStatus?: SessionStatusType;
  sessionLearners?: ISessionLearner[];
  sessionId?: string;
  schedulingRateFulfilled?: boolean;
  missOrCancelationRateFulfilled: boolean;
  startDate: number;
  simCheckListUrl?: string;
  generationType?: number;
  nameCustomized?: string;
  virtualSimOnly?: boolean;
}

export enum ActiveScenarioState {
  RETAKE = "retake",
  NEXT_SIMULATION_ASSIGNED = "nextSimulationAssigned",
  NEW_SIMULATION_ASSIGNED = "newSimulationAssigned"
}

export interface IScenarioWithSequence {
  id: string;
  name: string;
  sequence?: number;
}

export interface IScenarioSequenceUpdate {
  [key: string]: number;
}

export enum LearnerCompletionType {
  ALL = 'ALL',
  NOT_COMPLETED = 'NOT_COMPLETED',
  COMPLETED_ONE = 'COMPLETED_ONE',
  COMPLETED_TWO_PLUS = 'COMPLETED_TWO_PLUS',
  NOT_SCHEDULED = 'NOT_YET_SCHEDULED',
  SCHEDULED_NOT_YET_COMPLETED ="SCHEDULED_NOT_YET_COMPLETED",
}

export enum MissedLateActivityType {
  ALL = 'ALL',
  ONE_SESSION = 'ONE_SESSION',
  TWO_SESSION = 'TWO_SESSION',
  THREE_SESSION = 'THREE_SESSION',
  FOURPLUS_SESSION = 'FOURPLUS_SESSION',
}

export interface ICopyEventAndScoringIds {
  templateId: string;
  scenarioId: string;
}