export function generateFormDataForParts(formPartsMap: any, addFiles?: File | File[] | null, removeFiles?: string[]): FormData {
  const result = new FormData();

  if (formPartsMap) {
    Object.keys(formPartsMap).forEach(key => {
      result.append(key, new Blob([JSON.stringify(formPartsMap[key])], {
        type: 'application/json'
      }), key);
    });
  }

  if (addFiles) {
    if (Array.isArray(addFiles)) {
      addFiles.forEach(file => result.append('files', file, file.name));
    } else {
      result.append('file', addFiles, addFiles.name);
    }
  }

  if (removeFiles) {
    result.append('purge', new Blob([JSON.stringify(removeFiles)], {
      type: 'application/json'
    }), 'purge');
  }

  return result;
}

export default function generateFormData(entity: any, entityName: string, addFiles: File | File[] | null, removeFiles?: string[]): FormData {
  return generateFormDataForParts({ [entityName]: entity }, addFiles, removeFiles);
}
