exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".App__appRoot___1YkBv{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;height:100%;z-index:-1}.App__appRootWhite___2qkve{background-color:#f1efee}.App__loading___3ZaVJ{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;font-size:2.5rem;height:100%;-ms-flex-pack:center;justify-content:center}li{padding-bottom:1px}", "", {"version":3,"sources":["/home/jenkins/jenkins/workspace/mrsn-applications-deployment/release/Build/frontend/portal-frontend/src/App.css"],"names":[],"mappings":"AAAA,sBACE,oBAAqB,AACrB,aAAc,AACd,0BAA2B,AACvB,sBAAuB,AAC3B,YAAa,AACb,UAAY,CACb,AAED,2BACE,wBAA0B,CAC3B,AAED,sBACE,sBAAuB,AACnB,mBAAoB,AACxB,oBAAqB,AACrB,aAAc,AACd,iBAAkB,AAClB,YAAa,AACb,qBAAsB,AAClB,sBAAwB,CAC7B,AAED,GACE,kBAAoB,CACrB","file":"App.css","sourcesContent":[".appRoot {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  height: 100%;\n  z-index: -1;\n}\n\n.appRootWhite {\n  background-color: #f1efee;\n}\n\n.loading {\n  -ms-flex-align: center;\n      align-items: center;\n  display: -ms-flexbox;\n  display: flex;\n  font-size: 2.5rem;\n  height: 100%;\n  -ms-flex-pack: center;\n      justify-content: center;\n}\n\nli {\n  padding-bottom: 1px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"appRoot": "App__appRoot___1YkBv",
	"appRootWhite": "App__appRootWhite___2qkve",
	"loading": "App__loading___3ZaVJ"
};