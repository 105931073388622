import ICompanyUser from 'src/app/data/licensee/users/interfaces/ICompanyUser';
import IScenario, { IScenarioShort, ScenarioStatus } from 'src/app/data/projects/interfaces/IScenario';
import { IClientShort } from 'src/app/data/client/interfaces/IClient';
import RoleID from 'src/app/data/common/interfaces/RoleID';
import { TLocaleId } from 'src/i18n';
import { IntlShape } from 'react-intl/lib';
import { ISelectorOption } from 'src/components/Selector/Selector';
import { isCurrentUserPSorAOorForAMorO } from 'src/app/data/common/utils/userRoleUtils';
import IUserRoleExtended from 'src/app/data/profile/interfaces/IUserRoleExtended';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import { DeliveryHoursType, DeliveryWeekDaysField } from 'src/app/data/common/utils/dateUtil';
import { INextGenScenario } from 'src/app/data/nextGenScenario/interfaces/INextGenScenario';

export interface IDuplicateTargetScenarioType {
  scenarioVersion: string;
  softwareType: string;
}

export const getCapitalisedTranslatedProjectStatuses = (projectStatus: string):TLocaleId => {
  switch (projectStatus) {
    case ProjectStatus.ACTIVE:
      return 'Dashboard.ProjectsTable.StatusValue.Active';
    case ProjectStatus.COMPLETED:
      return 'Dashboard.ProjectsTable.StatusValue.Completed';
    case ProjectStatus.ARCHIVED:
      return 'Dashboard.ProjectsTable.StatusValue.Archived';
    default :
      return 'Dashboard.ProjectsTable.StatusValue.Active';
  }
};

export const getDemandWindowTypeTranslation = (demandWindowType: DemandWindowType):TLocaleId => {
  switch (demandWindowType) {
    case DemandWindowType.DAYS:
      return 'MursionPortal.Project.Days';
    case DemandWindowType.HOURS:
      return  'MursionPortal.Title.Hours';
    case DemandWindowType.MINUTES:
      return'MursionPortal.Title.Minutes';
    default :
      return 'MursionPortal.Project.Days';
  }
};

export const getShowAvailabilityTypeTranslation = (showAvailabilityType: ShowAvailabilityType): TLocaleId => {
  switch (showAvailabilityType) {
    case ShowAvailabilityType.ALL:
      return 'Filters.All';
    case ShowAvailabilityType.WEEKS:
      return 'MursionPortal.Weeks';
    case ShowAvailabilityType.DAYS:
      return 'MursionPortal.Project.Days';
    case ShowAvailabilityType.HOURS:
      return 'MursionPortal.Title.Hours';
    case ShowAvailabilityType.MINUTES:
      return 'MursionPortal.Title.Minutes';
    default:
      return 'Filters.All';
  }
};

export const getInviteToScheduleToolTip = (isLearnerCount:boolean, intl:IntlShape, inviteToScheduleAllowed:boolean, isInviteToScheduleEmailAllowed:boolean | undefined) => {
  if (!inviteToScheduleAllowed) {
    return intl.formatMessage({ id: 'MursionPortal.Project.InviteToSchedule.Sequencing.Tooltip' });
  }
  else if(!isInviteToScheduleEmailAllowed) {
    return intl.formatMessage({ id: 'MursionPortal.Project.InviteToSchedule.EmailDisabled.Tooltip' });
  }
  return !isLearnerCount
    ? intl.formatMessage({ id: 'MursionPortal.Project.LearnersNotYetScheduled' }, { learners: 0 })
    : intl.formatMessage({ id: 'MursionPortal.Project.InvitedToday.Tooltip' });
};

export const getInviteToScheduleProjectToolTip = (isLearnerCount:boolean, intl:IntlShape, isInviteToScheduleEmailAllowed:boolean | undefined) => {
  if (!isInviteToScheduleEmailAllowed) {
    return intl.formatMessage({ id: 'MursionPortal.Project.InviteToSchedule.EmailDisabled.Tooltip' });
  }
  return !isLearnerCount
    ? intl.formatMessage({ id: 'MursionPortal.Project.LearnersNotYetScheduled' }, { learners: 0 })
    : intl.formatMessage({ id: 'MursionPortal.Project.InviteToScheduleProject.Tooltip' });
};

export const inviteToScheduleBtnSequencing = (isLearnerCount:boolean, isInviteToScheduleAllowed:boolean) => {
    return !isInviteToScheduleAllowed ? true : !isLearnerCount;
};

export const isInviteToScheduleButtonVisible = (
  userRole: IUserRoleExtended | null,
  scenarioInitialData: IScenario | INextGenScenario | Partial<IScenario> | null
) => {
  return (
    isCurrentUserPSorAOorForAMorO(userRole) &&
    scenarioInitialData?.draft?.deliveryMode === SessionType.ONE_TO_ONE &&
    (scenarioInitialData.status === ScenarioStatus.STARTED || scenarioInitialData.status === ScenarioStatus.UPCOMING)
  );
};

export enum ProjectStatus {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  ARCHIVED = 'ARCHIVED', // aka project.archived === true
}

export enum DemandWindowType {
  DAYS = 'DAYS',
  HOURS = 'HOURS',
  MINUTES = 'MINUTES',
}

export enum ShowAvailabilityType {
  ALL = 'ALL',
  WEEKS = 'WEEKS',
  DAYS = 'DAYS',
  HOURS = 'HOURS',
  MINUTES = 'MINUTES',
}

export enum ProjectStatusRequestParam {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  ARCHIVED = 'archived',
}

export enum ScenarioVersion {
  V2 = 'V2',
  V3 = 'V3',
  V3z = 'V3z',
  V3meet = 'V3meet',
  V3MAGIC = 'V3MAGIC',
}

export enum ScenarioSoftwareVersion {
  DESKTOP_V3 = 'DESKTOP_V3',
  WEB_V3 = 'WEB_V3',
  DESKTOP_WEB_V3 = 'DESKTOP_WEB_V3',
  V3z = 'V3z',
  V3meet = 'V3meet',
}

export enum SoftwareType {
  WEB = 'WEB',
  DESKTOP = 'DESKTOP',
  WEB_DESKTOP = 'WEB_DESKTOP',
}

export interface IProjectUpdate extends IProjectViewAndUpdate, IProjectDeliveryHours {
  accManagerIds: string[];
  archived?: boolean;
  id?: string;
  clientId?: string;
  hours: number;
  name: string;
  tags: string[];
  startDate: number | null;
  endDate: number | null;
  scenarioVersion?: ScenarioVersion;
  softwareType?: SoftwareType;
  version: number;
  recordingAllowed: boolean;
  recordingDoNotShare?:boolean;
  recordingRecipients: RoleID[];
  recordingLearnerChoice: boolean;

  billable: boolean;
  demandBasedScheduling: boolean;
  demandWindow: number | null;
  demandBasedSchedulingStartTime: string | null;
  demandBasedSchedulingEndTime: string | null;
  timezoneId?: string | null;
  cancellationWindow: number | null;
  contractId: string;
  sessionSmartMetrics: boolean;
  reschedulingEnabled : boolean;
  sameDayRescheduling: boolean;
  demandBasedSlotsCount: number | null;
  demandWindowType: DemandWindowType;
  surveyEnabled?: boolean;
  enabledReportOneToOneDeliveries?: boolean;
  simPostSimulationSurveyOneToOne?:boolean;
  simPostSimulationSurveyWorkshop?:boolean;
  sequencing?: boolean;
  sessionTimeStep?: number | null;
  showAvailability: number;
  upcomingAvailability: number;
  joinNow: boolean | undefined;
  sessionMissedTime: number | null;
  virtualSimOnly?: boolean;
}

export interface IProjectViewAndUpdate {
  completionRate?: number | null;
  schedulingLockoutTime?: number | null;
  schedulingRate?: number | null;
  missOrCancelationRate?: number | null;
  schedulingLockoutDate?: number | null;
}

export interface IProjectFieldPermission {
  edit: RoleID[];
  view: RoleID[] | null; // if 'null' anyone can view the field
  editableWithScenarios: boolean;
}

export interface IProjectPropsPermissions {
  id: IProjectFieldPermission;
  archived: IProjectFieldPermission;
  version: IProjectFieldPermission;
  hours: IProjectFieldPermission;
  name: IProjectFieldPermission;
  startDate: IProjectFieldPermission;
  endDate: IProjectFieldPermission;
  tags: IProjectFieldPermission;
  scenarioVersion: IProjectFieldPermission;
  softwareType: IProjectFieldPermission;
  recordingAllowed: IProjectFieldPermission;
  recordingDoNotShare:IProjectFieldPermission;
  recordingRecipients: IProjectFieldPermission;
  recordingLearnerChoice: IProjectFieldPermission;
  billable: IProjectFieldPermission;
  cancellationWindow: IProjectFieldPermission;
  contractId: IProjectFieldPermission;
  demandWindow: IProjectFieldPermission;

  accManagers: IProjectFieldPermission;
  client: IProjectFieldPermission;

  createdDate: IProjectFieldPermission;
  learnerCount: IProjectFieldPermission;
  scenarios: IProjectFieldPermission;
  actualSessionsDuration: IProjectFieldPermission;
  plannedSessionsDuration: IProjectFieldPermission;
  status: IProjectFieldPermission;
  demandBasedScheduling: IProjectFieldPermission;
  reschedulingEnabled : IProjectFieldPermission;
  sessionTimeStep?: IProjectFieldPermission;
  showAvailability: IProjectFieldPermission;
  upcomingAvailability: IProjectFieldPermission;
  sessionMissedTime: IProjectFieldPermission;
  autoSimCheckbox: IProjectFieldPermission;
}

export interface IProjectShort {
  archived: boolean;
  id: string;
  name: string;
  demandBasedScheduling?: boolean;
  timezoneId?: string | null;
  nameCustomized?:string;
}

export interface IProjectActiveScenario extends IProjectShort{
  version: number;
  demandBasedScheduling: boolean;
  joinNow: boolean;
  demandBasedSchedulingStartTime: string;
  demandBasedSchedulingEndTime: string;
  timezoneId: string;
  sessionTimeStep: number;
  demandWindow: number;
  demandWindowType: DemandWindowType;
}

// is used in session table
export interface IProjectSimple {
  id: string;
  name: string;
  scenarioVersion: ScenarioVersion;
  contractId: string | null;
  sameDayRescheduling?:boolean;
  reschedulingEnabled?:boolean;
  simPostSimulationSurveyOneToOne?: boolean;
  simPostSimulationSurveyWorkshop?: boolean;
  sessionMissedTime?: number | null;
  demandBasedScheduling?: boolean;
  timezoneId?: string | null;
}

export interface IProjectBase {
  archived: boolean;
  endDate: number | null;
  id: string;
  name: string;
  startDate: number | null;
  status: ProjectStatus;
  tags: string[];
  scenarioVersion?: ScenarioVersion;
  version: number;
}

export type TCreateScenarioProjectPart = {
  id: string;
  client: {
    id: string
  } & Partial<IClientShort>;
  startDate: number | null;
  endDate: number | null;
  archived: boolean;
} & Partial<IProject>;


export interface IProjectUtilization {
  cancelledSessions: number;
  missedSessions: number;

  utilizedSessions: number;
  utilizedDuration: number;
  remainingDuration: number;
}

export interface IProjectSurveyConfig {
  enablePreSimulation: boolean;
  enablePostSimulation: boolean;
}

export interface IClientProjectList {
  id: string;
  name: string;
}

interface IProjectDeliveryHours {
  deliveryHoursStartTime: string | null;
  deliveryHoursEndTime: string | null;
  deliveryHoursType: DeliveryHoursType;
  deliveryWeekDays: {
    [value in DeliveryWeekDaysField]: boolean
  };
}

export default interface IProject extends IProjectBase, IProjectViewAndUpdate, IProjectDeliveryHours {
  accManagers: ICompanyUser[];
  hours?: number;
  client: IClientShort;
  createdDate?: number | null;
  learnerCount: number;
  scenarios: IScenarioShort[];
  actualSessionsDuration?: number;
  plannedSessionsDuration?: number;
  recordingAllowed: boolean;
  recordingDoNotShare?:boolean;
  recordingRecipients?: RoleID[];
  recordingLearnerChoice: boolean;
  softwareType?: SoftwareType;

  demandWindow?: number | null;
  demandBasedScheduling?: boolean;
  demandBasedSchedulingStartTime?: string | null ;
  demandBasedSchedulingEndTime?: string | null;
  timezoneId?: string | null;
  billable?: boolean;
  cancellationWindow?: number;
  contractId?: string;
  sessionSmartMetrics?: boolean;
  reschedulingEnabled? : boolean;
  sameDayRescheduling?: boolean;
  demandBasedSlotsCount: number | null;
  demandWindowType: DemandWindowType; 
  surveyEnabled?: boolean;
  simPostSimulationSurveyOneToOne?:boolean;
  simPostSimulationSurveyWorkshop?:boolean;
  sequencing?: boolean;
  enabledReportOneToOneDeliveries?: boolean;
  sessionTimeStep?: number | null;
  cancellationWindowType: DemandWindowType; 
  showAvailability: number;
  showAvailabilityType: ShowAvailabilityType;
  upcomingAvailability: number;
  joinNow?: boolean;
  sessionMissedTime: number | null;
  scenarioSettingErrorMessage?: string;
  virtualSimOnly?: boolean;
}

export interface IProjectListByContractPayload {
  clientId: { value: string[] } | null;
  contractProjectMappingFlag: boolean;
  page: number;
  size: number;
  archive: string;
  contractIds: { value: string[] } | null;
}

export enum TierOptions {
  TIER_ONE = 'Tier 1',
  TIER_TWO = 'Tier 2',
  TIER_THREE = 'Tier 3',
  TIER_FOUR = 'Tier 4',
  LEAD_SIMULATION = 'Lead Simulation Specialist',
}

export const enableInvitationBtn = (processedDate : string, scheduledDate : string) => {
  return  processedDate ? false : !!scheduledDate;
};

export const filterProjectList = (project:IProject, ScenarioStatusStarted:string, ScenarioStatusUpcoming:string, SessionTypeOneToOne:string) => {
  const filterScenario = project.scenarios.filter((itemList: IScenarioShort) => {
    const scenarioStatusStartedOrUpcoming = itemList?.status === ScenarioStatusStarted || itemList?.status === ScenarioStatusUpcoming;
    return (scenarioStatusStartedOrUpcoming && itemList?.deliveryMode === SessionTypeOneToOne);
  });
  project.scenarios = filterScenario;
  return project;
};

export const checkInviteToScheduleButton = (canViewInviteToSchedule:boolean, showInviteToSchedule:boolean) => {
  return canViewInviteToSchedule && !showInviteToSchedule;
};

export const inviteToScheduleBtnEnabledForSequencing = (isLearnerCount:boolean, isInviteToScheduleAllowed:boolean) => {
  return !isInviteToScheduleAllowed ? true : isLearnerCount;
};

export const inviteToScheduleBtnToolTip = (inviteToScheduleAllowed : boolean, isLearnerCountCheck : boolean, intl : IntlShape) => {
  if (!inviteToScheduleAllowed) {
    return intl.formatMessage({ id: 'MursionPortal.Project.InviteToSchedule.Sequencing.Tooltip' });
  }
  return isLearnerCountCheck ? '' : intl.formatMessage({ id: 'MursionPortal.Project.InvitedToday.Tooltip' });
};

export const getShowAvailabilityOptions = (intl: IntlShape): Array<ISelectorOption<ShowAvailabilityType>> => [
  {
    value: ShowAvailabilityType.ALL,
    label: intl.formatMessage({ id: 'Filters.All' }),
  },
  {
    value: ShowAvailabilityType.WEEKS,
    label: intl.formatMessage({ id: 'MursionPortal.Weeks' }),
  },
  {
    value: ShowAvailabilityType.DAYS,
    label: intl.formatMessage({ id: 'MursionPortal.Project.Days' }),
  },
  {
    value: ShowAvailabilityType.HOURS,
    label: intl.formatMessage({ id: 'MursionPortal.Title.Hours' }),
  },
  {
    value: ShowAvailabilityType.MINUTES,
    label: intl.formatMessage({ id: 'MursionPortal.Title.Minutes' }),
  },
];
