import { getCancellationWindowValue } from "src/app/data/common/utils/dateUtil";
import { DemandWindowType } from "src/app/data/projects/interfaces/IProject";
import { cancellationWindowUpperLimit } from "src/app/data/common/utils/getValidationErrorMessage";

const isCancellationWindowZero = (cancellationWindow: number | undefined, cancellationWindowType: DemandWindowType) => {
    const getCancellationWindowValueWithType = getCancellationWindowValue(cancellationWindow, cancellationWindowType);
    const cancellationWindowValue = Number.isInteger(getCancellationWindowValueWithType) ? getCancellationWindowValueWithType : 0;

    return cancellationWindowValue === 0;
};

const isCancellationWindowGreaterThanUpperLimit = (cancellationWindow: number | undefined) => {
    return cancellationWindow && cancellationWindow > cancellationWindowUpperLimit;
};

export {
    isCancellationWindowZero,
    isCancellationWindowGreaterThanUpperLimit,
};