import moment, { Moment } from 'moment-timezone';
import TIMEZONES, { ITimezone, TIMEZONE_ALIAS, TIMEZONE_REPLACE } from '../timezones';
import ICalendarRange from 'src/layouts/common/Calendar/components/common/ICalendarRange';

const nowStartOf = (startOf: moment.unitOfTime.StartOf, tzId: string) => {
  return moment.tz(tzId).startOf(startOf).valueOf();
};

const dateTimeAdd = (dateTime: number, unitOfTime: moment.unitOfTime.DurationConstructor, amount: number, tzId: string) => {
  return moment.tz(dateTime, tzId).add(amount, unitOfTime).valueOf();
};

const dateTimeSubtract = (dateTime: number, unitOfTime: moment.unitOfTime.DurationConstructor, amount: number, tzId: string) => {
  return moment.tz(dateTime, tzId).subtract(amount, unitOfTime).valueOf();
};

const format = (dateTime: number, tzId: string | null, fmt: string) => {
  return moment.tz(dateTime, tzId || moment.tz.guess()).format(fmt);
};

const TIME_RANGES = {
  DAY: moment.duration('P1D').asMilliseconds(),
  HOUR: moment.duration('PT1H').asMilliseconds(),
  MINUTE: moment.duration('PT1M').asMilliseconds(),
  MONTH: moment.duration('P1M').asMilliseconds(),
  WEEK: moment.duration('P7D').asMilliseconds(),
};

const getNearestAvailableTimezone = (defaultTzId: string): ITimezone => {
  const defaultTzOffset = +moment.tz(defaultTzId).format('ZZ');

  // check timezone aliases
  if (TIMEZONE_ALIAS[defaultTzId]) {
    return getNearestAvailableTimezone(TIMEZONE_ALIAS[defaultTzId]);
  }

  // try to find time zone by id
  const timezone = TIMEZONES.find(tz => tz.id === defaultTzId);

  if (timezone) {
    if (TIMEZONE_REPLACE[timezone.id]) {
      return getNearestAvailableTimezone(TIMEZONE_REPLACE[timezone.id]);
    }

    return timezone;
  }

  // raise the priority of search in timezones which contain a part of default tz id
  const timezoneByCity = TIMEZONES.find(tz =>
    defaultTzId.split('/').pop() === tz.id.split('/').pop()
  );

  if (timezoneByCity) {
    return timezoneByCity;
  }

  const timezonesByZoneName = TIMEZONES.filter(tz =>
    moment.tz(defaultTzId).zoneName() === moment.tz(tz.id).zoneName() &&
    moment.tz(defaultTzId).isDST() === moment.tz(tz.id).isDST()
  );
  const restTimezones = TIMEZONES.filter(tz => !timezonesByZoneName.includes(tz));

  const timezones = [...timezonesByZoneName, ...restTimezones];

  // get the closest available timezone
  let minDiff = Number.MAX_SAFE_INTEGER;
  let resultTz = {
    id: defaultTzId,
    name: defaultTzId,
  };


  timezones.forEach((tz) => {
    const tzOffset = +moment.tz(tz.id).format('ZZ');
    const diff = Math.abs(tzOffset - defaultTzOffset);

    if (diff < minDiff) {
      minDiff = diff;
      resultTz = tz;
    }
  });

  return resultTz;
};

const endTimeFromRange = (range: ICalendarRange, tzId: string): number => {
  const from = range.start;

  let unit: moment.unitOfTime.DurationConstructor;
  switch (range.step) {
    case TIME_RANGES.WEEK:
      unit = 'weeks';
      break;
    case TIME_RANGES.DAY:
      unit = 'days';
      break;
    default:
      unit = 'months';
      break;
  }

  return moment.tz(from, tzId).add(1, unit).valueOf();
};

/**
 * returns a month period with addition days
 * - at the beginnings of the fist week and
 * - at the end of the last week
 * - may include the whole next month
 *
 * @param date
 */
const getCalendarMonthRange = (date: Moment) => {
  const currentDate = date.clone();

  const start = currentDate.clone()
    .startOf('month')
    .clone()
    .startOf('week');

  const end = currentDate.clone()
    .add(1, 'week')
    .endOf('month')
    .add(1, 'week')
    .startOf('week');

  return {
    start: start.valueOf(),
    end: end.valueOf()
  };
};

const isWeekEnd = (date: number, tzId: string) => {
  const dayOfWeek = tzId ? moment(date).tz(tzId)?.days() : null;
  return (dayOfWeek === 0 || dayOfWeek === 6);
};

export {
  endTimeFromRange,
  dateTimeAdd,
  dateTimeSubtract,
  format,
  getNearestAvailableTimezone,
  getCalendarMonthRange,
  nowStartOf,
  TIME_RANGES,
  isWeekEnd,
};
