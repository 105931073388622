import { TLocaleId } from "src/i18n";

export enum SimSpecialistStatusType {
    CONNECTED = 'CONNECTED',
    LATE = 'LATE',
    IMMERSING = 'IMMERSING',
    COMPLETED = 'COMPLETED',
    LEFT = 'LEFT',
    ERROR = 'ERROR',
    SIM_ERROR='ERROR',
    DECLINED = 'DECLINED',
    MISSED = 'MISSED',
    SIM_TECHNICAL_ERROR = 'SIM_TECHNICAL_ERROR'
  }

  export enum SimSpecialistStatus {
    MISSED = 'Missed',
    SIM_TECHNICAL_ERROR = 'Sim technical error'
  }

export const getSimSpecialistStatusTypeI18nId = (learnerStatusLabelType: SimSpecialistStatusType): TLocaleId => {
    switch (learnerStatusLabelType) {
      case SimSpecialistStatusType.CONNECTED:
        return 'Mursion.Portal.Status.Connected';
      case SimSpecialistStatusType.LATE:
        return 'Mursion.Portal.Status.Late';
      case SimSpecialistStatusType.IMMERSING:
        return 'Mursion.Portal.Status.Immersing';
      case SimSpecialistStatusType.COMPLETED:
        return 'MursionPortal.Capitalized.Completed'; // Exists
      case SimSpecialistStatusType.LEFT:
        return 'Mursion.Portal.Status.Left';
      case SimSpecialistStatusType.SIM_ERROR:
        return 'MursionPortal.Status.Capitalized.SimError'; // Exists
      case SimSpecialistStatusType.DECLINED:
        return 'Mursion.Portal.Status.Declined';
      case SimSpecialistStatusType.MISSED:
        return 'MursionPortal.Status.Capitalized.Missed'; // Exists
      default :
        return 'MursionPortal.Status.Empty';
    }
  };