import { CreateEventType, EventType } from 'src/app/data/calendar/interfaces/EventType';
import moment from 'moment-timezone';
import ICompanyConfig from 'src/app/data/companyConfig/interfaces/ICompanyConfig';

const getEventDurationGap = (eventType: EventType | CreateEventType | null, companyConfig: Partial<ICompanyConfig> | null) => {
  const duration = moment.duration(30, 'm').asMilliseconds();

  switch (eventType) {
    case EventType.AVAILABILITY:
    case CreateEventType.AVAILABILITY:
      return companyConfig?.availability?.timeStep || duration;
    case EventType.SCHEDULED_SESSION:
    case CreateEventType.EMERGENCY_SESSION:
      return companyConfig?.session?.timeStep || duration;
    case EventType.COMPANY_EVENT:
    case CreateEventType.QA:
    case CreateEventType.TRAINING:
    case CreateEventType.BREAK_PREP:
    default:
      return companyConfig?.licenseeEvent?.timeStep || duration;
  }
};

export default getEventDurationGap;
