export function sortListByField<T extends any>(items: T[], sortBy: string, sortOrder: string): T[] {
  if (!sortBy) {
    return items;
  }

  return items.sort((itemA, itemB) => {

    if (!sortBy) {
      return 0;
    }

    if (!Object.prototype.hasOwnProperty.call(itemA, sortBy) || !Object.prototype.hasOwnProperty.call(itemB, sortBy)) {
      return 0;
    }

    if (!itemA[sortBy] && itemB[sortBy]) {
      return sortOrder === 'asc' ? -1 : 1;
    }

    if (itemA[sortBy] && !itemB[sortBy]) {
      return sortOrder === 'asc' ? 1 : -1;
    }

    if (Array.isArray(itemA[sortBy])) {
      const lengthA = itemA[sortBy].length;
      const lengthB = itemB[sortBy].length;

      if (lengthA === lengthB) {
        return 0;
      }

      if (lengthA < lengthB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      return sortOrder === 'asc' ? 1 : -1;
    }

    const fieldA = (itemA[sortBy] || 0).toString().toUpperCase();
    const fieldB = (itemB[sortBy] || 0).toString().toUpperCase();
    if (fieldA < fieldB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (fieldA > fieldB) {
      return sortOrder === 'asc' ? 1 : -1;
    }

    return 0;
  });
}
