import queryString from 'query-string';
import RoleID from 'src/app/data/common/interfaces/RoleID';

const getUrlParamValue = (paramName: string, location: any) => {
  const searchParams = queryString.parse(location.search);
  const value = searchParams[paramName];
  // parse result may be an array of strings
  // if so, take the first value
  const singleValue = Array.isArray(value) ? value[0] : value;

  return singleValue || '';
};

export const getUrlParamRole = (paramName: string, location: any): RoleID | undefined => {
  const searchParams = queryString.parse(location.search);
  const value = searchParams[paramName];
  // parse result may be an array of strings
  // if so, take the first value
  const singleValue = Array.isArray(value) ? value[0] : value;

  if (Object.values(RoleID).includes(singleValue as RoleID)) {
    return singleValue as RoleID;
  } else {
    return undefined;
  }
};

export const updateUrlParamsValue = (params: any, location: any, history: any) => {
  const searchParams = queryString.parse(location.search);

  location.search = queryString.stringify({
    ...searchParams,
    ...params,
  });

  history.push(location);
};

export enum PasswordStrengthTypes {
  TooWeak = 'Too weak',
  Weak = 'Weak',
  Medium = 'Medium',
  Strong = 'Strong',
}

export default getUrlParamValue;
