import RoleID from 'src/app/data/common/interfaces/RoleID';
import IUserProfile from 'src/app/data/profile/interfaces/IUserProfile';
import { ISession, ISessionForList, SessionUserStatus } from 'src/app/data/session/interfaces/ISession';
import SessionStatusType from 'src/app/data/session/interfaces/SessionStatusType';
import {
  isCurrentUserBorF,
  isCurrentUserClientUser,
  isCurrentUserLearner,
  isCurrentUserOps,
  isCurrentUserPS,
  isCurrentUserPSorAMorOps,
  isCurrentUserSimDesigner,
  isCurrentUserSimSpec,
} from 'src/app/data/common/utils/userRoleUtils';
import IUserRoleExtended from 'src/app/data/profile/interfaces/IUserRoleExtended';
import IScenario, { ScenarioStatus } from 'src/app/data/projects/interfaces/IScenario';
import {
  hasPermissionToCancelSession,
  hasPermissionToCreateSession,
  hasPermissionToEditSession,
  hasPermissionToEditSSSessionNotes,
  hasPermissionToEditTrainingSession,
  hasPermissionToSwapSession
} from 'src/app/data/permissions/sessionPermissionUtils';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import moment from 'moment-timezone';
import {
  canChangeSimSpecialist,
  isCurrentUserSessionSS,
  isSelfCreated
} from 'src/layouts/common/Sessions/SessionsTable/components/SessionEditForm/utils';
import IUser from 'src/app/data/common/interfaces/IUser';
import { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';

const SESSION_STATUS_REPORT_VIEW: SessionStatusType[] = [
  SessionStatusType.COMPLETED, SessionStatusType.ERROR,
];

const canSessionBeCancelled = (session: ISession,  userRole: IUserRoleExtended,): boolean => {
  const { status, archived } = session;

  const statusesToCancel = [
    SessionStatusType.RESERVED,
    SessionStatusType.ORPHAN,
    SessionStatusType.WAIF,
    SessionStatusType.BOOKED,
    SessionStatusType.SWAP,
  ];

  if (isCurrentUserOps(userRole) || isCurrentUserLearner(userRole)) {
    statusesToCancel.push(SessionStatusType.UPCOMING);
  }

  if (isCurrentUserPS(userRole)) {
    statusesToCancel.push(SessionStatusType.UPCOMING);
    statusesToCancel.push(SessionStatusType.PENDING);
    statusesToCancel.push(SessionStatusType.RUNNING);
  }

  return !archived && !!status && statusesToCancel.includes(status);
};


const canSessionBeLeft = (session: ISession, userRole: IUserRoleExtended): boolean => {
  const { status } = session;

  const statusesToLeave = [
    SessionStatusType.RESERVED,
    SessionStatusType.ORPHAN,
    SessionStatusType.WAIF,
    SessionStatusType.BOOKED,
    SessionStatusType.SWAP,
  ];

  if (isCurrentUserLearner(userRole)) {
    statusesToLeave.push(SessionStatusType.UPCOMING);
  }

  return !!status && statusesToLeave.includes(status);
};

const canSessionBeEdited = (session: ISession, timezoneId: string, userRole: IUserRoleExtended): boolean => {
  const { status } = session;

  const statusesToEdit = [
    SessionStatusType.RESERVED,
    SessionStatusType.ORPHAN,
    SessionStatusType.WAIF,
    SessionStatusType.BOOKED,
    SessionStatusType.SWAP,
  ];

  if(isCurrentUserLearner(userRole)){
    statusesToEdit.push(SessionStatusType.UPCOMING);
  }

  if (!status || !statusesToEdit.includes(status)) {
    return false;
  }

  const now = timezoneId ? moment.tz(timezoneId) : moment();
  const eventStart = timezoneId ? moment.tz(session.startDate, timezoneId) : moment(session.startDate);

  return eventStart.isAfter(now);
};

const canSimSpecBeAssignedToSession = (session: ISession): boolean => {
  const { status } = session;

  const statusesToAssignSS = [
    SessionStatusType.RESERVED,
    SessionStatusType.ORPHAN,
    SessionStatusType.WAIF,
    SessionStatusType.BOOKED,
    SessionStatusType.UPCOMING,
    SessionStatusType.SWAP,
  ];

  return !!status && statusesToAssignSS.includes(status);
};

const canSessionBeSwapped = (session: ISession, isCurrentUserProfService: boolean): boolean => {
  const { status } = session;

  const statusesToSwap = [
    SessionStatusType.BOOKED,
    SessionStatusType.RESERVED
  ];

  if (isCurrentUserProfService) {
    statusesToSwap.push(SessionStatusType.UPCOMING);
  }

  return !!status && statusesToSwap.includes(status);
};

const canSessionSwapBeAccepted = (session: ISession): boolean => {
  return session.status === SessionStatusType.SWAP;
};

const canSessionRequestBeAccepted = (session: ISession): boolean => {
  const { status } = session;

  const statusesToAcceptRequest = [
    SessionStatusType.ORPHAN,
    SessionStatusType.WAIF,
  ];

  return !!status && statusesToAcceptRequest.includes(status);
};

const canSessionBeParticipatedIn = (session: ISession) => {
  const { status } = session;

  const statusesToJoin = [
    SessionStatusType.RESERVED,
    SessionStatusType.ORPHAN,
    SessionStatusType.WAIF,
    SessionStatusType.SWAP,
    SessionStatusType.BOOKED,
  ];

  return !!status && statusesToJoin.includes(status);
};

const canCurrentUserJoinTheSession = (currentUserProfile: IUserProfile | null, sessionLearners?: IUser[]) => {
  return !!sessionLearners && !!currentUserProfile
    && currentUserProfile.currentRoleId === RoleID.LEARNER
    && !sessionLearners.some(learner => learner.id === currentUserProfile.id);
};

const canCurrentUserLeaveTheSession = (currentUserProfile: IUserProfile | null, session: ISession, userRole: IUserRoleExtended) => {
  return canSessionBeLeft(session, userRole) && !!currentUserProfile
    && currentUserProfile.currentRoleId === RoleID.LEARNER
    && currentUserProfile.id !== session.createdBy.profileId
    && session.learners.some(learner => learner.id === currentUserProfile.id);
};

const canCurrentUserChangeSS = (currentUserRole: IUserRoleExtended | null, session: ISession) => {

  if (!isCurrentUserPSorAMorOps(currentUserRole) || !session.status) {
    return false;
  }

  return canChangeSimSpecialist(currentUserRole, session) && canSimSpecBeAssignedToSession(session);
};

const canCurrentUserLeaveLearnersEmpty = (currentUserRole: IUserRoleExtended | null, session: ISession) => {
  if (!currentUserRole) {
    return false;
  }

  if (session.externalLearnersOnly || session.status !== SessionStatusType.BOOKED) {
    return true;
  }

  const canEditLearners = [RoleID.BUYER, RoleID.FACILITATOR].includes(currentUserRole.id);

  return !canEditLearners;
};

const canGroupSessionBeCreated = (currentUserProfile: IUserProfile | null) => {
  const availableRoleIds = [
    RoleID.BUYER,
    RoleID.FACILITATOR,
  ];

  return !!currentUserProfile
    && (currentUserProfile.roles.some(role => isCurrentUserLearner(role))
      && currentUserProfile.roles.some(role => availableRoleIds.includes(role.id))
      || (currentUserProfile.currentRoleId === RoleID.PROF_SERVICE || currentUserProfile.currentRoleId === RoleID.OPERATIONS));
};

const canSessionReportBeViewed = (currentUserProfile: IUserProfile | null, currentUserRole: IUserRoleExtended | null, session: ISession | null) => {
  if (!currentUserProfile || !currentUserRole || !session) {
    return false;
  }

  const currentUserIsLearner = isCurrentUserLearner(currentUserRole);
  const currentUserIsSS = isCurrentUserSimSpec(currentUserRole);
  const currentUserParticipated = !!session && session.learners.some(learner => learner.id === currentUserProfile.id && !!learner.participated);

  return !!session.status && SESSION_STATUS_REPORT_VIEW.includes(session.status)
    && (
      session.training
      || !!session.recordingRecipients && session.recordingRecipients.includes(currentUserRole.id)
    ) && (
      session.training && (
        currentUserIsSS && currentUserParticipated
        || currentUserIsSS && !!session.simspecialist && session.simspecialist.id === currentUserProfile.id
        || isCurrentUserPSorAMorOps(currentUserRole)
      )
      || (!session.training && (!currentUserIsLearner || currentUserIsLearner && currentUserParticipated))
    );
};

const canScheduleSessionForScenario = (scenario: Partial<IScenario> | null, userProfile: IUserProfile | null, currentUserRole: IUserRoleExtended | null): boolean => {
  if (!scenario || !userProfile || !currentUserRole || !scenario?.draft?.deliveryMode) {
    return false;
  }

  const { archived, status, certificateId, draft, learnerPathwayScheduleAllowed } = scenario;
  const { deliveryMode } = draft;

  const enabledScenarioStatuses = [ScenarioStatus.STARTED, ScenarioStatus.UPCOMING];

  if (archived
    || !status
    || !enabledScenarioStatuses.includes(status)
    || !certificateId
    || !scenario.draft.scenarioVersion
  ) {
    return false;
  }

  if (
    [ScenarioVersion.V3meet, ScenarioVersion.V3z, ScenarioVersion.V2].includes(scenario.draft.scenarioVersion) &&
    [RoleID.SIM_SPECIALIST, RoleID.ACC_MANAGER].includes(currentUserRole.id)
  ) {
    return false;
  }

  // if user is not a client, trainings can be scheduled
  if (!isCurrentUserClientUser(currentUserRole) && !isCurrentUserSimDesigner(currentUserRole)) {
    return true;
  }

  // if user is a client check permissions
  if (!hasPermissionToCreateSession(currentUserRole, deliveryMode)) {
    return false;
  }

  if (isCurrentUserLearner(currentUserRole)) {
    return deliveryMode === SessionType.ONE_TO_ONE && !!learnerPathwayScheduleAllowed;
  }

  if (isCurrentUserBorF(currentUserRole)) {
    switch (deliveryMode) {
      case  SessionType.ONE_TO_ONE:
      case  SessionType.ONE_TO_MANY_REMOTE:
      case  SessionType.ONE_TO_MANY_LOCAL:
        return true;
      case  SessionType.GROUP:
        return userProfile.roles.some(role => role.id === RoleID.LEARNER);
      default:
        return false;
    }
  }

  return false;
};

const canEditSessionSSNotes = (
  session: ISession,
  userProfile: IUserProfile,
  userRole: IUserRoleExtended
) => {
  const { status } = session;

  const statusesToEditNotes = [
    SessionStatusType.BOOKED,
    SessionStatusType.COMPLETED,
    SessionStatusType.MISSED,
    SessionStatusType.ERROR,
  ];

  return hasPermissionToEditSSSessionNotes(userRole)
    && isCurrentUserSessionSS(userProfile, session)
    && !session.training // training sessions doesn't have notes
    && !!status && statusesToEditNotes.includes(status);
};

const canCurrentUserEditAnyTraining = (userRole: IUserRoleExtended) => {
  return (userRole.id === RoleID.PROF_SERVICE || userRole.id === RoleID.OPERATIONS);
};

const manageSessionAttendance = (session: ISession, userProfile: IUserProfile | null, timezoneId: string) => {
  const simCurrentTime = moment.tz(timezoneId).valueOf();
  const isSessionSS = !!session.simspecialist && session.simspecialist.id === userProfile?.id && userProfile?.currentRoleId === RoleID.SIM_SPECIALIST;
  const isScenarioOfMeetOrZoom = session.scenarioVersion === ScenarioVersion.V3z || session.scenarioVersion === ScenarioVersion.V3meet;
  const displayAttendanceButton = (isSessionSS && (isBeforeSimsSessionNextDayMidnight(timezoneId, session.endDate)) || (userProfile?.currentRoleId === RoleID.SUPPORT_ADMIN)) && session.status !== (SessionStatusType.CANCELLED ||SessionStatusType.LATE_CANCELLED ||SessionStatusType.LICENSEE_CANCELLED ||SessionStatusType.EARLY_CANCELLED) && simCurrentTime > session.startDate;
  if (session.scenarioVersion === ScenarioVersion.V3) {
    return (displayAttendanceButton);
  }
  return (displayAttendanceButton && session.ml3SocialAttendanceClient && session.ml3SocialAttendanceLicensee && isScenarioOfMeetOrZoom);
};

const isBeforeSimsSessionNextDayMidnight = (timezoneId:string, endDate: number) => {
  return moment().tz(timezoneId).isSameOrBefore(moment(endDate).tz(timezoneId).add(1,'d').endOf('d'));
};

const showCancelTrainingButtonFlag = (sessionTraining:boolean, isSessionCancellable: boolean, isUserAbleToEditAnyTraining: boolean, selfCreated: boolean) => {
  return sessionTraining && isSessionCancellable && (isUserAbleToEditAnyTraining || selfCreated);
};

const showSwapButtonFlag = (sessionTraining:boolean, permissionToSwapSession: boolean, isCurrentUserPSRole: boolean, canSessionBeSwappedFlag: boolean) => {
  return sessionTraining && (permissionToSwapSession || isCurrentUserPSRole) && canSessionBeSwappedFlag;
};

const showChangeSsButtonFlag = (sessionTraining:boolean, isSessionEditable: boolean, isSessionStatusUpcoming: boolean, isUserAbleToChangeSS: boolean) => {
  return sessionTraining && (isSessionEditable || isSessionStatusUpcoming) && isUserAbleToChangeSS;
};

const getActionButtonsConfig = (
  session: ISession,
  userProfile: IUserProfile,
  userRole: IUserRoleExtended,
  timezoneId: string
) => {
  const selfCreated = isSelfCreated(userProfile, session);

  const permissionToCancelSession = hasPermissionToCancelSession(userRole, selfCreated);
  const permissionToEditSession = hasPermissionToEditSession(userRole, selfCreated);
  const permissionToEditTrainingSession = hasPermissionToEditTrainingSession(userRole);
  const permissionToSwapSession = hasPermissionToSwapSession(userRole);

  const isSessionEditable = canSessionBeEdited(session, timezoneId, userRole);
  const isSessionCancellable = canSessionBeCancelled(session, userRole);
  const isSessionAvailableToJoin = canSessionBeParticipatedIn(session);

  const isUserAbleToEditAnyTraining = canCurrentUserEditAnyTraining(userRole);
  const isUserAbleToLeaveSession = canCurrentUserLeaveTheSession(userProfile, session, userRole);
  const isUserAbleToChangeSS = canCurrentUserChangeSS(userRole, session);

  const isUserAbleToEditNotes = canEditSessionSSNotes(session, userProfile, userRole);

  const showRestoreBtn = !!session.archived && permissionToCancelSession;
  const showCancelTrainingBtn = showCancelTrainingButtonFlag(!!session.training, isSessionCancellable, isUserAbleToEditAnyTraining, selfCreated);
  const showCancelSessionBtn = !session.training && isSessionCancellable && permissionToCancelSession;
  const showLeaveButton = isSessionEditable && isUserAbleToLeaveSession;
  const showParticipateBtn = isSessionAvailableToJoin && canCurrentUserJoinTheSession(userProfile, session.learners);
  const showSwapBtn = showSwapButtonFlag(!session.training, permissionToSwapSession, isCurrentUserPS(userRole), canSessionBeSwapped(session, isCurrentUserPS(userRole)));
  const showAcceptSwapBtn = !session.training && permissionToSwapSession && canSessionSwapBeAccepted(session);
  const showAcceptRequestBtn = !session.training && permissionToSwapSession && canSessionRequestBeAccepted(session);
  const showChangeSsBtn = showChangeSsButtonFlag(!session.training, isSessionEditable, session.status === SessionStatusType.UPCOMING, isUserAbleToChangeSS);
  const showUpdateSessionBtn = isUserAbleToEditNotes
    || isSessionEditable
    && (
      session.training
        ? isUserAbleToEditAnyTraining || permissionToEditTrainingSession && selfCreated
        : !showChangeSsBtn && permissionToEditSession
    );

  return {
    showRestoreBtn,
    showCancelTrainingBtn,
    showCancelSessionBtn,
    showLeaveButton,
    showParticipateBtn,
    showSwapBtn,
    showAcceptSwapBtn,
    showAcceptRequestBtn,
    showChangeSsBtn,
    showUpdateSessionBtn,
  };
};

const getUpcomingBeforeStart = (miliSec: number) => {
  const upcomingBeforeStartMins = moment.duration(miliSec);
  return upcomingBeforeStartMins.asMinutes();
};

const canAddToCalendarCheck = (session: ISession, isSessionBeEdited:boolean, currentUserIsGlobal:boolean) => {
  return !!session && isSessionBeEdited && !currentUserIsGlobal;
};

const disableLearnersSelectCheck = (session: ISession, isSessionBeEdited:boolean, isEditTrainees:boolean, isEditLearners:boolean) => {
  return !!session && session.training
  ? !(isSessionBeEdited && isEditTrainees)
  : !isEditLearners;
};

const getLearnerAttendanceStatus = (learnerStatus: SessionUserStatus | undefined) => {
  return learnerStatus ?? SessionUserStatus.MISSED;
};

const isSessionAndSimStatusMissed = (session: ISessionForList) => {
  const { status, simStatusForSession } = session;
  return status === SessionStatusType.MISSED && !simStatusForSession;
};

export {
  canCurrentUserJoinTheSession,
  canCurrentUserLeaveTheSession,
  canCurrentUserChangeSS,
  canSessionBeCancelled,
  canSessionBeEdited,
  canSessionBeLeft,
  canSessionBeSwapped,
  canSessionSwapBeAccepted,
  canSessionRequestBeAccepted,
  canSimSpecBeAssignedToSession,
  canGroupSessionBeCreated,
  canSessionReportBeViewed,
  canScheduleSessionForScenario,
  canCurrentUserLeaveLearnersEmpty,
  getActionButtonsConfig,
  canEditSessionSSNotes,
  manageSessionAttendance,
  isBeforeSimsSessionNextDayMidnight,
  getUpcomingBeforeStart,
  canAddToCalendarCheck,
  disableLearnersSelectCheck,
  getLearnerAttendanceStatus,
  isSessionAndSimStatusMissed,
};
