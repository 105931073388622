import { TableTheme } from 'src/components/EntityTable/EntityTable';
import styles from 'src/components/EntityTable/EntityTable.css';

export function getPageDataPrefix(pageDataPrefix: string | undefined) {
  return pageDataPrefix || '';
}

export function getFontSizeClass(fontSizeClass: string | undefined) {
  return fontSizeClass && styles[fontSizeClass];
}

export function getTableTheme(themeType: TableTheme | undefined) {
  let entityTableStyle = '';
  if (themeType === TableTheme.Cobalt) {
    entityTableStyle = styles.cobalt;
  } else if (themeType === TableTheme.Custom) {
    entityTableStyle = styles.customCobalt;
  }
  return entityTableStyle;
}
