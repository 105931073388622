import SessionType, { ContractSessionType } from '../session/interfaces/SessionType';
import { TLocaleId } from 'src/i18n';

export const DELIVERY_MODE: { [key in SessionType]: TLocaleId } = {
  [SessionType.NONE]: 'MursionPortal.DeliveryMode.OneToOne',
  [SessionType.ONE_TO_ONE]: 'MursionPortal.DeliveryMode.OneToOne',
  [SessionType.ONE_TO_MANY_REMOTE]: 'MursionPortal.DeliveryMode.OneToManyRemote',
  [SessionType.ONE_TO_MANY_LOCAL]: 'MursionPortal.DeliveryMode.OneToManyLocal',
  [SessionType.GROUP]: 'MursionPortal.DeliveryMode.Group',
};

export const CONTRACT_DELIVERY_MODE: { [key in ContractSessionType]: TLocaleId } = {
  [ContractSessionType.ONE_TO_ONE]: 'MursionPortal.DeliveryMode.PrivatePractice',
  [ContractSessionType.WORKSHOPS]: 'MursionPortal.DeliveryMode.Workshop',
};