import * as React from 'react';
import { Suspense } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { Location } from 'history';
import { withRouter } from 'react-router-dom';
import { WSConnectionStatus } from 'src/app/data/common/interfaces/WSConnectionStatus';
import AuthStatus from 'src/app/redux/modules/auth/interfaces/AuthStatus';
import selectors from 'src/app/redux/selectors';
import actions from 'src/app/redux/store/actions';
import { IAppState } from 'src/app/redux/store/reducers';
import { getRoutesConfig } from 'src/routing';
import styles from './App.css';
import UseRoleLink, { IUseRoleLinkParams } from 'src/hooks/useRoleLink';
import LoadingOverlay from 'src/components/LoadingOverlay/LoadingOverlay';
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary';
import { hot } from 'react-hot-loader/root';
import ROUTE_PATHS from 'src/routing/paths';
import getUrlParamValue, { updateUrlParamsValue } from 'src/app/data/common/utils/queryParamsAccessor';
import { IExternalLoginData, PARAM_LOGIN_EXTERNAL } from 'src/app/redux/modules/auth/interfaces/IAuthData';
import SsoCheck from 'src/layouts/common/SsoCheck';
import Cookies from 'js-cookie';
import LoadChunk from 'src/app/services/LoadChunk/LoadChunk';
import moment from 'moment-timezone';
import 'src/i18n/momentLocaleIndex';
import { momentUpdateLocale } from 'src/i18n/momentUpdateLocale';
import { getRoutesPath } from 'src/layouts/common/LearnerOnboarding/LearnerRoutes';

const SuccessPageRoute = LoadChunk(() => import('src/layouts/common/Static/SuccessPageRoute'));
const ErrorPageRoute = LoadChunk(() => import('src/layouts/common/Static/ErrorPageRoute'));
const ErrorModal = LoadChunk(() => import('src/components/ErrorModal'));
const CookiePopupContainer = LoadChunk(() => import('src/components/CookiePopup/CookiePopup'));
const RefreshSessionTimeoutModal = LoadChunk(() => import('src/components/RefreshSessionTimeoutModal'));
const EmailVerificationPage = LoadChunk(() => import('src/components/EmailVerificationPage/EmailVerificationPage'));

const LoginLoader = LoadChunk(() => import('src/layouts/unauthorized/Login/LoginLoader/LoginLoader'));

const AuthorizedRoot = LoadChunk(() => import('src/layouts/common/Root'));
const UnauthorizedRoot = LoadChunk(() => import('src/layouts/unauthorized/Root'));

const connector = connect(  (state: IAppState) => ({
    profileLoaded: selectors.profile.isUserProfileLoaded(state),
    status: selectors.auth.getAuthStatus(state),
    userRole: selectors.profile.getCurrentUserRole(state),
    wsConnectionStatus: selectors.ws.getConnectionStatus(state),
    language: selectors.profile.getUserLanguage(state),
    clientConfig: selectors.clientsConfig.getClientConfig(state),
    isUserOnboarded: selectors.profile.isUserOnboarded(state),
    companyConfig: selectors.companyConfig.getCompanyConfig(state),
  }),  {
    authenticationCheck: actions.auth.authenticationCheck,
    initWebSocketConnection: actions.ws.initWebSocketConnection,
    logOut: actions.auth.logOut,
    switchRole: actions.auth.switchRole,
    saveToken: actions.auth.saveToken,
    getToken: actions.auth.getToken,
  });
type PropsFromRedux = ConnectedProps<typeof connector>;


interface IAppContainerState {
  showIntro: boolean;
  loaded: boolean;
  authFailedPath?: Location;
  redirectUrl?: string;
}

type Props = RouteComponentProps<any> & PropsFromRedux & { ldClient?: any};

class App extends React.Component<Props, IAppContainerState> {

  public state: IAppContainerState = {
    showIntro: true,
    loaded: false,
  };

  public componentDidMount() {
    this.authenticationCheck();
  }

  public async componentDidUpdate(prevProps: Props) {
    // Initial auth token check has just finished
    // or Auth Error occurred during the loading process
    if (prevProps.status !== this.props.status
      && this.props.status === AuthStatus.NOT_AUTHENTICATED) {
      // User is not authenticated and should be redirected to the Login Page
      this.setState({
        loaded: true,
        authFailedPath: prevProps.status === AuthStatus.AUTHENTICATION_SUCCESSFUL
          ? this.props.location
          : undefined,
      });
    }

    if (prevProps.status !== this.props.status
      && this.props.status === AuthStatus.SWITCHING_ROLE) {
      this.setState({
        authFailedPath: undefined,
        loaded: false,
      });
    }

    if (prevProps.status === AuthStatus.AUTHENTICATION_SUCCESSFUL
      && this.props.status === AuthStatus.NOT_AUTHENTICATED
      && !this.isAssignPasswordLocation()
    ) {
      this.props.history.replace(ROUTE_PATHS.HOME, []);
    }

    if (prevProps.status !== this.props.status
      && this.props.status === AuthStatus.AUTHENTICATION_SUCCESSFUL) {
      this.setState({
        authFailedPath: undefined,
        loaded: false,
        showIntro: false,
      });

      if (this.props.wsConnectionStatus === WSConnectionStatus.WS_DISCONNECTED) {
        this.props.initWebSocketConnection();
      }
    }

    // User profile has been loaded
    if (!this.state.loaded
      && this.props.status === AuthStatus.AUTHENTICATION_SUCCESSFUL
      && (!prevProps.userRole || this.props.userRole && prevProps.userRole.id !== this.props.userRole.id)
      && this.props.profileLoaded) {
      this.setState({
        authFailedPath: undefined,
        loaded: true,
      });
    }
  }

  public render() {
    const language = this.props.language;
    moment.locale(language);
    momentUpdateLocale(language);
    localStorage.setItem('locale', language);

    if (!this.state.loaded) {
      return <LoadingOverlay active={true} spinner={true}/>;
    }

    return (
      // TODO: change background color for particular routs
      <ErrorBoundary>
        <Suspense fallback={<LoadingOverlay isFallback={true} active={true} spinner={true}/>}>
          <Switch>
            <Route path={ROUTE_PATHS.ERROR} component={ErrorPageRoute}/>
            <Route path={ROUTE_PATHS.SUCCESS} component={SuccessPageRoute}/>
            <Route path={ROUTE_PATHS.EMAIL_VERIFICATION} component={EmailVerificationPage}/>
            <Route>
              <ErrorModal/>
              <RefreshSessionTimeoutModal/>
              <div className={styles.appRoot} role={'application'}>
                {this.state.showIntro && <LoginLoader/>}
                {this.renderRoutes()}
              </div>
              <CookiePopupContainer/>
            </Route>
          </Switch>
        </Suspense>
      </ErrorBoundary>
    );
  }

  private isAssignPasswordLocation() {
    return this.props.location.pathname.match('/join');
  }

  private renderRoutes() {

    const { redirectUrl } = this.state;
    const { clientConfig, isUserOnboarded, companyConfig } = this.props;
    const externalLoginDataCookie = Cookies.get(PARAM_LOGIN_EXTERNAL);

    if (externalLoginDataCookie) {
      return <SsoCheck
        externalLoginData={JSON.parse(externalLoginDataCookie) as IExternalLoginData}
        redirectUrl={redirectUrl}
      />;
    }

    const loggedIn = this.props.status === AuthStatus.AUTHENTICATION_SUCCESSFUL;
    if (!loggedIn || this.isAssignPasswordLocation()) {
      const from = this.state.authFailedPath || this.props.location;

      return (
        <UnauthorizedRoot from={from}/>
      );
    }

    const userRole = this.props.userRole;

    if (!userRole) {
      return null;
    }
    
    const routesConfig: any = getRoutesConfig(userRole.id, getRoutesPath(clientConfig, isUserOnboarded, companyConfig), companyConfig?.nextGenScenario || false);

    if (!routesConfig) {
      return null;
    }

    return (
      <UseRoleLink>
        {
          ({ refreshing }: IUseRoleLinkParams) => (
            !refreshing && <AuthorizedRoot routesConfig={routesConfig}/>
          )
        }
      </UseRoleLink>
    );
  }

  private async authenticationCheck() {
    const { location, getToken, history } = this.props;
    const access_token = getUrlParamValue('access_token', location); // tslint:disable-line
    const refresh_token = getUrlParamValue('refresh_token', location); // tslint:disable-line
    const refresh_token_exp_duration = +getUrlParamValue('refresh_token_exp_duration', location); // tslint:disable-line
    const redirectUrl = getUrlParamValue('to', location);
    const stateId = getUrlParamValue('state_id', location);

    if (stateId) {
      try {
        const tokenInfo = await getToken(stateId);
        this.props.saveToken({
          access_token: tokenInfo.accessToken,
          refresh_token: tokenInfo.refreshToken,
          refresh_token_exp_duration: tokenInfo.refreshTokenExpirationDuration,
        });
      }
      catch (e) {
        // do nothing
      }
    } else if (!!access_token && !!refresh_token) {
      // remove the tokens from url
      updateUrlParamsValue({
        access_token: undefined,
        refresh_token: undefined,
      }, location, history);

      this.props.saveToken({
        access_token,
        refresh_token,
        refresh_token_exp_duration,
      });
    }

    if (redirectUrl) {
      this.setState({
        redirectUrl,
      });
    }
    this.props.authenticationCheck();
  }
}
export default hot(withRouter(connector(App)));