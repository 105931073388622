// When the application is running on a local development server (localhost),this URL will be used for redirection.
const defaultURLForLocalhost = 'sandbox3-simulation.mursion.com';
const autoSimPortalDomainIdentifier = 'simulation';
const currentPortalDomainIdentifier = 'portal';
const stageHostNameForOldPortal = 'staging.portal.mursion.com';
const stageHostNameForAutoSimPortal = 'staging-simulation.mursion.com';

export const redirectionOnPortal = () => {

    const currentHostname = window.location.hostname;
    let modifiedHostname = currentHostname.replace(currentPortalDomainIdentifier, autoSimPortalDomainIdentifier);

    if (currentHostname === 'localhost') {
        modifiedHostname = defaultURLForLocalhost;
    }
    if (currentHostname === stageHostNameForOldPortal) {
        modifiedHostname = stageHostNameForAutoSimPortal;
    }

    const redirectionUrl = `${window.location.protocol}//${modifiedHostname}/login`;

    window.open(redirectionUrl, '_self');
};
