import IUserRoleExtended from 'src/app/data/profile/interfaces/IUserRoleExtended';
import Permission from 'src/app/data/profile/interfaces/Permission';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import RoleID from 'src/app/data/common/interfaces/RoleID';

const hasPermissionToCancelSession = (currentUserRole: IUserRoleExtended | null, selfCreated: boolean): boolean => {

  if (!currentUserRole) {
    return false;
  }

  if (currentUserRole.permissions.includes(Permission.SESSIONS_DELETE)) {
    return true;
  }

  if (selfCreated) {
    return currentUserRole.permissions.includes(Permission.SESSIONS_SELF_CREATED_DELETE);
  }

  return false;
};

const hasPermissionToEditSession = (currentUserRole: IUserRoleExtended | null, selfCreated: boolean): boolean => {

  if (!currentUserRole) {
    return false;
  }

  if (currentUserRole.permissions.includes(Permission.SESSIONS_EDIT)) {
    return true;
  }

  if (selfCreated) {
    return currentUserRole.permissions.includes(Permission.SESSIONS_SELF_CREATED_EDIT);
  }

  return false;
};

const hasPermissionToEditTrainingSession = (currentUserRole: IUserRoleExtended | null): boolean => {

  if (!currentUserRole) {
    return false;
  }

  return currentUserRole.permissions.includes(Permission.TRAINING_SESSIONS_EDIT);


};

const hasPermissionToSwapSession = (currentUserRole: IUserRoleExtended | null): boolean => {
  return !!currentUserRole && currentUserRole.permissions.includes(Permission.SWAP);
};

const hasPermissionToAssignSimSpecialist = (currentUserRole: IUserRoleExtended | null): boolean => {
  return !!currentUserRole && currentUserRole.permissions.includes(Permission.SESSIONS_SS_EDIT);
};

const hasPermissionToEditSelfCreatedSession = (currentUserRole: IUserRoleExtended | null): boolean => {
  return !!currentUserRole && currentUserRole.permissions.includes(Permission.SESSIONS_SELF_CREATED_EDIT);
};

const hasPermissionToCreateSession = (currentUserRole: IUserRoleExtended | null, sessionType: SessionType | ''): boolean => {
  if (!currentUserRole || !sessionType) {
    return false;
  }

  if (currentUserRole.permissions.includes(Permission.SESSIONS_CREATE)) {
    return true;
  }

  if (sessionType === SessionType.ONE_TO_ONE) {
    return currentUserRole.permissions.includes(Permission.SESSIONS_ONE_TO_ONE_CREATE);
  }

  return false;
};

const hasPermissionToCreateEmergencySession = (currentUserRole: IUserRoleExtended | null): boolean => {
  if (!currentUserRole) {
    return false;
  }

  return currentUserRole.permissions.includes(Permission.SESSION_EMERGENCY_CREATE);
};

const hasPermissionToReviewSession = (currentUserRole: IUserRoleExtended | null): boolean => {
  return !!currentUserRole && currentUserRole.permissions.includes(Permission.SESSIONS_REVIEW);
};

const hasPermissionToReadSessionHistory = (currentUserRole: IUserRoleExtended | null): boolean => {
  return !!currentUserRole && currentUserRole.permissions.includes(Permission.SESSIONS_HISTORY_READ);
};

const hasPermissionToReadSessionSmartMetrics = (currentUserRole: IUserRoleExtended | null): boolean => {
  return !!currentUserRole && currentUserRole.permissions.includes(Permission.SESSIONS_SMART_METRICS_READ);
};

const hasExtendedAnalyticsPermissions = (currentUserRole: IUserRoleExtended | null): boolean => {
  return !!currentUserRole && currentUserRole.id === RoleID.DEV_ADMIN;
};

const hasPermissionToReadSessionReport = (currentUserRole: IUserRoleExtended | null): boolean => {
  return !!currentUserRole && currentUserRole.permissions.includes(Permission.SESSION_REPORT_READ);
};

const hasPermissionToReadCancelledSessions = (currentUserRole: IUserRoleExtended | null): boolean => {
  return !!currentUserRole && currentUserRole.permissions.includes(Permission.SESSIONS_CANCELLED_READ);
};

const hasPermissionToReadSSSessionNotes = (currentUserRole: IUserRoleExtended | null): boolean => {
  return !!currentUserRole && currentUserRole.permissions.includes(Permission.SS_SESSION_NOTES_READ);
};

const hasPermissionToEditSSSessionNotes = (currentUserRole: IUserRoleExtended | null): boolean => {
  return !!currentUserRole && currentUserRole.permissions.includes(Permission.SS_SESSION_NOTES_EDIT);
};

export {
  hasPermissionToAssignSimSpecialist,
  hasPermissionToCancelSession,
  hasPermissionToCreateSession,
  hasPermissionToEditSession,
  hasPermissionToSwapSession,
  hasPermissionToReviewSession,
  hasPermissionToReadSessionHistory,
  hasPermissionToReadSessionSmartMetrics,
  hasPermissionToReadSessionReport,
  hasPermissionToCreateEmergencySession,
  hasPermissionToReadCancelledSessions,
  hasPermissionToReadSSSessionNotes,
  hasPermissionToEditSSSessionNotes,
  hasPermissionToEditSelfCreatedSession,
  hasExtendedAnalyticsPermissions,
  hasPermissionToEditTrainingSession,
};
