import * as queryString from 'querystring';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import IEntityQuery from 'src/app/data/common/interfaces/IEntityQuery';

export default function stringifyEntityQuery(query?: IEntityQuery | null): string {
  return query ? `?${queryString.stringify(_omitBy(query, _isNil))}` : '';
}

export function stringifyQuery(query?: { [key: string]: string | number } | object | null): string {
  return query ? `?${queryString.stringify(_omitBy(query, _isNil))}` : '';
}
