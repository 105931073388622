export enum FacilitatorLearnersListType {
  ALL = 'ALL',
  FACILITATOR_TEAM = 'FACILITATOR_TEAM',
}

export const facilitatorLearnersListTypeLabels = {
  [FacilitatorLearnersListType.ALL]: {
    label: 'MursionPortal.Label.ClientLevel',
  },
  [FacilitatorLearnersListType.FACILITATOR_TEAM]: {
    label: 'MursionPortal.Label.TeamLevel',
  }
};
