import getUrlParamValue, { getUrlParamRole, updateUrlParamsValue } from 'src/app/data/common/utils/queryParamsAccessor';
import { PARAM_SORTBY, PARAM_SORTBY_ORDER } from 'src/components/SortBy';
import IListPageData from 'src/app/data/common/interfaces/IListPageData';
import { PARAM_SEARCH } from 'src/components/SearchField';
import {
  DEFAULT_SIZE_PER_PAGE,
  PARAM_CONTRACT_STATUS,
  PARAM_PAGE,
  PARAM_PROJECT_STATUS,
  PARAM_SIZE
} from 'src/components/Pagination/Pagination';
import { ProjectStatusRequestParam } from 'src/app/data/projects/interfaces/IProject';
import RoleID from 'src/app/data/common/interfaces/RoleID';

export const DEFAULT_LIST_PAGE_DATA: IListPageData = {
  asc: true,
  order: 'name',
  page: 0,
  size: 5,
  archive: 'active',
};

export const DEFAULT_TABLE_PAGE_DATA: IListPageData = {
  asc: true,
  order: 'name',
  page: 0,
  size: DEFAULT_SIZE_PER_PAGE,
  archive: 'active',
};

export function updatePageDataUrl(pageData: IListPageData, location: any, history: any, prefix: string = '') {
  updateUrlParamsValue({
    [prefix + PARAM_PAGE]: pageData.page,
    [prefix + PARAM_SIZE]: pageData.size,
    [prefix + PARAM_SORTBY_ORDER]: pageData.sortByOrder,
  }, location, history);
}

export default function getListPageDataFromUrl(location: any, isList: boolean, defaultOverrides?: any, prefix: string = ''): IListPageData {
  const sortOrder = getUrlParamValue(prefix + PARAM_SORTBY_ORDER, location);
  const pageStr = getUrlParamValue(prefix + PARAM_PAGE, location);
  const sizeStr = getUrlParamValue(prefix + PARAM_SIZE, location);
  const generationType = getUrlParamValue('generationType', location);

  let archiveStr: any = getUrlParamValue('archive', location);
  if (archiveStr !== 'archived' && archiveStr !== 'active' && archiveStr !== 'all') {
    archiveStr = defaultOverrides?.archive || 'active';
  }

  const projectStatusStr: any =
    getUrlParamValue(prefix + PARAM_PROJECT_STATUS, location) as ProjectStatusRequestParam || defaultOverrides?.project_status;

    const contractStatusStr: any =
    getUrlParamValue(prefix + PARAM_CONTRACT_STATUS, location) as ProjectStatusRequestParam || defaultOverrides?.contract_status || 'active';


  let defaultData = isList ? DEFAULT_LIST_PAGE_DATA : DEFAULT_TABLE_PAGE_DATA;

  if (defaultOverrides) {
    defaultData = {
      ...defaultData,
      ...defaultOverrides
    };
  }

  return {
    asc: sortOrder === '' ? defaultData.asc : sortOrder === 'asc',
    filter: getUrlParamValue(prefix + PARAM_SEARCH, location) || defaultData.filter,
    order: getUrlParamValue(prefix + PARAM_SORTBY, location) || defaultData.order,
    sortByOrder: getUrlParamValue(prefix + PARAM_SORTBY_ORDER, location) || undefined,
    roleId: getUrlParamRole('roleId', location),
    page: pageStr ? +pageStr : defaultData.page,
    size: sizeStr ? +sizeStr : defaultData.size,
    archive: archiveStr,
    ...(generationType && {generationType}),
    status: projectStatusStr || contractStatusStr,
  };
}

export function getDataByIdAndName(ItemId: string | undefined, ItemName: string | undefined) {
  return !!ItemId ? [{ id: ItemId, name: ItemName || '' }] : null;
}

export function getDataById(ItemId: string | undefined) {
  return !!ItemId ? [{ id: ItemId, name: '' }] : null;
}

export function getRoleId(roleId: RoleID | undefined) {
  return roleId ? [roleId] : null;
}

export function getUrlParamData(key: string) {
  const queryValue = window.location.search;
  const urlParams = new URLSearchParams(queryValue);
  return urlParams.get(key);
}

export function getTeamData(teamData: string | undefined) {
  return teamData || '';
}