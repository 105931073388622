import { IReportScheduleAndSendData } from 'src/app/data/dashboardReport/interfaces/IDashboardReport';
import { REPORT_PAGE_ID } from 'src/app/data/dashboardReport/interfaces/IReportPageId';
import {ReportFrequencyType, ReportSessionType} from 'src/app/data/dashboardReport/interfaces/ReportFiltersType';
import { intl } from 'src/i18n/createIntl';
import _cloneDeep from 'lodash/cloneDeep';

export const mappedReportFilterResponse = (data: IReportScheduleAndSendData) => {
    const { recipients, reportFrequency, reportFrequencyType, startDate } = data;
    const defaultResponse = { label: '', value: '' };
    return {
        getReportFrequency: reportFrequency ? { label: reportFrequency, value: reportFrequency } : defaultResponse,
        getReportFrequencyType: reportFrequencyType ? { label: reportFrequencyType, value: reportFrequencyType } : defaultResponse,
        getStartDate: startDate ? new Date(startDate) : new Date(),
        getRecipientsEmail: recipients && Array.isArray(recipients) ? recipients.map(email => ({id: email, name: email})) : [],
    };
};

const SELECTOR_FILTERS_SESSION = [
    'CONTRACT_SUMMARY',
    'CONTRACT_ITEMS',
    'CONTRACT_PROJECT',
    'CONTRACT_SCENARIO',
    'LEARNER_ACTIVITY',
    'LEARNER_SIMULATIONS',
    'SIMULATION_SUMMARIES'
];

export const fetchSessionIncludedSelector = (getPageId: string | null) => {
    if(!getPageId){
        return false;
    }
    const getReportType = getPageId?.replace(" ", "");
    const getReportPageId = REPORT_PAGE_ID[getReportType];
   return  (SELECTOR_FILTERS_SESSION.includes(getReportPageId));
};

const ReportFrequency = {
    [ReportFrequencyType.DAILY] : intl().formatMessage({id: 'Dashboard.Report.ReportFrequencyOption.Daily'}),
    [ReportFrequencyType.WEEKLY] : intl().formatMessage({id: 'Dashboard.Report.ReportFrequencyOption.Weekly'}),
    [ReportFrequencyType.MONTHLY] : intl().formatMessage({id: 'Dashboard.Report.ReportFrequencyOption.Monthly'}),
};

export const ReportSession = {
    [ReportSessionType.ALL] : intl().formatMessage({id: 'Dashboard.Report.ReportSessionOption.AllDays'}),
    [ReportSessionType.PREVIOUS_DAY] : intl().formatMessage({id: 'Dashboard.Report.ReportSessionOption.PreviousDay'}),
    [ReportSessionType.PREVIOUS_7_DAYS] : intl().formatMessage({id: 'Dashboard.Report.ReportSessionOption.Previous7Days'}),
    [ReportSessionType.CURRENT_DAY] : intl().formatMessage({id: 'Dashboard.Report.ReportSessionOption.CurrentSessions'}),
    [ReportSessionType.NEXT_7_DAYS] : intl().formatMessage({id: 'Dashboard.Report.ReportSessionOption.Next7Days'}),
    [ReportSessionType.MONTH_TO_DATE] : intl().formatMessage({id: 'Dashboard.Report.ReportSessionOption.MonthToDate'}),
    [ReportSessionType.PREVIOUS_FULL_MONTH] : intl().formatMessage({id: 'Dashboard.Report.ReportSessionOption.PreviousFullMonth'}),
    [ReportSessionType.PREVIOUS_30_DAYS] : intl().formatMessage({id: 'Dashboard.Report.ReportSessionOption.Previous30Days'}),
};

const optionsList = [
    {
        label: ReportFrequency.DAILY,
        children: [
            ReportSession.PREVIOUS_DAY,
            ReportSession.PREVIOUS_7_DAYS,
            ReportSession.CURRENT_DAY,
            ReportSession.NEXT_7_DAYS,
        ]
    },
    {
        label: ReportFrequency.WEEKLY,
        children: [
            ReportSession.PREVIOUS_7_DAYS,
            ReportSession.NEXT_7_DAYS,
            ReportSession.MONTH_TO_DATE,
        ]
    },
    {
        label: ReportFrequency.MONTHLY,
        children: [
            ReportSession.PREVIOUS_FULL_MONTH,
            ReportSession.PREVIOUS_30_DAYS,
        ]
    }
];
 
export const fetchFrequencyOptions = () => optionsList.map(({label}) => ({label, value: label})) || [];

export const fetchSessionOptions = (selectedFrequency: string, isPageIdLearnerActivity:boolean) => {
    const selctedOption =  _cloneDeep(optionsList).find(({label}) => label === selectedFrequency);
    if(isPageIdLearnerActivity && selctedOption){
        selctedOption.children = [...[ReportSession.ALL], ...selctedOption?.children];
    }
    return selctedOption?.children.map(label => ({
        label,
        value: Object.keys(ReportSession).find(key => ReportSession[key] === label) || '',
    })) || [];
};
