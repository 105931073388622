import { ICustomData } from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/Components/NextGenScenarioDetails/ScenarioContentContainer/ScenarioContentContainer';
import IScenario, { IScenarioAttachment, IScenarioDraftUpdate, IScenarioPlanningUpdate } from 'src/app/data/projects/interfaces/IScenario';
import { ILibraryInfoMap } from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/Components/NextGenScenarioDetails/LibraryInfoContainer/LibraryInfoContainer';
import { IFilterItem } from 'src/layouts/mursion/ScenarioBankContainer/components/ItemBank/ItemBankListView/filter/ItemBankFilters';
import SessionType from 'src/app/data/session/interfaces/SessionType';
import { AvatarRestrictionType } from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/Components/NextGenScenarioAvatarRestriction/NextGenScenarioAvatarRestriction';
import { ScenarioCompositionType } from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/Components/ScenarioCompositionSelector/ScenarioCompositionSelector';
import { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';
import IEnvironment from 'src/app/data/client/interfaces/IEnvironment';
import IAvatar from 'src/app/data/client/interfaces/IAvatar';
import { IAssetProjectBase } from 'src/app/data/client/interfaces/IAsset';

export interface IScenarioContentItemsInfo {
  id: string;
  title: string;
  customDescription: string | null | undefined;
  description?: string | null;
}

export interface ISkills {
  buildingBlockWithCustomDetailsDTO: IFilterItem;
  mappedObject: {
    domainMappedToSkillList: IFilterItem[];
  };
}

interface INextGenScenarioBase {
  coverStory: string;
  whyMatters: string;
  outcome: ICustomData;
  summary: string;
  pathwayId: string;
  thumbnailImageDescription: string;
  scenarioThumbnailImageDescription: string;
  coverImageDescription: string;
  coverImage?: string | null;
  thumbnailImage?: string | null;
  coverSummary: string;
  pathwayDTO: ICustomSelectPathway;
  coverImageUrl?: string | null;
  thumbnailImageUrl?: string | null;
  skill: IFilterItem[] | ISkills[];
  avatar: IFilterItem[];
  researchSummary: IScenarioContentItemsInfo;
  learnerInstruction: IScenarioContentItemsInfo;
  pathways: IFilterItem[];
  versionNumber?: string | null;
  versionDesc?: string | null;
}

export interface INextGenScenario extends INextGenScenarioBase, IScenario {
  vignette?: string | null;
  attachments?: IScenarioAttachment[];
  standardized?: boolean;
  scenarioVersion: ScenarioVersion;
  avatars?: IAvatar[];
  environment?: IEnvironment;
  assetProject?: IAssetProjectBase | null;
  assetSettingsAliases?: {[assetId: string]: string};
  deliveryMode: SessionType;
  goals?: string[];
  sessionSize: number;
  sessionLength: number;
  simCheckListUrl?: string;
  avatarRestriction?: AvatarRestrictionType[];
  avatarComposition?: ScenarioCompositionType;
  scenarioId?: string;
  customizedName?: string;
  prepGuide?: string;
  prepGuideCustomized?: string;
  virtualSimOnly?: boolean;
}

export interface INextGenExtraData {
  skill?: IFilterItem[] | ISkills[];
  whyMatters?: string;
  researchSummary?: IScenarioContentItemsInfo;
}

export interface INextGenScenarioTemplateDraftUpdate extends IScenarioDraftUpdate, INextGenExtraData {
  avatarRestriction?: AvatarRestrictionType[];
  avatarComposition?: ScenarioCompositionType;
  scenarioVersion?: ScenarioVersion;
  environment?: IEnvironment;
}

export interface ICustomSelectPathway<T = string, K = string> {
  id: T;
  title: K;
}

type FileInputValue = File | File[] | null;

export interface IDeliveryDetailsUpdate {
  deliveryMode: SessionType;
  assetProjectId?: string;
  sessionLength: number;
  sessionSize: number;
  simCheckListUrl: string;
  assetSettingsAliases?: { [assetId: string]: string };
  avatarRestriction?: AvatarRestrictionType[];
  avatarComposition?: ScenarioCompositionType;
  avatarIds?: string[];
  environmentId?: string;
  scenarioVersion?: ScenarioVersion;
  virtualSimOnly?: boolean;
}

export interface INextGenScenarioInfo {
  clientName: string;
  scenarioId: string;
  projectName: string;
  scenarioStatus: string;
  bankScenarioModified: boolean;
  clientId: string;
  projectId: string;
}

export interface INextGenChildAndOrphanScenario {
  childOrphanScenarioList: INextGenScenarioInfo[];
  scenarioName: string;
}

export interface IDuplicateScenarioPlanning extends IScenarioPlanningUpdate {
  targetClientId: string;
  targetProjectId: string;
}

export function generateFormDataForPartsLibraryInfoData(libraryInfoMap: ILibraryInfoMap, addCoverImage?: FileInputValue, addThumbnailImage?: FileInputValue): FormData {
  const result = new FormData();

  if (libraryInfoMap) {
    const { libraryInfo: { summary: { text }, ...restData } } = libraryInfoMap;
    const updatedLibraryInfoMap = {
      libraryInfo: {
        ...restData,
        summary: text,
      },
    };
    
    Object.keys(updatedLibraryInfoMap).forEach(key => {
      result.append(key, new Blob([JSON.stringify(updatedLibraryInfoMap[key])], {
        type: 'application/json'
      }), key);
    });
  }

  if (addCoverImage) {
    if (Array.isArray(addCoverImage)) {
      addCoverImage.forEach(file => result.append('coverImage', file, file.name));
    } else {
      result.append('coverImage', addCoverImage, addCoverImage.name);
    }
  }

  if (addThumbnailImage) {
    if (Array.isArray(addThumbnailImage)) {
      addThumbnailImage.forEach(file => result.append('thumbnailImage', file, file.name));
    } else {
      result.append('thumbnailImage', addThumbnailImage, addThumbnailImage.name);
    }
  }

  return result;
}

export function getScenarioSettingValue (value: number | null){
  return value === 0 ? null : value;
}