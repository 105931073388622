import { intl } from 'src/i18n/createIntl';

const getUserName = (user: Partial<{ firstName: string | null, lastName: string | null, email: string | null }> | null, short?: boolean): string => {
  if (!user) {
    return intl().formatMessage({id: 'MursionPortal.NotAvailable' });
  }

  if (user.firstName && user.lastName) {
    const userLastName = short ? `${user.lastName[0]}.` : user.lastName;
    return `${user.firstName} ${userLastName}`;
  }

  return user.firstName || user.lastName || user.email || intl().formatMessage({id: 'MursionPortal.NotAvailable' });
};

export default getUserName;
