import IUserRole from 'src/app/data/common/interfaces/IUserRole';
import RoleID from 'src/app/data/common/interfaces/RoleID';
import IUserProfile from 'src/app/data/profile/interfaces/IUserProfile';

const globalUserRoles = [
  RoleID.IT_MANAGER,
  RoleID.SUPPORT_ADMIN,
  RoleID.BUSINESS_ADMIN,
  RoleID.DEV_ADMIN,
];

const isGlobalUser = (userProfile: IUserProfile | null) => {
  return !!userProfile && globalUserRoles.includes(userProfile.currentRoleId);
};

export const isGlobalUserRole = (userRole: IUserRole | null) => {
  return !!userRole && globalUserRoles.includes(userRole.id);
};

export default isGlobalUser;
