import _orderBy from 'lodash/orderBy';
import moment, { Moment } from 'moment-timezone';
import { EventType } from 'src/app/data/calendar/interfaces/EventType';
import ICalendarEvent from 'src/app/data/calendar/interfaces/ICalendarEvent';
import ITimeBlock from 'src/app/data/calendar/interfaces/ITimeBlock';
import IClientConfig from 'src/app/data/clientConfig/interfaces/IClientConfig';
import getEventDurationGap from 'src/app/data/common/getEventDurationGap';
import { round, roundMoment } from 'src/app/data/common/utils/dateUtil';
import ICompanyConfig from 'src/app/data/companyConfig/interfaces/ICompanyConfig';

export const splitToDailyEvents = <T extends { startDate: number, endDate: number; [key: string]: any }>(event: T, tzId: string) => {
  const events: T[] = [];
  const { startDate, endDate } = event;
  const mStart = moment.tz(startDate, tzId);
  const mEnd = moment.tz(endDate, tzId);

  if (mStart.isSame(mEnd, 'd') || mStart.clone().add(1, 'd').startOf('d').isSame(mEnd)) {
    return [event];
  }

  for (
    const tmpStart = mStart.clone();
    tmpStart.isSameOrBefore(mEnd, 'd');
    tmpStart.add(1, 'd').startOf('d')
  ) {
    const isLastDay = tmpStart.isSame(mEnd, 'd');

    events.push({
      ...event,
      endDate: isLastDay
        ? endDate
        : tmpStart.clone().add(1, 'd').startOf('d').valueOf(),
      startDate: tmpStart.valueOf(),
    });
  }

  return events;
};

export const checkUserBusy = (
  userId: string,
  startTime: number,
  endTime: number,
  events: ICalendarEvent[],
  isCurrentUser: boolean = false): boolean => {

  const eventTypesToCheck = [
    EventType.SCHEDULED_SESSION,
    EventType.TRAINING,
    EventType.COMPANY_EVENT,
  ];
  return events
    .filter(event => eventTypesToCheck.includes(event.type))
    .filter(event => {

      // suggests that if user received information about the event
      // than he is a participant
      if (isCurrentUser && event.type === EventType.COMPANY_EVENT) {
        return true;
      }

      let userIdsToCheck: string[] = [];

      if (event.learners) {
        userIdsToCheck = userIdsToCheck.concat(event.learners.map(user => user.id || ''));
      }

      if (event.members) {
        userIdsToCheck = userIdsToCheck.concat(event.members);
      }

      if (event.simspecialist) {
        userIdsToCheck.push(event.simspecialist.id || '');
      }

      return userIdsToCheck.includes(userId);
    })
    .some(event => {
      return startTime < event.endDate && endTime > event.startDate;
    });
};

export const isEventPast = (event: ICalendarEvent | null, tzId: string) => {
  if (!event) {
    return false;
  }

  return moment.tz(event.startDate, tzId).isBefore(moment.tz(tzId));
};

const calcTimeBlockEnd = (start: Moment, sessionLength: number) => {
  return start.clone().add(sessionLength, 'ms');
};

export const getAvailablePeriods = <T extends { startDate: number, endDate: number; }>(
  date: moment.Moment,
  timeblocks: T[],
  sessionLength: number,
  step: number,
  tzId: string,
): ITimeBlock[] => {

  const startOfDay = date.clone().startOf('d').valueOf();
  const endOfDay = date.clone().add(1, 'd').startOf('d').valueOf();

  const sortedTimeBlock = [...timeblocks].sort((tbA, tbB) => tbA.startDate - tbB.startDate);
  return sortedTimeBlock.reduce((accum: ITimeBlock[], tb: T) => {


      if (tb.endDate < startOfDay || tb.startDate > endOfDay) {
        return accum;
      }

      const stepMinutes = moment.duration(step, 'ms').asMinutes();
      const remainder = moment.tz(tb.startDate, tzId).minute() % stepMinutes !== 0 ? stepMinutes - (moment.tz(tb.startDate, tzId).minute() % stepMinutes) : 0;
      tb.startDate = moment.tz(tb.startDate, tzId).add(remainder, 'minutes').startOf('m').valueOf();
      const periods: ITimeBlock[] = [];

      for (let i = Math.max(tb.startDate, startOfDay); i < Math.min(endOfDay, tb.endDate); i += step) {

        const newPeriod = {
          start: moment.tz(i, tzId),
          end: moment.tz(i + sessionLength, tzId),
          index: accum.length + periods.length,
        };

        const isExisting =
          accum.some((item) => item.start.isSame(newPeriod.start))
          || periods.some((item) => item.start.isSame(newPeriod.start));

        if (!isExisting) {
          periods.push(newPeriod);
        }
      }
      return accum.concat(periods);
    }, []);
};

export const getAvailabilityTimeBlocks = <T extends { startDate: number, endDate: number; }>(
  date: moment.Moment,
  availabilities: T[],
  sessionLength: number,
  clientConfig: IClientConfig | null,
  companyConfig: ICompanyConfig | null,
  tzId: string): ITimeBlock[] => {
  availabilities = _orderBy(availabilities, 'startDate', 'asc');

  if (!availabilities.length) {
    return [];
  }

  const result: ITimeBlock[] = [];
  const availabilityDurationGap = getEventDurationGap(EventType.AVAILABILITY, companyConfig);

  const disabledMsToStart = companyConfig ? companyConfig.session.scheduleDisabledTime : 0;

  const gapDuration = moment.duration(availabilityDurationGap, 'ms');
  const nearestTime = round(
    moment.tz(tzId).add(disabledMsToStart, 'ms').valueOf(),
    gapDuration.asMilliseconds(),
    'ceil',
    tzId
  );

  const startTime = round(
    Math.max(availabilities[0].startDate, nearestTime.valueOf()), gapDuration.asMilliseconds(),
    'ceil',
    tzId
  );
  const endTime = roundMoment(
    moment.tz(_orderBy(availabilities, 'endDate', 'desc')[0].endDate, tzId),
    'm',
    'ceil',
  );

  const now = moment.tz(tzId);

  let index = 0;

  for (const curTime = moment.tz(startTime, tzId);
       !calcTimeBlockEnd(curTime, sessionLength).isAfter(endTime);
       curTime.add(availabilityDurationGap, 'ms')
  ) {
    const start = curTime.clone();
    const end = calcTimeBlockEnd(start, sessionLength);
    const startOfToday = date.clone().startOf('day');
    const startOfTomorrow = startOfToday.clone().add(1, 'day');

    if (start.isBefore(startOfToday) || end.isAfter(startOfTomorrow)
      || !!clientConfig && !!clientConfig.daysBeforeSessionStart
      && start.diff(now, 'ms') < moment.duration(clientConfig.daysBeforeSessionStart, 'd').asMilliseconds()) {
      continue;
    }
    const availabilityIndex = availabilities.findIndex((av: T) => {
      return !moment.tz(av.startDate, tzId).isAfter(start)
        && !roundMoment(moment.tz(av.endDate, tzId), 'm', 'ceil').isBefore(end);
    });

    if (availabilityIndex > -1) {
      const timeBlock: ITimeBlock = {
        end,
        index,
        start,
      };
      result.push(timeBlock);
      index++;
    }
  }
  return result;
};


export const mergeEvents = <T extends { startDate: number, endDate: number }>(events: T[]) => {

  if (!(events && events.length)) {
    return [];
  }

  // Stack of final ranges
  const stack: T[] = [];

  // Sort according to start value
  events.sort((a, b) => a.startDate - b.startDate);

  // Add first range to stack
  stack.push(events[0]);

  events.slice(1).forEach((range, i) => {
    const top = stack[stack.length - 1];

    if (top.endDate < range.startDate) {

      // No overlap, push range onto stack
      stack.push(range);
    } else if (top.endDate < range.endDate) {

      // Update previous range
      top.endDate = range.endDate;
    }
  });

  return stack;
};

export const getPeriodInsideDay = (
  date: moment.Moment,
  tzId: string,
  planning: { startDate: number, endDate: number }
) => {

  const dateLocal = date.clone().tz(tzId);
  const dayStart = dateLocal.clone().startOf('d');
  const dayEnd = dateLocal.clone().endOf('d');
  const now = moment().tz(tzId);
  const planningStartLocal = moment(planning.startDate).tz(tzId);
  const planningEndLocal = moment(planning.endDate).tz(tzId);

  const isDayInsidePlanning = dateLocal.isBetween(planningStartLocal, planningEndLocal, 'd', '[]');
  const isDayBeforeNow = dateLocal.isBefore(now, 'd');

  if (isDayBeforeNow || !isDayInsidePlanning) {
    return null;
  }

  const from = dayStart.isSame(planningStartLocal, 'd')
    ? planningStartLocal.valueOf()
    : dayStart.valueOf();
  const to = dayEnd.isSame(planningEndLocal, 'd')
    ? planningEndLocal.valueOf()
    : dayEnd.valueOf();

  return { from, to };
};

// debugging utils ===========================================================================================

export const debugEvent = (tzId: string) => <T extends { startDate: number, endDate: number, type: EventType }>(event: T) => {
  return {
    start: moment.tz(event.startDate, tzId).format('D HH:mm Z'),
    end: moment.tz(event.endDate, tzId).format('D HH:mm Z'),
    type: event.type,
  };
};

export const debugTimeBlock = (tzId: string) => <T extends { start: Moment, end: Moment }>(event: T) => {
  return {
    start: event.start.format('D HH:mm:ss'),
    end: event.end.format('D HH:mm:ss'),
  };
};

export const cutAvailabilites = <T extends { startDate: number, endDate: number }>(availabilities: T[], from: number, to: number) => {
  availabilities.forEach((availability) => {
    if (availability.startDate < from) {
      availability.startDate = from;
    }
    if (availability.endDate > to) {
      availability.endDate = to;
    }
  });
  return availabilities;
};

export const getClientId = (currentClientId: string, selectedClientIds: string[] | null) => {
  return currentClientId ? [currentClientId] : selectedClientIds;
};

export const getProjectId = (currentProjectId: string, selectedProjectIds: string[] | null) => {
  return currentProjectId ? [currentProjectId] : selectedProjectIds;
};

export const getScenarioId = (currentScenarioId: string, selectedScenarioIds: string[] | null) => {
  return currentScenarioId ? [currentScenarioId] : selectedScenarioIds;
};