import RoleID from '../common/interfaces/RoleID';
import _has from 'lodash/has';
import { FacilitatorLearnersListType } from 'src/app/data/clientConfig/interfaces/FacilitatorLearnersList';

const hasUserBaOrSa = {
    [RoleID.BUSINESS_ADMIN]: {
        id: RoleID.BUSINESS_ADMIN,
        name: 'Business Admin',
        shortName: 'Business Admin',
      },
      [RoleID.SUPPORT_ADMIN]: {
        id: RoleID.SUPPORT_ADMIN,
        name: 'Support Admin',
        shortName: 'Support Admin',
      },
};

const hasUserBaOrSaOrOps = {
    ...hasUserBaOrSa,
    [RoleID.OPERATIONS]: {
        id: RoleID.OPERATIONS,
        name: 'Operations',
        shortName: 'Operations',
      },
};

const hasUserBaOrSaOrOpsOrPs = {
    ...hasUserBaOrSaOrOps,
    [RoleID.PROF_SERVICE]: {
        id: RoleID.PROF_SERVICE,
        name: 'Professional Service',
        shortName: 'Prof. Services',
    },
};
const hasUserBaOrSaOrOpsOrPsOrPm = {
    ...hasUserBaOrSaOrOpsOrPs,
    [RoleID.ACC_MANAGER]: {
        id: RoleID.ACC_MANAGER,
        name: 'Project Manager',
        shortName: 'Project Manager',
      },
};

const hasUserBaOrSaOrOpsOrPsOrPmOrAo = {
    ...hasUserBaOrSaOrOpsOrPsOrPm,
    [RoleID.BUYER]: {
        id: RoleID.BUYER,
        name: 'Account Owner',
        shortName: 'Acc Owner',
      },
};

const hasUserBaOrSaOrOpsOrPsOrPmOrAoOrF = {
    ...hasUserBaOrSaOrOpsOrPsOrPmOrAo,
    [RoleID.FACILITATOR]: {
        id: RoleID.FACILITATOR,
        name: 'Facilitator',
        shortName: 'Facilitator',
    },
};

const checkOperations = (createdUserRole: string, currentUserRole: string) => !!(createdUserRole === RoleID.OPERATIONS && (_has(hasUserBaOrSaOrOps, currentUserRole)));

const checkProfService = (createdUserRole: string, currentUserRole: string) => !!(createdUserRole === RoleID.PROF_SERVICE && (_has(hasUserBaOrSaOrOpsOrPs, currentUserRole)));

const checkAccountManager = (createdUserRole: string, currentUserRole: string) => !!(createdUserRole === RoleID.ACC_MANAGER && (_has(hasUserBaOrSaOrOpsOrPsOrPm, currentUserRole)));

const checkBuyer = (createdUserRole: string, currentUserRole: string) => !!(createdUserRole === RoleID.BUYER && (_has(hasUserBaOrSaOrOpsOrPsOrPmOrAo, currentUserRole)));

const hasPermissionToUpdateDashboardReport = (currentUserRole: string | undefined, createdUserRole: string, facilitatorLearnersListType?: string | null | undefined): boolean => {
    if(currentUserRole && createdUserRole){
        if(
            _has(hasUserBaOrSa, currentUserRole) || 
            checkOperations(createdUserRole, currentUserRole) ||
            checkProfService(createdUserRole, currentUserRole) ||
            checkAccountManager(createdUserRole, currentUserRole) ||
            checkBuyer(createdUserRole, currentUserRole) 
        ){
            return true;
        }
        if(createdUserRole === RoleID.FACILITATOR && (_has(hasUserBaOrSaOrOpsOrPsOrPmOrAoOrF, currentUserRole))){
            return !(!!facilitatorLearnersListType && facilitatorLearnersListType !== FacilitatorLearnersListType.ALL);

        }
    }
    return false;
};
  

export {
    hasPermissionToUpdateDashboardReport,
};
