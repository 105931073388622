import ROUTE_PATHS from 'src/routing/paths';
import { LearnerMaterialTabs } from 'src/layouts/common/LearnerMaterials/LearnerMaterials';
import { SCENARIO_CHILD_MENUS_ITEM } from 'src/layouts/mursion/ScenarioBankContainer/components/ScenariosBank/NextGenScenarios/NextGenScenariosSideMenu/nextGenSideMenuConfig';

export function getScenarioRedirectionLinkForClientUser(
  projectId?: string,
  scenarioId?: string,
  generationType?: number,
  redirectUrlParams?: LearnerMaterialTabs,
  scenarioLearnerReportUrl: string = '',
) {
  return generationType === 1
    ? `${ROUTE_PATHS.LEARNER_MATERIALS_SCENARIO_INFO}/${scenarioId}?redirectUrlParams=${redirectUrlParams}`
    : `${ROUTE_PATHS.PROJECTS}/${projectId}${ROUTE_PATHS.SCENARIOS}/${scenarioId}${scenarioLearnerReportUrl}`;
}

export function getScenarioRedirectionLinkForCompanyUser(
  generationType: number | undefined,
  clientPrefix: string,
  projectId: string,
  scenarioId: string
) {
  return generationType === 1
    ? `${clientPrefix}/projects/${projectId}${ROUTE_PATHS.NEXTGEN_SCENARIOS}/${scenarioId}#${SCENARIO_CHILD_MENUS_ITEM.SCENARIO_DETAILS_CONTENT}`
    : `${clientPrefix}/projects/${projectId}${ROUTE_PATHS.SCENARIOS}/${scenarioId}`;
}