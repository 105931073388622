import { IntlShape } from 'react-intl';
import { TableTheme } from 'src/components/EntityTable';
import { INumber } from 'src/components/ListView/components/ListHeader/ListHeader';
import { RoleFilter } from 'src/components/RoleFilterControl/RoleFilterControl';
import { SelectorTheme } from 'src/components/Selector/themes/SelectorThemes';
import { ISortByFieldItem } from 'src/components/SortBy/SortByField';
import { isCurrentUserClientUser } from 'src/app/data/common/utils/userRoleUtils';
import IUserRoleExtended from 'src/app/data/profile/interfaces/IUserRoleExtended';
import { IScenarioTemplate } from 'src/app/data/projects/interfaces/IScenario';

function getScenarioTemplateTotal(numbers: INumber[] | undefined) {
  return numbers ? numbers[0].value : 0;
}

function isCobaltSelectorTheme(themeType: SelectorTheme | undefined) {
  return themeType === SelectorTheme.Cobalt;
}

function getTotalCount(numbers: INumber[] | undefined) {
  return numbers ? numbers.length : undefined;
}

function canShowTotalCount(haveNumbers: number | undefined, hideSearch: boolean | undefined) {
  return haveNumbers || !hideSearch;
}

function getTableTheme(isNextGenScenarioEnabled: boolean | undefined) {
  return isNextGenScenarioEnabled ? TableTheme.Custom : TableTheme.Default;
}

function getPaginationTheme(isNextGenScenarioEnabled: boolean | undefined) {
  return isNextGenScenarioEnabled ? TableTheme.Cobalt : TableTheme.Default;
}

function getListHeaderTheme(isNextGenScenarioEnabled: boolean | undefined) {
  return isNextGenScenarioEnabled ? SelectorTheme.Cobalt : SelectorTheme.Default;
}

function totalCountExists(numbers: INumber[] | undefined, haveNumbers: number | undefined) {
  return numbers && haveNumbers;
}

function showFilters(
  sortByFields: ISortByFieldItem[] | undefined,
  archivedFilters:
    | {
        active: number;
        archived: number;
        completed?: number;
      }
    | null
    | undefined,
  roleFilter: RoleFilter | undefined,
  createButton: JSX.Element | null | undefined
) {
  return sortByFields || archivedFilters || roleFilter || createButton;
}

function getTotalResultFountText(num: INumber, intl: IntlShape) {
    return (num.value as number > 1) ? intl.formatMessage({ id: 'MursionPortal.Results.Found' }, { count: num.value }) :
    intl.formatMessage({ id: 'MursionPortal.Result.Found' }, { count: num.value });
}

function getScenarioName(nameCustomized: string | undefined, cardHeading: string | undefined) {
  return nameCustomized ? nameCustomized : cardHeading; 
}

function getScenarioNameForClientUsers(
  userRole: IUserRoleExtended | null,
  nameCustomized: string | undefined,
  scenarioName: string | undefined
) {
  const showCustomizedTitle = isCurrentUserClientUser(userRole) && !!nameCustomized;
  return showCustomizedTitle ? nameCustomized : scenarioName;
}

function getScenarioTitle(
  isScenarioTemplate: boolean,
  scenarioTemplate: IScenarioTemplate | null,
  nonTemplateScenarioTitle: string | undefined
) {
  return isScenarioTemplate ? scenarioTemplate && scenarioTemplate.name : nonTemplateScenarioTitle;
}

function getInviteToScheduleToolTipMessage(isInviteToScheduleEmailAllowed: boolean,  intl: IntlShape) {
  return isInviteToScheduleEmailAllowed
    ? ''
    : intl.formatMessage({ id: 'MursionPortal.Project.InviteToSchedule.EmailDisabled.Tooltip' });
}

export {
  getScenarioTemplateTotal,
  isCobaltSelectorTheme,
  getTotalCount,
  canShowTotalCount,
  getTableTheme,
  getPaginationTheme,
  getListHeaderTheme,
  totalCountExists,
  showFilters,
  getTotalResultFountText,
  getScenarioName,
  getScenarioNameForClientUsers,
  getScenarioTitle,
  getInviteToScheduleToolTipMessage
};
