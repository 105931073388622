import {
  IScenarioDraft,
  IScenarioDraftUpdate,
  IScenarioPlanning,
  IScenarioPlanningUpdate,
  IScenarioTemplate,
  IScenarioTemplateUpdate
} from 'src/app/data/projects/interfaces/IScenario';
import { ScenarioVersion } from 'src/app/data/projects/interfaces/IProject';

const convertScenarioDraftToUpdate = (scenarioDraft: IScenarioDraft): IScenarioDraftUpdate => ({
  name: scenarioDraft.name,
  deliveryMode: scenarioDraft.deliveryMode,
  goals: scenarioDraft.goals,
  sessionSize: scenarioDraft.sessionSize,
  sessionLength: scenarioDraft.sessionLength,
  vignette: scenarioDraft.vignette || '',
  assetProjectId: scenarioDraft.assetProject ? scenarioDraft.assetProject.id : '',
  environmentId: scenarioDraft.environment ? scenarioDraft.environment.id : '',
  avatarIds: scenarioDraft.avatars ? scenarioDraft.avatars.map(a => a.id) : [],
  version: scenarioDraft.version,
  assetSettingsAliases: scenarioDraft.assetSettingsAliases,
  simCheckListUrl: scenarioDraft.simCheckListUrl,
  virtualSimOnly: scenarioDraft.virtualSimOnly,
});

const convertScenarioTemplateToUpdate = (scenarioTemplate: IScenarioTemplate): IScenarioTemplateUpdate => {

  const scenarioTemplateBase = {
    ...scenarioTemplate,
    id: scenarioTemplate.id,
    name: scenarioTemplate.name,
    sessionLength: scenarioTemplate.sessionLength,
    sessionSize: scenarioTemplate.sessionSize,
    deliveryMode: scenarioTemplate.deliveryMode,
    goals: scenarioTemplate.goals,
    licenseeId: scenarioTemplate.licensee ? scenarioTemplate.licensee.id || '' : '',
    vignette: scenarioTemplate.vignette,
    scenarioVersion: scenarioTemplate.scenarioVersion,
    attachments: scenarioTemplate.attachments,
    assetSettingsAliases: scenarioTemplate.assetSettingsAliases,
    version: scenarioTemplate.version,
    standardized: scenarioTemplate.standardized,
  };


  switch (scenarioTemplate.scenarioVersion) {
    case (ScenarioVersion.V2):
      return {
        ...scenarioTemplateBase,
        environmentId: scenarioTemplate.environment ? scenarioTemplate.environment.id : '',
        avatarIds: scenarioTemplate.avatars ? scenarioTemplate.avatars.map(a => a.id) : [],
      };
    case (ScenarioVersion.V3):
    default:
      return {
        ...scenarioTemplateBase,
        assetProjectId: scenarioTemplate.assetProject ? scenarioTemplate.assetProject.id : '',
      };
  }
};

const convertScenarioPlanningToUpdate = (scenarioPlanning: IScenarioPlanning): IScenarioPlanningUpdate => ({
  startDate: scenarioPlanning.startDate,
  endDate: scenarioPlanning.endDate,
  teamIds: (scenarioPlanning.teams || []).map(team => team.id || ''),
  intervalDuration: scenarioPlanning.intervalDuration,
  version: scenarioPlanning.version,
  name: scenarioPlanning.name || undefined,
  completionRate: scenarioPlanning.completionRate,
  schedulingRate: scenarioPlanning.schedulingRate,
  missOrCancelationRate: scenarioPlanning.missOrCancelationRate,
  schedulingLockoutTime: scenarioPlanning.schedulingLockoutTime,
  schedulingLockoutDate: scenarioPlanning.schedulingLockoutDate,
});

export {
  convertScenarioDraftToUpdate,
  convertScenarioTemplateToUpdate,
  convertScenarioPlanningToUpdate,
};
