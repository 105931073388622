import { IPublicSessionData } from 'src/app/redux/modules/publicSession/interfaces/IPublicSession';
import { getUpcomingBeforeStart } from 'src/app/data/session/utils/sessionActionUtils';
import { MINUTES_JOIN_BEFORE } from 'src/app/data/common/constants';
import sessionWizardHooks
  from 'src/layouts/common/Calendar/components/CalendarMainPanel/components/SessionWizard/sessionWizardHooks';
import { ISessionWithHistoryInfo } from 'src/app/data/session/interfaces/ISession';
import moment from 'moment-timezone';
import { ScenarioVersion, SoftwareType } from 'src/app/data/projects/interfaces/IProject';
import { IUserAttendanceData, IMursionSocialSessionData, IJoinSimulationData } from 'src/app/redux/modules/launchSimulation/interfaces/ILaunchSimulation';
import ICompanyConfig from 'src/app/data/companyConfig/interfaces/ICompanyConfig';
import CLIENT_SURVEY_CONFIG from 'src/layouts/common/ClientSurveyPage/clientSurveyConfig';
import IClientConfig from 'src/app/data/clientConfig/interfaces/IClientConfig';
import LoggingService from 'src/app/services/logging/LoggingService';
import { ISurveyConfig } from 'src/layouts/common/ClientSurveyPage/components/PostSimulationSurvey/PostSimulationSurvey';

export interface IPostSurveyRedirect {
    sessionStatus: boolean;
    sessionLink: string | undefined;
    sessionId: string | undefined;
    clientConfig: IClientConfig | null;
    mp8135MagicOpenNewTab?: boolean | null;
}

const getMinutesBeforeJoin = (companyConfig: ICompanyConfig | null) => {
    return companyConfig?.session?.upcomingBeforeStart ? getUpcomingBeforeStart(companyConfig.session.upcomingBeforeStart) : MINUTES_JOIN_BEFORE;
};

function  joinSimulation(params: IJoinSimulationData) {
    const {
        preSimulationSurveyEnabled,
        isAttendanceAllowed,
        isUserRecordingConsentAllowed,
        sessionDesktopLink,
        companyConfig,
        sessionId,
        session,
        onUserConsentCall,
        onSaveUserAttendanceAndConsent,
        onRedirectToWebApp,
        setIsSessionStarted
    } = params;
    const sessionProjectConfig: IPublicSessionData = {
        recordingAllowed: !!session?.recordingAllowed,
        recordingLearnerChoice: !!session?.recordingLearnerChoice,
        recordingRecipients: session?.recordingRecipients || [],
        startDate: session?.startDate,
        minutesJoinBefore: getMinutesBeforeJoin(companyConfig),
        userML3SocialRecordingConsent: isUserRecordingConsentAllowed
    };
    if(moment().isAfter(moment(sessionProjectConfig.startDate).subtract(sessionProjectConfig.minutesJoinBefore, 'm'))) {
        if(preSimulationSurveyEnabled === false) {
            if(isAttendanceAllowed) {
                if(isUserRecordingConsentAllowed) {
                    onUserConsentCall(sessionDesktopLink, sessionProjectConfig, isUserRecordingConsentAllowed);
                } else {
                    // recordingAllowed flag value is passing in userRecordingConsent because if ml3SocialAttendance is true and userRecordingConsent is false then the recording of video should be done on the basis of configuration set at project level. This we are doing as in case if we don’t show user recording consent, the value would go as false, and if SIM record session video won’t be delivered to the learner. We want to deliver video to learners if it’s recorded. 
                    const attendanceData : IUserAttendanceData = {
                    userRecordingConsent: !!session?.recordingAllowed,
                    sessionId,
                    isRecordingLiesWithLearner: !!session?.recordingLearnerChoice
                    };
                    onSaveUserAttendanceAndConsent(attendanceData, sessionDesktopLink);
                }
            } else {
                onRedirectToWebApp(sessionDesktopLink);
            }
        }
    } else {
        onUserConsentCall(sessionDesktopLink, sessionProjectConfig, isUserRecordingConsentAllowed);
        setIsSessionStarted(false);
    }
}

function isMursionSocialSession(params: IMursionSocialSessionData) {
    const {
        scenarioVersion, 
        sessionDesktopLink,
        isLearner, 
        companyConfig, 
        clientConfig, 
        session, 
        preSimulationSurveyEnabled,
        sessionId,
        onRedirectToWebApp,
        onUserConsentCall,
        setIsSessionStarted,
        onSaveUserAttendanceAndConsent
    } = params;
    const scenarioVersionAndDesktopLink = !!scenarioVersion && !!sessionDesktopLink;
    const v3MeetAndV3Z = [ScenarioVersion.V3meet, ScenarioVersion.V3z].includes(scenarioVersion);
    if (scenarioVersionAndDesktopLink && v3MeetAndV3Z) {
        if(!isLearner) {
            onRedirectToWebApp(sessionDesktopLink);
            return;
        } else {
            const ml3SocialAttendanceLicensee = !!companyConfig?.ml3SocialAttendance;
            const ml3SocialAttendanceClient = !!clientConfig?.ml3SocialAttendance;
            const isAttendanceAllowed = ml3SocialAttendanceLicensee && ml3SocialAttendanceClient;
            const isUserRecordingConsentAllowed = isAttendanceAllowed && !!clientConfig?.userML3SocialRecordingConsent && !!session?.recordingAllowed;
            const joinSimulationParams = {
                preSimulationSurveyEnabled,
                isAttendanceAllowed,
                isUserRecordingConsentAllowed,
                sessionDesktopLink,
                companyConfig,
                session,
                sessionId,
                onUserConsentCall,
                onSaveUserAttendanceAndConsent,
                onRedirectToWebApp,
                setIsSessionStarted
            };
            joinSimulation(joinSimulationParams);
        }
    }


}

function getSessionItemValue(isTraining: boolean, sessionId: any) {
    return isTraining
    ? sessionWizardHooks.useTrainingSession(sessionId || '')()
    : sessionWizardHooks.useSession(sessionId || '')(); // if sessionId is wrong, useAppInfo will throw an exception
}

function isActiveLoadingOverlay(sessionDesktopLink: string | null, refreshing: boolean, sessionRefreshing: boolean, loading: boolean) {
    return loading || !sessionDesktopLink && (refreshing || sessionRefreshing);
}

function isSessionSimSpec(session: ISessionWithHistoryInfo | null, userId: string) {
    return session && session.simspecialist && session.simspecialist.id === userId;
}

function isSessionLearner(session: ISessionWithHistoryInfo | null, userId: string) {
    return session && session.learners.some(l => l.id === userId);
}

function isSessionSimSpecAndLearner(session: ISessionWithHistoryInfo | null, userId: string) {
    return isSessionSimSpec(session, userId) && isSessionLearner(session, userId);
}

function isSessionRefreshing(sessionRefreshing: boolean, refreshing: boolean, session: ISessionWithHistoryInfo | null) {
    return sessionRefreshing || refreshing || !session;
}

function redirectPostSurveyLink(sessionId: string | undefined, postSimulationSurveyTypeId: string) {
    const postSimulationSurveyLink = `${CLIENT_SURVEY_CONFIG.SURVEY_ROUTE}/${sessionId}/?${CLIENT_SURVEY_CONFIG.surveyTypeParams}=${postSimulationSurveyTypeId}&${CLIENT_SURVEY_CONFIG.surveyTypeConfigured}=${CLIENT_SURVEY_CONFIG.postSimulationConfigList[0]}`;
    window.open(postSimulationSurveyLink, '_self');
}

async function getCallbackSession(sessionId: string, redirectInfo: any) {
    try {
      const fetcher = redirectInfo.fetchSession;
      const session = await fetcher(sessionId);
      getCallBackScenario(session.scenarioId, redirectInfo);
    } catch (err) {
      // do nothing
    }
}

async function getCallBackScenario(scenarioID: string, redirectInfo: any) {
    try {
      const fetcherScen = redirectInfo.fetchScenario;
      const scenarioInfo = await fetcherScen(scenarioID);
      const { id } = scenarioInfo.draft.project;
      getCallbackProject(id, redirectInfo);
    } catch (err) {
      // do nothing
    }
}

async function getCallbackProject(projectID:string, redirectInfo: any) {
    try {
        const fetcherPrj = redirectInfo.fetchProject_deprecated;
        const projectInfo = await fetcherPrj(projectID);
        const softwareType: any = projectInfo.softwareType;
        if (softwareType === SoftwareType.WEB || softwareType === SoftwareType.WEB_DESKTOP) {
            return;
        } else {
            redirectPostSurveyLink(redirectInfo.sessionId, '');
        }
    } catch (err) {
      // do nothing
    }
}

async function handleSurveyAndSocialRedirection(redirectInfo: IPostSurveyRedirect) {
    const socialRecordingConsent = (redirectInfo.clientConfig?.userML3SocialRecordingConsent === false || redirectInfo.clientConfig?.ml3SocialAttendance === false);
    await LoggingService.logInfo(`SessionStatus ${redirectInfo.sessionStatus} SessionLink ${redirectInfo.sessionLink}`);
    if (redirectInfo.sessionStatus && redirectInfo.sessionLink) {
        const tabDirection = redirectInfo.mp8135MagicOpenNewTab || socialRecordingConsent ? '_blank' : '_self';
        window.open(redirectInfo.sessionLink, tabDirection);
    }
    if (socialRecordingConsent && redirectInfo.sessionId) {
        getCallbackSession(redirectInfo.sessionId, redirectInfo);
    }
}

function getUrlId(url: string) {
    const surveyTypeSplit = url.split('jfe/form/');
    return surveyTypeSplit[1] ? surveyTypeSplit[1] : '';
}

function assignURLFromSurveyConfig(item: ISurveyConfig, generationType: number | undefined) {
    if(generationType === 1 && item.SURVEY_TYPE === CLIENT_SURVEY_CONFIG.NEXTGEN_POST_SIMULATION_SURVEY && item.URL) {
        return item.URL || '';
    }
    if(generationType === 0 && item.SURVEY_TYPE === CLIENT_SURVEY_CONFIG.post_simulation_survey && item.URL) {
        return item.URL || '';
    }
    return ''; 
}

const submitEmbeddedDataToQualtrics = (dataJsonResponse: object, redirectionUrl: string) => {
    const form = document.createElement('form');
    form.action = redirectionUrl;
    form.method = 'POST';

  for (const [name, value] of Object.entries(dataJsonResponse)) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = String(value);
    form.appendChild(input);
  }
    const newWindow = window.open('', '_blank');
    newWindow?.document.body.appendChild(form);
    form.submit();
};

export {
    isMursionSocialSession,
    getSessionItemValue,
    isActiveLoadingOverlay,
    isSessionSimSpec,
    isSessionLearner,
    isSessionSimSpecAndLearner,
    isSessionRefreshing,
    redirectPostSurveyLink,
    getUrlId,
    handleSurveyAndSocialRedirection,
    assignURLFromSurveyConfig,
    submitEmbeddedDataToQualtrics
};
