import moment from 'moment-timezone';
import { IntlShape } from 'react-intl';

/**
 * Converts Moment into readable format
 * @param eventStart
 * @param intl
 * @param intl
 * @param tzId - current timezone
 * @param short - if true returns '5 days' as '5 d'
 * @param showOnlyMax - if true returns '5 days 4 hours' as '5 days'
 */

export default function getTimeToEvent(eventStart: moment.Moment, intl:IntlShape, tzId?: string, short?: boolean, showOnlyMax?: boolean): string {
  const now = tzId ? moment.tz(tzId) : moment();
  const dateStart = eventStart.clone();
  const days = dateStart.diff(now, 'days');

  if (dateStart.isBefore(now)) {
    return '';
  }

  if (!!days) {
    dateStart.subtract(days, 'days');
  }

  const hours = dateStart.diff(now, 'hours');

  if (!!hours) {
    dateStart.subtract(hours, 'hours');
  }

  const minutes = dateStart.diff(now, 'minutes');

  const labelSuffix = (count: number) => count > 1;
  const daysLabel = () => {
    if (!days) {
      return '';
    }

    return short ? `${days}d` : `${days} ${labelSuffix(days)? intl.formatMessage({id:'GetTimeToEvents.Days'}) : intl.formatMessage({id:'GetTimeToEvents.Day'})}`;
  };
  const hoursLabel = () => {
    if (!hours) {
      return '';
    }

    return short ? `${hours}h` : `${hours} ${labelSuffix(hours)? intl.formatMessage({id:'GetTimeToEvents.Hours'})  : intl.formatMessage({id:'GetTimeToEvents.Hour'}) }`;
  };
  const minutesLabel = () => {
    if (!minutes) {
      return '';
    }

    return short ? `${minutes}m` : `${minutes} ${labelSuffix(minutes)? intl.formatMessage({id:'GetTimeToEvents.Minutes'})  : intl.formatMessage({id:'GetTimeToEvents.Minute'}) }`;
  };

  if (!days && !hours && minutes < 1) {
    return short ? '< 1 m' : intl.formatMessage({id:'GetTimeToEvents.LessThanOneMinute'}) ;
  }

  if (showOnlyMax) {
    if (!!days) {
      return `${daysLabel()}`;
    }

    if (!!hours) {
      return `${hoursLabel()}`;
    }

    return `${minutesLabel()}`;
  }

  return `${daysLabel()} ${hoursLabel()} ${minutesLabel()}`;
}
